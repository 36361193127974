
import { liveContent_KTV } from './careing.livecontent.ktv'
import { KTVSongInfo } from './careing.songinfo';
import { BackGroundCommunicationService } from '../backgroundcommunication.service'
import { ElderData } from '../select-elder/elder_data';

declare var Parse: any;

class KTVselect {
    ktvSongsInfoArray: KTVSongInfo[] = []
    ktvSingerArray: string[] = []
    ktvSongList: KTVSongInfo[] = []
    ktvSongs: KTVSongInfo
    openKTVSongList: number = 0
    openKTV: number = 0
    SelectSinger: string
    SelectSong: string


}


class KTVlive {
    callRecording_pointer: any
    startKTVSongFlag: number = 0
    //callRecording_userId: string
    //callRecording_song_name: string
    //callRecording_song_url: string
    //callRecording_song_status: string
    //callRecording_song_timestamp: string
    show_video_display: number

    //song_url: string
    song_objectId: string
    //song_name:string
    //song_singer:string
    //song_timestamp:string
    //volume_value: number = 0
    statusObject: any
    liveStatusTemplete: any = {
        start: "start",
        pending: "pending",
        playing: "playing",
        pause: "pause",
        stop: "stop",
        resume: "resume"
    }
    KTVObject = {
        "KTV": {
            "song_name": "",
            "song_url": "",
            "status": "start",
            "timestamp": "00:00:00",
            "volume": 0
        }

    }
}


export class KTV {

    KTVSelect: KTVselect = new KTVselect();
    KTVlive: KTVlive = new KTVlive();
    KTVliveMember: liveContent_KTV[] = [];
    KTVliveMemberindex: string[] = [];
    video_screen;

    constructor(public _backcommunicationservice: BackGroundCommunicationService) {
        console.log("in KTV consttruct")
        this.queryKTVSongSinger();
        let a = _backcommunicationservice.getliveContentArray().subscribe(array => {
            this.handleLivequery(array)
        })
    }

    /**
     * initKTVinteraction
     */
    initKTVinteraction(channel: string) {
        if (!channel) {
            return;
        }
        console.log("initKTVinteraction channel= " + channel);
        const LiveInteractionParse = Parse.Object.extend("Messengering");
        var parseLiveInteraction = new LiveInteractionParse();
        var liveContent = {}
        liveContent[Parse.User.current().id] = this.KTVlive.KTVObject
        console.dir(JSON.stringify(liveContent))
        parseLiveInteraction.set("owner", Parse.User.current());
        parseLiveInteraction.set("liveContent", JSON.stringify(liveContent));
        parseLiveInteraction.set("channel", channel);
        parseLiveInteraction.set("ishost", true);

        parseLiveInteraction.save();
    }


    /**
     * handleLivequery
     * @param array 
     */
    handleLivequery(array: any) {
        console.log("in handleLivequery")
        console.dir(array)
        this.KTVliveMember = array;

        // refresh id index
        this.KTVliveMemberindex = [];
        var updatedIndex: number = -1;


        this.KTVliveMember.forEach((data, index) => {
            this.KTVliveMemberindex.push(data.user_object_id)
            if (data.newUpdated) {
                this.KTVliveMember[index].newUpdated = false;
                updatedIndex = index
            }
        });
        this.receiveMemberOnlineStatusChangeCallback(updatedIndex);
    }

    /**
     * 
     * @param _updatedIndex 
     */
    receiveMemberOnlineStatusChangeCallback(_updatedIndex: number) {
        console.log("in receiveMemberOnlineStatusChangeCallback _updatedIndex= " + _updatedIndex)
        if (_updatedIndex == -1) {
            return;
        }

        console.info("in receiveMemberOnlineStatusChangeCallback updated user = " + this.KTVliveMemberindex[_updatedIndex] + " = (must) " + this.KTVliveMember[_updatedIndex].user_object_id)
        const newStatus = this.KTVliveMember[_updatedIndex].song_status;

        const updatemenberUserid: string = this.KTVliveMemberindex[_updatedIndex]
        const isSelfUpdate: boolean = (updatemenberUserid == Parse.User.current().id);

        switch (newStatus) {
            case this.KTVlive.liveStatusTemplete.pending:
                /*
                if(this.KTVliveMember.length<=1){
                    return;
                }*/
                var isProcess: boolean = true;
                this.KTVliveMember.every((member, index) => {
                    if (member.song_status != this.KTVlive.liveStatusTemplete.pending) {
                        isProcess = false
                        return false
                    }
                    return true;
                });
                if (isProcess) {
                    console.info("in receiveStatusChangeCallback penging-> process");
                    this.execUpdateSelfktvStatus(this.KTVlive.liveStatusTemplete.playing);
                }
                break;
            case this.KTVlive.liveStatusTemplete.playing:
                break;
            case this.KTVlive.liveStatusTemplete.start:
                console.log("receiveStatusChangeCallback status= " + newStatus + " is self" + isSelfUpdate)
                break;
        }



    }

    /**
     * setKTVObject
     * @param _timestamp 
     */
    setKTVObjectStatus(_timestamp: string, status: string) {
        // this.KTVlive.KTVObject.KTV.song_name = this.KTVlive.
        // this.KTVlive.KTVObject.KTV.song_url = this.KTVlive.song_url
        this.KTVlive.KTVObject.KTV.status = status
        this.KTVlive.KTVObject.KTV.timestamp = _timestamp
        //this.KTVlive.KTVObject.KTV.volume = this.KTVlive.volume_value
        return this.KTVlive.KTVObject;
    }


    /**
     * setKTVDisplay
     */
    setKTVDisplay() {
        // this.KTVlive.KTVObject.KTV.timestamp = "00:00:00";
    }
    /**
     * updateSelfktvStatus
     * @param status 
     */
    execUpdateSelfktvStatus(status: string) {
        console.info("in updateSelfktvStatus status= " + status);
        switch (status) {
            case this.KTVlive.liveStatusTemplete.pending:
                if (this.KTVlive.KTVObject.KTV.status != this.KTVlive.liveStatusTemplete.start) {
                    return;
                }
                // preload success
                if (this.KTVlive.KTVObject.KTV.song_url == "" || this.KTVlive.KTVObject.KTV.song_url == "NOT_UPLOADED" || this.KTVlive.song_objectId == "") {
                    this.KTVliveErrorCallback("選歌失敗, 請再試一次");
                    return;
                }
                // create new live content
                var ktvSong_pointer = new Parse.Object.extend("KTVSongs");;
                ktvSong_pointer.id = this.KTVlive.song_objectId;
                var liveContent = {}
                liveContent[Parse.User.current().id] = this.setKTVObjectStatus("00:00:00", this.KTVlive.liveStatusTemplete.pending);

                // display
                this.setKTVDisplay();

                // parse new content
                console.log("saving liveContent.......");
                var liveContentString = JSON.stringify(liveContent);
                console.dir(this.KTVliveMemberindex)
                const selfindex = this.KTVliveMemberindex.indexOf(Parse.User.current().id)
                if (selfindex == -1) {
                    return;
                }
                // exec save
                this.KTVliveMember[selfindex].messengerObject.set("liveContent", liveContentString)
                this.KTVliveMember[selfindex].messengerObject
                    .save()
                    .then(() => {
                        console.log("點歌成功 --- " + this.KTVSelect.SelectSinger + " : " + this.KTVSelect.SelectSong);
                        this.KTVlive.startKTVSongFlag = 1;
                    }).catch((e) => {
                        alert('選歌失敗 錯誤：' + e.message);

                    })


                break;
            case this.KTVlive.liveStatusTemplete.playing:
                if (this.KTVlive.KTVObject.KTV.status == this.KTVlive.liveStatusTemplete.start) {
                    return;
                }
                break;
        }

    }


    /**
     * execSaveMessengerLiveContent
     * @param messengerObject 
     * @param liveContentString 
     */
    /*execSaveMessengerLiveContent(messengerObject, liveContentString) {
        messengerObject.set("liveContent", liveContentString)
        return messengerObject.save()
    }*/

    /**
     * openKTVSong()
     * open selection and init the interaction
     */
    openKTVSong() {
        console.log("openKTVSong " + this.KTVlive.callRecording_pointer.id)
        if (!this.KTVlive.callRecording_pointer.id) {
            return;
        }
        this._backcommunicationservice.initTheliveQuery(this.KTVlive.callRecording_pointer.id, (isSuccess) => {
            console.info("initTheliveQuery success");
            if (isSuccess) {
                this.initKTVinteraction(this.KTVlive.callRecording_pointer.id);
            }
            this.KTVSelect.openKTV = 1;
        });
    }

    /**
     * queryKTVSongSinger
     */
    queryKTVSongSinger() {
        const KTVParse = Parse.Object.extend("KTVSongs");
        var query = new Parse.Query(KTVParse);
        query.include('KTVSongFile');
        query.find((results) => {
            console.log("Successfully retrieved " + results.length + " songs");
            if (results.length > 0) {
                for (var i = 0; i < results.length; i++) {
                    var songObject = results[i];
                    var ktvSongInfo = new KTVSongInfo();
                    ktvSongInfo.objectId = songObject.id;
                    ktvSongInfo.index = songObject.get("SongIndex");
                    ktvSongInfo.language = songObject.get("SongLanguage");
                    ktvSongInfo.name = songObject.get("SongName");
                    ktvSongInfo.singer = songObject.get("Singer");
                    if (songObject.get("KTVSongFile")) {
                        ktvSongInfo.url = songObject.get("KTVSongFile").get("url");
                    } else {
                        ktvSongInfo.url = "NOT_UPLOADED";
                    }
                    this.KTVSelect.ktvSongsInfoArray.push(ktvSongInfo);

                    var SingerListExist = false;
                    for (var j = 0; j < this.KTVSelect.ktvSingerArray.length; j++) {
                        if (ktvSongInfo.singer == this.KTVSelect.ktvSingerArray[j]) {
                            SingerListExist = true;
                        }
                    }
                    if (!SingerListExist) {
                        this.KTVSelect.ktvSingerArray.push(ktvSongInfo.singer);
                    }

                }
                console.dir(this.KTVSelect.ktvSingerArray);
            }
        }).catch((error) => {
            alert("無法找到歌單 錯誤 : " + error.code + " " + error.message);
        });
    }

    /**
     * findKTVSong
     */
    findKTVSong() {
        console.log("singer=" + this.KTVSelect.SelectSinger);
        if (this.KTVSelect.SelectSinger == "" || !this.KTVSelect.SelectSinger) {
            alert("請選擇一位歌手");
            return;
        }
        this.KTVSelect.openKTVSongList = 1;
        this.KTVSelect.ktvSongList = [];

        for (var i = 0; i < this.KTVSelect.ktvSongsInfoArray.length; i++) {
            if (this.KTVSelect.ktvSongsInfoArray[i].singer == this.KTVSelect.SelectSinger) {
                console.log(this.KTVSelect.ktvSongsInfoArray[i].singer + " - " + this.KTVSelect.ktvSongsInfoArray[i].name);
                var ktvSongInfo = new KTVSongInfo();
                ktvSongInfo.index = this.KTVSelect.ktvSongsInfoArray[i].index;
                ktvSongInfo.language = this.KTVSelect.ktvSongsInfoArray[i].language;
                ktvSongInfo.name = this.KTVSelect.ktvSongsInfoArray[i].name;
                ktvSongInfo.singer = this.KTVSelect.ktvSongsInfoArray[i].singer;
                ktvSongInfo.url = this.KTVSelect.ktvSongsInfoArray[i].url;
                ktvSongInfo.objectId = this.KTVSelect.ktvSongsInfoArray[i].objectId;
                this.KTVSelect.ktvSongList.push(ktvSongInfo);
            }
        }
    }

    /**
     * selectKTVSong
     */
    selectKTVSong() {


    }

    videoready() {
        alert("影片準備完成");

    }

    KTVliveErrorCallback(reason: string) {
        alert("錯誤：" + reason);
    }


    /**
     * startKTVSong
     */
    startKTVSong() {
        console.log("song=" + this.KTVSelect.SelectSong);
        if (this.KTVSelect.SelectSong == "" || !this.KTVSelect.SelectSong) {
            alert("請選擇一首歌曲");
            return;
        }
        // empty live song 
        this.KTVlive.KTVObject.KTV.song_url = "";
        this.KTVlive.song_objectId = "";

        // fill content
        for (var i = 0; i < this.KTVSelect.ktvSongList.length; i++) {
            if (this.KTVSelect.SelectSong == this.KTVSelect.ktvSongList[i].name) {
                this.KTVlive.KTVObject.KTV.song_url = this.KTVSelect.ktvSongList[i].url;
                this.KTVlive.KTVObject.KTV.song_name = this.KTVSelect.ktvSongList[i].name;
                this.KTVlive.song_objectId = this.KTVSelect.ktvSongList[i].objectId;
                if (this.KTVlive.KTVObject.KTV.song_url == "NOT_UPLOADED" || this.KTVlive.KTVObject.KTV.song_url == "") {
                    this.KTVliveErrorCallback("伺服器找不到此首歌曲")
                    return;
                }
                console.log("song url=" + this.KTVlive.KTVObject.KTV.song_url);
                console.log("song objectId=" + this.KTVlive.song_objectId);
            }
        }
        console.log("video_screen set url=" + this.KTVlive.KTVObject.KTV.song_url)
        this.KTVlive.show_video_display = 1;
        this.video_screen = document.querySelector('video')
        if (!this.video_screen) {
            this.KTVliveErrorCallback("莫名")
            return;
        }

        this.video_screen.onloadeddata = () => {
            // preload success
            if (this.KTVlive.KTVObject.KTV.song_url == "" || this.KTVlive.KTVObject.KTV.song_url == "NOT_UPLOADED" || this.KTVlive.song_objectId == "") {
                this.KTVliveErrorCallback("選歌失敗, 請再試一次");
                return;
            }
            var ktvSong_pointer = new Parse.Object.extend("KTVSongs");;
            ktvSong_pointer.id = this.KTVlive.song_objectId;
            //var joiner_pointer = new Parse.Object.extend("_User");
            //joiner_pointer.id = this.focusParseUserData.user_object_id;
            this.execUpdateSelfktvStatus(this.KTVlive.liveStatusTemplete.pending)
        };
        this.video_screen.src = this.KTVlive.KTVObject.KTV.song_url;
        this.video_screen.load();
    }

    /**
     * remote_volume_up
     */
    remote_volume_up() {
        /*
        console.log("remote_volume_up")
        this.KTVlive.volume_value = this.KTVlive.volume_value++;
        var joiner_id = this.focusParseUserData.user_object_id.toString();
        var KTVObject_volume = {
            "KTV": {
                // "song_name": this.callRecording_song_name_update,
                // "song_url": this.callRecording_song_url_update,
                "status": "volume_up",
                // "timestamp": this.callRecording_song_timestamp_update,
                // "volume": this.volume_value
            }

        }


        var liveContent = {}
        liveContent[joiner_id] = KTVObject_volume;
        var liveContentString = JSON.stringify(liveContent);
        this.KTVlive.callRecording_pointer.set("liveContent", liveContentString);
        this.KTVlive.callRecording_pointer.save(null, {
            success: (callRecordingData) => {
                console.log('update volume: ' + callRecordingData.id);
            },
            error: function (data, error) {
                // Execute any logic that should take place if the save fails.
                // error is a Parse.Error with an error code and message.
                console.log('音量調整錯誤：' + error.message);

            }
        });*/


    }


    /**
     * remote_volume_down
     */
    remote_volume_down() {
        console.log("remote_volume_down")
        /*
        this.KTVlive.volume_value = this.KTVlive.volume_value--;
        var joiner_id = this.focusParseUserData.user_object_id.toString();
        var KTVObject_volume = {
            "KTV": {
                //"song_name": this.callRecording_song_name_update,
                //"song_url": this.callRecording_song_url_update,
                "status": "volume_down",
                //"timestamp": this.callRecording_song_timestamp_update,
                //"volume": this.volume_value
            }

        }


        var liveContent = {}
        liveContent[joiner_id] = KTVObject_volume;
        var liveContentString = JSON.stringify(liveContent);
        this.KTVlive.callRecording_pointer.set("liveContent", liveContentString);
        this.KTVlive.callRecording_pointer.save(null, {
            success: (callRecordingData) => {
                console.log('update volume: ' + callRecordingData.id);
            },
            error: function (data, error) {
                // Execute any logic that should take place if the save fails.
                // error is a Parse.Error with an error code and message.
                console.log('音量調整錯誤：' + error.message);

            }
        });*/


    }


    remote_KTV_video_stop() {
        console.log("remote_KTV_video_stop")
        /*
        var joiner_id = this.focusParseUserData.user_object_id.toString();
        var KTVObject_volume = {
            "KTV": {
                //"song_name": this.callRecording_song_name_update,
                //"song_url": this.callRecording_song_url_update,
                "status": "stop",
                //"timestamp": this.callRecording_song_timestamp_update,
                //"volume": this.volume_value
            }

        }


        var liveContent = {}
        liveContent[joiner_id] = KTVObject_volume;
        var liveContentString = JSON.stringify(liveContent);
        this.KTVlive.callRecording_pointer.set("liveContent", liveContentString);
        this.KTVlive.callRecording_pointer.save(null, {
            success: (callRecordingData) => {
                console.log('video stop: ' + callRecordingData.id);
                this.video_screen.pause();
            },
            error: function (data, error) {
                // Execute any logic that should take place if the save fails.
                // error is a Parse.Error with an error code and message.
                console.log('影片停止錯誤：' + error.message);

            }
        });*/

    }

}