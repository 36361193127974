import { Component, OnInit } from '@angular/core';
declare var Parse: any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor() {
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }

  ngOnInit() {
    Parse.User.logOut();
  }

}
