import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { SubscriptionInfo } from './subscription';
declare var Parse: any;

@Component({
  selector: 'AddSubscription',
  templateUrl: 'addsubscription.component.html',
  styleUrls: ['addsubscription.component.css']
})

export class AddSubscriptionComponent implements OnInit {

  subscriptioninfo: SubscriptionInfo;
  constructor(
    private router: Router,
    private _location: Location
  ) {
    this.subscriptioninfo = new SubscriptionInfo();
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }
  ngOnInit(): void {
  }



  submit_from(): void {
    console.log("submit_from: " + this.subscriptioninfo.mabowid + " " + this.subscriptioninfo.buytime
      + " " + this.subscriptioninfo.startdate
      + " " + this.subscriptioninfo.enddate
      + " " + this.subscriptioninfo.option
    );

    var query = new Parse.Query(Parse.User);
    query.equalTo("username", this.subscriptioninfo.mabowid);

    query.find({
      success: (user) => {
        console.log(user[0]);
        if (user[0] != null) {
          this.saveSubObject(user[0], this.subscriptioninfo);
        } else {
          alert("非註冊使用者無法訂閱");
          console.error("no user find");
        }
      },
      error: function (user, error) {
        alert('Failed to create new object, with error code: ' + error.message);
        console.error('Failed to create new object, with error code: ' + error.message);
      }
    });
    //  alert("訂閱完成");
    //  this._location.back();
  }

  saveSubObject(user, subscriptioninf) {
    console.log("in saveSubObject()");
    console.dir(subscriptioninf);
    var elder_mabowid = subscriptioninf.mabowid;
    var buyCaredTime = subscriptioninf.buytime;
    var startdate = new Date(subscriptioninf.startdate);
    var enddate = new Date(subscriptioninf.enddate);
    var contract_type = subscriptioninf.option;


    var SubscriptionDetail = Parse.Object.extend("SubscriptionDetail");
    var subscriptionDetail = new SubscriptionDetail();
    var query = new Parse.Query(SubscriptionDetail);
    query.equalTo("user", user);

    query.find({
      success: (subscriptiondetail) => {
        console.log("subscriptiondetail length: " + subscriptiondetail.length);
        if (subscriptiondetail.length) {
          for (var i = 0; i < subscriptiondetail.length; i++) {
            var subscription = subscriptiondetail[i];

            if ((subscription.get('subscriptionStartDate').getTime() <= startdate.getTime() && subscription.get('subscriptionEndDate').getTime() >= startdate.getTime())
              || (subscription.get('subscriptionStartDate').getTime() <= enddate.getTime() && subscription.get('subscriptionEndDate').getTime() >= enddate.getTime())) {
              console.log("訂閱時間重疊: " + subscriptiondetail[i].get('subscriptionStartDate') + " to " + subscriptiondetail[i].get('subscriptionEndDate'));
              alert("訂閱時間重疊: " + subscriptiondetail[i].get('subscriptionStartDate') + " to " + subscriptiondetail[i].get('subscriptionEndDate'));
              return;
            }
          }
        }

        var Task = Parse.Object.extend("SubscriptionDetail");
        var task = new Task();
        task.set("user", user);
        task.set("subscriptionStartDate", new Date(startdate));
        task.set("subscriptionEndDate", new Date(enddate));
        task.set("subscriptionMinutes", Number(buyCaredTime));
        task.set("usedMinutes", 0);
        task.set("contract_type", Number(contract_type));
        var currentUser = Parse.User.current();
        task.save(null, {
          sessionToken: currentUser.sessionToken
        }).then(function (task) {
          console.log('New object created with objectId: ' + task.id);
          if (!task.id) {
            return;
          }
          alert("訂閱完成");
          this._location.back();
          console.log("Success");
        }, function (error) {
          alert('Failed to create new object, with error code: ' + error.message);
          console.error('Failed to create new object, with error code: ' + error.message);
        });

      },
      error: function (user, error) {
        console.error('Failed to create new object, with error code: ' + error.message);
      }
    });


  }

}

