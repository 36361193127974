import { Component, EventEmitter, OnInit } from "@angular/core";
import { Appointment, DayWithAppointments, Month } from "./stateTypes";
import * as moment from "moment";

declare var Parse: any;

@Component({
    selector: "calendar",
    templateUrl: 'calendar.component.html',
    styleUrls: ['calendar.component.css'],
})
export class Calendar implements OnInit {
    public weeks$: Array<Array<DayWithAppointments>>;
    public DaysWithAppointments: Array<DayWithAppointments>;
    public selectedMonth;
    public formattedMonth;
    public tracker;
    constructor(
    ) {
        /*window.onbeforeunload = function () {
            return 'window close';
        };*/
    }

    public ngOnInit(): void {
        let time = moment();
        this.selectedMonth = { year: time.year(), month: time.month() };
        this.QueryCalendar();
        this.DaysWithAppointments = this.getDefaultDaysWithAppointments(this.selectedMonth);
        this.fillincalendar();
        this.formattedMonth = moment().year(this.selectedMonth.year).month(this.selectedMonth.month).format("YYYY/MM");

    }

    private getDefaultDaysWithAppointments(month: Month): Array<DayWithAppointments> {
        let dayOne = moment().year(month.year).month(month.month).date(1);
        let days: Array<DayWithAppointments> = [];
        for (var i = 0; i < dayOne.daysInMonth(); i++) {
            days.push({ day: { year: month.year, month: month.month, day: i + 1 }, appointments: [] });
        }
        return days;
    }


    public addAppointment(appointment: Appointment): void {

        var Calendar = Parse.Object.extend("Calendar");
        var calendar = new Calendar();
        calendar.set("date", appointment.date);
        calendar.set("appointment", appointment.description);
        var currentUser = Parse.User.current();
        calendar.save(null, {
            success: (task) => {
                console.log('New object created with objectId: ' + task.id);
                if (!task.id) {
                    return;
                }
                let appointmentMoment = moment(appointment.date);
                appointment.ParseID = task.id;
                this.DaysWithAppointments[appointmentMoment.date() - 1].appointments.push(appointment);
                this.fillincalendar();
                console.log("addAppointment Success");
            },
            error: function (task, error) {
                console.log('Failed to create new object, with error code: ' + error.message);
            }
        });
    }

    public removeAppointment(appointment: Appointment): void {
        var Calendar = Parse.Object.extend("Calendar");
        var calendar = new Calendar();
        var query = new Parse.Query(calendar);
        query.get(appointment.ParseID, {
            success: function (myObj) {
                console.log('removeAppointment found ');
                myObj.destroy({});
            },
            error: function (object, error) {
                console.log('removeAppointment not found ');
            }
        });
        this.DaysWithAppointments[appointment.date.getDate() - 1].appointments =
            this.DaysWithAppointments[appointment.date.getDate() - 1].appointments.filter((_appointment: Appointment) => {
                return _appointment.ParseID !== appointment.ParseID;
            })
        this.fillincalendar();

    }

    public updateAppointment(appointment: Appointment): void {
        var Calendar = Parse.Object.extend("Calendar");
        var calendar = new Calendar();
        calendar.id = appointment.ParseID;
        calendar.set("date", appointment.date);
        calendar.set("appointment", appointment.description);
        var currentUser = Parse.User.current();
        var acl = new Parse.ACL(currentUser);
        calendar.setACL(acl);
        calendar.save(null, {
            sessionToken: currentUser.sessionToken

        }).then(function (task) {
            console.log('Update object created with objectId: ' + task.id);
            if (!task.id) {
                return;
            }
            console.log("updateAppointment Success");
        }, function (error) {
            console.error('Failed to update new object, with error code: ' + error.message);
        });
    }


    QueryCalendar() {
        var Calendar = Parse.Object.extend("Calendar");
        var calendar = new Calendar();
        var query = new Parse.Query(calendar);
        let dayOne = moment().year(this.selectedMonth.year).month(this.selectedMonth.month).date(1);
        var dayfirst = new Date(this.selectedMonth.year, this.selectedMonth.month, 1);
        var daylast = new Date(this.selectedMonth.year, this.selectedMonth.month, dayOne.daysInMonth());
        query.descending("updatedAt");
        query.lessThan("date", daylast);
        query.greaterThan("date", dayfirst);
        query.find({
            success: (results) => {
                console.log("Successfully retrieved " + results.length + " messages.");
                for (var i = 0; i < results.length; i++) {
                    var object = results[i];
                    console.log("id: " + object.id);
                    console.log("date: " + object.get("date"));
                    console.log("appointment: " + object.get("appointment"));
                    let appointmentMoment = moment(object.get("date"));
                    let appointment = new Appointment(object.id, object.get("date"), object.get("appointment"));
                    this.DaysWithAppointments[appointmentMoment.date() - 1].appointments.push(appointment);

                }
                this.fillincalendar();
            },
            error: function (error) {
                alert("Error: " + error.code + " " + error.message);
            }
        });

    }

    fillincalendar() {
        var MultiArray = new Array(6);
        MultiArray[0] = new Array(7);
        MultiArray[1] = new Array(7);
        MultiArray[2] = new Array(7);
        MultiArray[3] = new Array(7);
        MultiArray[4] = new Array(7);
        MultiArray[5] = new Array(7);

        var weekcount = null;
        this.DaysWithAppointments.forEach(day => {
            let momentDay = moment().year(day.day.year).month(day.day.month).date(day.day.day);
            if (!weekcount)
                weekcount = momentDay.week();
            var index = momentDay.week() - weekcount;
            if (index < 0)
                index = index + 52;
            MultiArray[index][momentDay.weekday()] = day;
        });

        this.weeks$ = MultiArray;
    }

    public previous(): void {
        let time = moment().year(this.selectedMonth.year).month(this.selectedMonth.month).add(-1, "months");
        this.selectedMonth = { year: time.year(), month: time.month() };
        this.formattedMonth = moment().year(this.selectedMonth.year).month(this.selectedMonth.month).format("YYYY/MM");
        this.QueryCalendar();
        this.DaysWithAppointments = this.getDefaultDaysWithAppointments(this.selectedMonth);
        this.fillincalendar();
    }

    public next(): void {
        let time = moment().year(this.selectedMonth.year).month(this.selectedMonth.month).add(1, "months");
        this.selectedMonth = { year: time.year(), month: time.month() };

        this.formattedMonth = moment().year(this.selectedMonth.year).month(this.selectedMonth.month).format("YYYY/MM");
        this.QueryCalendar();
        this.DaysWithAppointments = this.getDefaultDaysWithAppointments(this.selectedMonth);
        this.fillincalendar();
    }

}