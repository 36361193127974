
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { liveinteraction, useractivity } from './liveinteraction';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var Parse: any;
declare var $: any;

@Component({
  selector: 'app-user-analysis',
  templateUrl: './user-analysis.component.html',
  styleUrls: ['./user-analysis.component.css']
})
export class UserAnalysisComponent extends ParentClass implements OnInit {

  constructor(router: Router, private _http: HttpClient) {
    super(router);
  }
  isLoading = false;
  ngOnInit() {
    this.quary_live_interaction();
  }

  public aws_analysis_url = "https://nlpufc8cjk.execute-api.ap-northeast-1.amazonaws.com/deploy/data/liveinteraction/";
  public liveinteractionlist: Array<any> = []; // list for room object at parse
  selected: liveinteraction = {
    id: "default",
    createdAt: "default",
    name: "default",
    start: new Date,
    end: new Date,
    type: 1,
    duration: 0,
    has_guess_number: false,
    correct_guess_number: []
  };
  index;
  public correct_guess_number: Array<Number> = [];
  public correct_guess_number_candidate;
  public liveinteractionDatalist = []; // data for store remote aws pinpont data 
  public arrayLikeEventType: Array<any> = [];
  public array2d_interaction: Array<any> = [];
  public array2d_mabowQ: Array<any> = [];
  public array2d_callinevent: Array<any> = [];
  public array2d_ktvevent: Array<any> = [];
  public array2d_likeevent: Array<any> = [];
  public array2d_guessevent: Array<any> = [];
  public array2d_chooseevent: Array<any> = [];
  public interaction_array: Array<any> = [];
  public mabowQ_array: Array<any> = [];
  public callinevent_array: Array<any> = [];
  public ktvevent_array: Array<any> = [];
  public likeevent_array: Array<any> = [];
  public guessevent_array: Array<any> = [];
  public chooseevent_array: Array<any> = [];
  public listbyuser: Array<any> = [];
  public userlist: Array<any> = [];
  useractivity_obj: useractivity = {
    id: "",
    elderid: "",
    zendeskid: "",
    name: "",
    number: "",
    denytime: "",
    isVIPdeny: false,
    enterktvroom: 0,    //->Pinpoint_EVENT_WATCHING_LIVEINTERACTION
    callintoktvroom: "", //->Pinpoint_EVENT_JOIN_LIVEINTERACTION
    callintime: "",
    callintimestamp: 0,
    leavetime: "?",
    leavetimestamp: 0,
    singing: 0,         //->Pinpoint_EVENT_KTV_LIVEINTERACTION
    likeinktv: 0,        //->Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION
    guess: 0,         //->Pinpoint_EVENT_GUESSNUMBER_LIVEINTERACTION
    choose: 0,
    Qact: 0,
    Qview: 0,
    Qcorrect: -1,
    guess_ball: null
  };

  private RecordEvent = {
    Pinpoint_EVENT_NOTIFICATION_DENY: "Pinpoint_EVENT_NOTIFICATION_DENY",
    Pinpoint_EVENT_NOTIFICATION_VIP_DENY_SHOW_SMALL: "Pinpoint_EVENT_NOTIFICATION_VIP_DENY_SHOW_SMALL",
    Pinpoint_EVENT_WATCHING_LIVEINTERACTION: "Pinpoint_EVENT_WATCHING_LIVEINTERACTION",
    Pinpoint_EVENT_LEAVE_WATCHING_LIVEINTERACTION: "Pinpoint_EVENT_LEAVE_WATCHING_LIVEINTERACTION",
    Pinpoint_EVENT_Q: "Pinpoint_EVENT_Q",
    Pinpoint_EVENT_LEAVE_Q: "Pinpoint_EVENT_LEAVE_Q",
    Pinpoint_EVENT_JOIN_LIVEINTERACTION: "Pinpoint_EVENT_JOIN_LIVEINTERACTION",
    Pinpoint_EVENT_KTV_LIVEINTERACTION: "Pinpoint_EVENT_KTV_LIVEINTERACTION",
    Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION: "Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION",
    Pinpoint_EVENT_GUESSNUMBER_LIVEINTERACTION: "Pinpoint_EVENT_GUESSNUMBER_LIVEINTERACTION",
    Pinpoint_EVENT_AWARD_CHOOSE_LIVEINTERACTION: "Pinpoint_EVENT_LIVEINTERACTION_a7ExiqWbhi"
  }


  public lineChartData: Array<any> = [
    { data: [], label: 'Interaction' },
    { data: [], label: 'Mabow Q' },
    { data: [], label: 'Call in event' },
    { data: [], label: 'KTV event' },
    { data: [], label: 'Like event' },
    { data: [], label: 'Guess event' },
    { data: [], label: 'Choose event' }
  ];
  public lineChartLabels: Array<any> = [];
  public lineChartOptions: any = {
    responsive: true
  };
  public lineChartColors: Array<any> = [
    { // red
      backgroundColor: 'rgba(255,0,102,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // yellow
      backgroundColor: 'rgba(255,200,0,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // green
      backgroundColor: 'rgba(0,204,102,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // blue
      backgroundColor: 'rgba(0,153,255,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(255,102,255,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pintBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgb(255,153,0,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgb(255, 204, 255,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';
  WatchCount = 0;
  ActionCount = 0;
  MabowAccount1 = "WHE7qBKD8F";
  MabowAccount2 = "ccmmYi6GhE";


  private clear_init_Data() {

    this.liveinteractionDatalist = []; // data for store remote aws pinpont data 
    this.array2d_interaction = [];
    this.array2d_mabowQ = [];
    this.array2d_callinevent = [];
    this.array2d_ktvevent = [];
    this.array2d_likeevent = [];
    this.array2d_guessevent = [];
    this.array2d_chooseevent = [];
    this.interaction_array = [];
    this.mabowQ_array = [];
    this.callinevent_array = [];
    this.ktvevent_array = [];
    this.likeevent_array = [];
    this.guessevent_array = [];
    this.chooseevent_array = [];
    this.listbyuser = [];
    this.userlist = [];

    this.lineChartData = [
      { data: [], label: 'Interaction' },
      { data: [], label: 'Mabow Q' },
      { data: [], label: 'Call in event' },
      { data: [], label: 'KTV event' },
      { data: [], label: 'Like event' },
      { data: [], label: 'Guess event' },
      { data: [], label: 'Choose event' }
    ];
    this.lineChartLabels = [];
    this.WatchCount = 0;
    this.ActionCount = 0;

  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
    if (e.active.length != 0)
      console.log("index: " + e.active[0]._index);
    //  go to show people
    this.interaction_array = this.array2d_interaction[e.active[0]._index];
    this.mabowQ_array = this.array2d_mabowQ[e.active[0]._index];
    this.callinevent_array = this.array2d_callinevent[e.active[0]._index];
    this.ktvevent_array = this.array2d_ktvevent[e.active[0]._index];
    this.likeevent_array = this.array2d_likeevent[e.active[0]._index];
    this.guessevent_array = this.array2d_guessevent[e.active[0]._index];
    this.chooseevent_array = this.array2d_chooseevent[e.active[0]._index];
    this.index = this.lineChartLabels[e.active[0]._index];

    $('.ui.array.modal')
      .modal('show')
      ;
  }

  /**
   * interface for go to detail page
   * @param id 
   */
  public gocaredetail(id: string) {
    console.log("route to : " + id);
    //TODO:
    //this.globalService.focusParseUserObject = elderdata;
    //this.router.navigateByUrl('/caredetails');
  }

  /**
   * on chart hover
   * @param e
   */
  public chartHovered(e: any): void {
    console.log(e);
  }

  toJson(str) {
    return eval("(" + this.toArray(str) + ")");
  }

  toArray(str) {
    str = str.replace("{", "");
    str = str.replace("}", "");
    var list = str.split(",");
    var myStr = "{";
    for (var i = 0; i < list.length; i++) {
      try {
        var keys = list[i].split("=");
        var key = this.Trim(keys[0]);
        var value = this.Trim(keys[1]);
        if (i > 0) {
          myStr += ",";
        }
        myStr += "\"" + key + "\":\"" + value + "\"";
      } catch (e) {
        continue;
      }
    }
    myStr += "}";
    return myStr;
  }

  //替换掉字符串中头尾的空格  
  Trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  }

  /**
   * 
   * @param obj parse data
   * @param first_event_time 
   * @param time_interval 
   */
  public parse_data(first_event_time, time_interval): void {
    console.info("begin parse_data.....")
    this.liveinteractionDatalist.forEach((obj, index) => {
      const JSONattr = this.toJson(obj.attributes)
      const JSONattr_eventid = JSONattr.eventid;
      const JSONattr_who = JSONattr.who;
      const newdate = new Date(JSONattr.timestamp);
      newdate.setSeconds(0);
      const formatedtime = moment.unix(JSONattr.timestamp / 1000).format("HH:mm");
      const indexoftimestamp = this.lineChartLabels.indexOf(formatedtime);
      var index = 0;
      if (indexoftimestamp == -1)// prevent event not in the record range
        return;

      if (this.listbyuser.length == 0) {  // push 1st data
        var useractivity_obj: useractivity = {
          id: JSONattr_who,
          elderid: "",
          zendeskid: "",
          name: "",
          number: "",
          denytime: "",
          isVIPdeny: false,
          enterktvroom: 0,    //->Pinpoint_EVENT_WATCHING_LIVEINTERACTION
          callintoktvroom: "", //->Pinpoint_EVENT_JOIN_LIVEINTERACTION
          callintime: "",
          callintimestamp: 0,
          leavetime: "?",
          leavetimestamp: 0,
          singing: 0,         //->Pinpoint_EVENT_KTV_LIVEINTERACTION
          likeinktv: 0,        //->Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION
          guess: 0,
          choose: 0,
          Qact: 0,
          Qview: 0,
          Qcorrect: -1,
          guess_ball: null
        };
        this.listbyuser.push(useractivity_obj);
        this.userlist.push(JSONattr_who);
      }
      for (i = 0; i < this.listbyuser.length; i++) {
        if (this.listbyuser[i].id == JSONattr_who) { // user already in array.
          index = i;
          break;
        }
        if (i == (this.listbyuser.length - 1)) { // user not in array, so push to array.
          var useractivity_obj: useractivity = {
            id: JSONattr_who,
            elderid: "",
            zendeskid: "",
            name: "",
            number: "",
            denytime: "",
            isVIPdeny: false,
            enterktvroom: 0,    //->Pinpoint_EVENT_WATCHING_LIVEINTERACTION
            callintoktvroom: "", //->Pinpoint_EVENT_JOIN_LIVEINTERACTION
            callintime: "",
            callintimestamp: 0,
            leavetime: "?",
            leavetimestamp: 0,
            singing: 0,         //->Pinpoint_EVENT_KTV_LIVEINTERACTION
            likeinktv: 0,        //->Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION
            guess: 0,
            choose: 0,
            Qact: 0,
            Qview: 0,
            Qcorrect: -1,
            guess_ball: null
          };
          this.listbyuser.push(useractivity_obj);
          this.userlist.push(JSONattr_who);
          index = this.listbyuser.length;
        }
      }
      //console.log("list by user data length " + this.listbyuser.length);
      if (JSONattr_who == "WHE7qBKD8F") {
        console.log(JSONattr_who + ":" + obj.event_type + " > " + JSONattr.timestamp)
      }

      for (var k = 0; k < this.arrayLikeEventType.length; k++) {
        if (obj.event_type == this.arrayLikeEventType[k]) {
          this.lineChartData[4].data[indexoftimestamp]++;
          this.array2d_likeevent[indexoftimestamp].push(JSONattr_who);
          this.listbyuser[index].likeinktv++;
          if (this.listbyuser[index].likeinktv == 1 && JSONattr_who != this.MabowAccount1) {
            this.ActionCount++;
          }
        }
      }

      switch (obj.event_type) {
        case this.RecordEvent.Pinpoint_EVENT_WATCHING_LIVEINTERACTION:
          /*for (var i = indexoftimestamp; i < this.lineChartLabels.length; i++) {
            this.lineChartData[0].data[i]++;
            this.array2d_interaction[i].push(JSONattr_who);
          }*/
          this.listbyuser[index].enterktvroom++;
          break;
        case this.RecordEvent.Pinpoint_EVENT_JOIN_LIVEINTERACTION:
          for (var i = indexoftimestamp; i < this.lineChartLabels.length; i++) {
            this.lineChartData[0].data[i]++;
            this.array2d_interaction[i].push(JSONattr_who);
          }
          this.lineChartData[2].data[indexoftimestamp]++;
          this.array2d_callinevent[indexoftimestamp].push(JSONattr_who);
          this.listbyuser[index].callintoktvroom++;
          this.listbyuser[index].callintime = formatedtime;
          this.listbyuser[index].callintimestamp = JSONattr.timestamp;
          if (this.listbyuser[index].callintoktvroom == 1 && JSONattr_who != this.MabowAccount1 && JSONattr_who != this.MabowAccount2) {
            this.WatchCount++;
          }
          break;
        case this.RecordEvent.Pinpoint_EVENT_LEAVE_WATCHING_LIVEINTERACTION:
          for (var i = indexoftimestamp; i < this.lineChartLabels.length; i++) {
            if (this.array2d_interaction[i].indexOf(JSONattr_who) > -1) {  //avoid over reduction, check if user in record
              this.lineChartData[0].data[i]--;
              this.listbyuser[index].leavetime = formatedtime;
              this.listbyuser[index].leavetimestamp = JSONattr.timestamp;
              this.array2d_interaction[i] = this.array2d_interaction[i].filter((val) => {
                return val != JSONattr_who;
              })
            }
          }
          break;
        case this.RecordEvent.Pinpoint_EVENT_Q:
          for (var i = indexoftimestamp; i < this.lineChartLabels.length; i++) {
            this.lineChartData[1].data[i]++;
            this.array2d_mabowQ[i].push(JSONattr_who);
          }
          break;
        case this.RecordEvent.Pinpoint_EVENT_LEAVE_Q:
          console.log(JSONattr_who + " leave Q event: " + formatedtime)
          for (var i = indexoftimestamp; i < this.lineChartLabels.length; i++) {
            this.lineChartData[1].data[i]--;
            this.array2d_mabowQ[i] = this.array2d_mabowQ[i].filter((val) => {
              return val != JSONattr_who;
            })
          }
          break;
        case this.RecordEvent.Pinpoint_EVENT_NOTIFICATION_DENY:
          this.listbyuser[index].denytime = formatedtime;
          this.listbyuser[index].isVIPdeny = false;
          //console.log(JSONattr_who + ": deny at " + this.listbyuser[index].denytime)
          break;
        case this.RecordEvent.Pinpoint_EVENT_NOTIFICATION_VIP_DENY_SHOW_SMALL:
          this.listbyuser[index].denytime = formatedtime;
          this.listbyuser[index].isVIPdeny = true;
          //console.log(JSONattr_who + ": deny at " + this.listbyuser[index].denytime)
          break;

        case this.RecordEvent.Pinpoint_EVENT_KTV_LIVEINTERACTION:
          this.lineChartData[3].data[indexoftimestamp]++;
          this.array2d_ktvevent[indexoftimestamp].push(JSONattr_who);
          this.listbyuser[index].singing++;
          if (this.listbyuser[index].singing == 1 && JSONattr_who != this.MabowAccount1) {
            this.ActionCount++;
          }
          break;
        /*case this.RecordEvent.Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION:
          console.log("like:" + JSONattr_who)
          this.lineChartData[4].data[indexoftimestamp]++;
          this.array2d_likeevent[indexoftimestamp].push(JSONattr_who);
          this.listbyuser[index].likeinktv++;
          if (this.listbyuser[index].likeinktv == 1 && JSONattr_who != "WHE7qBKD8F") {
            this.ActionCount++;
          }
          break;*/
        case this.RecordEvent.Pinpoint_EVENT_GUESSNUMBER_LIVEINTERACTION:
          //console.log("guess:" + JSONattr_who)
          if (this.listbyuser[index].guess == 0) {
            this.lineChartData[5].data[indexoftimestamp]++;
            this.array2d_guessevent[indexoftimestamp].push(JSONattr_who);
            this.listbyuser[index].guess++;
            if (this.listbyuser[index].guess == 1 && JSONattr_who != this.MabowAccount1) {
              this.ActionCount++;
            }
          } else {
            //console.log("guess deprecated:" + JSONattr_who)
          }
          break;
        /* case this.RecordEvent.Pinpoint_EVENT_AWARD_CHOOSE_LIVEINTERACTION:
           console.log("choose:" + JSONattr_who)
           this.checkChooseOption(JSONattr_who, index, indexoftimestamp);
           break;*/
        default:

          if (obj.event_type.indexOf(this.RecordEvent.Pinpoint_EVENT_AWARD_CHOOSE_LIVEINTERACTION) >= 0) {
            console.log("choose:" + JSONattr_who)
            this.checkChooseOption(JSONattr_who, index, indexoftimestamp);
          }
          //console.log("not parse event type: " + obj.event_type);
          break;
      };

    })

    this.updateUserInfo();

    var LiveInteractionLikeParse = Parse.Object.extend("LiveInteractionLike");
    var LiveInteractionLikeQuery = new LiveInteractionLikeParse();
    var queryLike = new Parse.Query(LiveInteractionLikeQuery);

    var parentPointer = {
      __type: 'Pointer',
      className: 'LiveInteraction',
      objectId: this.selected.id
    };

    var typePointer = {
      __type: 'Pointer',
      className: 'interactionTypeDefine',
      objectId: "724gXDynIS"
    };

    queryLike.equalTo("parentInteraction", parentPointer);
    queryLike.equalTo("type", typePointer);

    queryLike.find().then((results) => {
      for (var i = 0; i < results.length; i++) {
        this.listbyuser.forEach((item) => {
          if (item.id == results[i].get("owner").id) {
            if (results[i].get("metaContent")) {
              var jsonobject = results[i].get("metaContent");
              //TODO: here assume only guess one number, if user will guess 2+ number, should parse all data
              item.guess_ball = Number(jsonobject.key);
              if (this.correct_guess_number.indexOf(item.guess_ball) > -1) {
                item.guess = 2;
              }
            }
          }
        });
      }
      this.isLoading = false;
    });
  }

  private updateUserInfo() {
    //console.log("in updateUserInfo")
    //console.dir(this.userlist)
    var query_time = Math.floor(this.userlist.length / 100) + 1;
    //console.log("query_time=" + query_time)
    var userlistArray = [];
    var elderlistArray = [];
    for (var k = 0; k < query_time; k++) {
      userlistArray.push(this.userlist.slice(k * 100, (k + 1) * 100))

    }
    for (var j = 0; j < userlistArray.length; j++) {
      var query = new Parse.Query(Parse.User);
      query.containedIn("objectId", userlistArray[j]);
      query.find().then((results) => {
        for (var i = 0; i < results.length; i++) {
          this.listbyuser.forEach((item) => {
            if (item.id == results[i].id && results[i].get("userNickname")) {
              item.name = results[i].get("userNickname");
              item.number = "號碼" + results[i].get("username");
              if (results[i].get("ZDID")) {
                //console.log(item.name + " ZDID=" + results[i].get("ZDID"))
                item.zendeskid = results[i].get("ZDID");
              }
            }
          });
        }
        /*if (j == userlistArray.length -1){
          this.isLoading = false;
        }*/
      });

      const User = Parse.Object.extend("_User");
      var ElderDetailInfo = Parse.Object.extend("ElderDetailInfo");
      var pElderDetailInfo = new ElderDetailInfo();
      var queryElder = new Parse.Query(pElderDetailInfo);
      elderlistArray = [];
      for (var x in userlistArray[j]) {
        elderlistArray.push(User.createWithoutData(userlistArray[j][x]))
      }
      queryElder.containedIn("User", elderlistArray);
      queryElder.include("User");
      queryElder.find().then((elders) => {
        for (var l = 0; l < elders.length; l++) {
          this.listbyuser.forEach((item) => {
            if (item.id == elders[l].get("User").id) {
              item.elderid = elders[l].id;
            }
          });
        }

      });

      if (j == userlistArray.length - 1) {
        this.isLoading = false;
      }
    }
  }

  private check_guess_ball_correction() {
    console.log("check_guess_ball_correction");
    this.listbyuser.forEach((item) => {
      if (item.guess_ball) {
        item.guess = 1;
        if (this.correct_guess_number.indexOf(item.guess_ball) > -1) {
          item.guess = 2;
        }
      } else
        item.guess = 0;
    });
  }

  private checkChooseOption(JSONattr_who, index, indexoftimestamp) {

    var LiveInteractionLikeParse = Parse.Object.extend("LiveInteractionLike");
    var LiveInteractionLikeQuery = new LiveInteractionLikeParse();
    var queryChoose = new Parse.Query(LiveInteractionLikeQuery);

    var parentPointer = {
      __type: 'Pointer',
      className: 'LiveInteraction',
      objectId: this.selected.id
    };
    var ownPointer = {
      __type: 'Pointer',
      className: '_User',
      objectId: JSONattr_who
    };
    var typePointer = {
      __type: 'Pointer',
      className: 'interactionTypeDefine',
      objectId: "a7ExiqWbhi"
    };

    queryChoose.equalTo("parentInteraction", parentPointer);
    queryChoose.equalTo("owner", ownPointer);
    queryChoose.equalTo("type", typePointer);
    queryChoose.find().then((chooseResults) => {
      console.log("in checkChooseOption " + JSONattr_who + " chooseResults=" + chooseResults.length)
      // results.length should only 1
      var chooseObj = chooseResults[0].get("metaContent");
      if (chooseObj != null) {
        if (chooseObj.key == "1" || chooseObj.key == "2") {
          if (this.listbyuser[index].choose == 0) {
            this.lineChartData[6].data[indexoftimestamp]++;
            this.array2d_chooseevent[indexoftimestamp].push(JSONattr_who);
            this.listbyuser[index].choose++;
            if (this.listbyuser[index].choose == 1 && JSONattr_who != this.MabowAccount1) {
              this.ActionCount++;
            }
          } else {
            //console.log("choose deprecated:" + JSONattr_who)
          }
        }
      }
    });

  }

  public parse_Q_data(first_event_time, time_interval): void {
    this.mq_parse_get_results(0);
  }

  private mq_parse_get_results(results_count_index) {
    var MabowQUserStatus = Parse.Object.extend("MabowQ_UserStatus");
    var MabowQUserStatusOBJ = new MabowQUserStatus();
    var query = new Parse.Query(MabowQUserStatusOBJ);
    var MabowQChannelPointer = {
      __type: 'Pointer',
      className: 'LiveInteraction',
      objectId: this.selected.id
    }
    query.equalTo("MabowQ_Channel", MabowQChannelPointer);
    query.notEqualTo("userStatus", 0);
    query.include('userObject');
    query.limit(100);
    query.skip(results_count_index * 100);
    query.find({
      success: (results) => {
        if (results.length > 0) {
          for (var i = 0; i < results.length; i++) {
            var object = results[i];
            if (object.get("userAnswer")) {
              var user = object.get("userObject").id;
              var time1 = object.get("createdAt");
              var time2 = object.get("updatedAt");
              const newdate1 = new Date(time1);
              var newdate2 = new Date(time2);
              if (newdate2.getTime() > this.selected.end.getTime()) {
                newdate2 = this.selected.end;
              }
              newdate1.setSeconds(0);
              newdate2.setSeconds(0);
              const newdateTime1 = Math.floor(newdate1.getTime() / 1000)
              const newdateTime2 = Math.floor(newdate2.getTime() / 1000)
              const formatedtime1 = moment.unix(newdateTime1).format("HH:mm");
              const indexoftimestamp1 = this.lineChartLabels.indexOf(formatedtime1);
              const formatedtime2 = moment.unix(newdateTime2).format("HH:mm");
              const indexoftimestamp2 = this.lineChartLabels.indexOf(formatedtime2);
              //console.log("indexoftimestamp1=" + indexoftimestamp1 + " indexoftimestamp2=" + indexoftimestamp2);
              var obj = object.get("userAnswer");
              //console.log("obj.length=" + Object.keys(obj).length);
              var correct = object.get("answerCorrectAmount");
              console.log("user=" + user + " count=" + Object.keys(obj).length)
              if (Object.keys(obj).length == 5) {
                var answerCount = 0;
                for (var j in obj) {
                  if (obj[j] > 0) {
                    answerCount = answerCount + 1;
                  }
                }
                //console.log("ansC=" + answerCount);
                if (answerCount >= 1) {
                  //console.log("action 2")
                  this.lineChartData[1].data[indexoftimestamp1]++;
                  this.lineChartData[1].data[indexoftimestamp2]++;
                  this.array2d_mabowQ[indexoftimestamp1].push(user);
                  this.array2d_mabowQ[indexoftimestamp2].push(user);
                  var index = -1;
                  var val = user;
                  index = this.listbyuser.findIndex((item, i) => {
                    return item.id === val
                  });
                  //console.log("id=" + user + " index=" + index);
                  if (index != -1) {
                    this.listbyuser[index].Qact = this.listbyuser[index].Qact + 2;
                    this.listbyuser[index].Qview = Object.keys(obj).length;
                    this.listbyuser[index].Qcorrect = correct;
                    if (user != this.MabowAccount1 && user != this.MabowAccount2) {
                      this.ActionCount = this.ActionCount + 2;
                    }
                  }

                } else {
                  //console.log("action 1")
                  this.lineChartData[1].data[indexoftimestamp1]++;
                  this.array2d_mabowQ[indexoftimestamp1].push(user);
                  var index = -1;
                  var val = user;
                  index = this.listbyuser.findIndex((item, i) => {
                    return item.id === val
                  });
                  //console.log("id=" + user + " index=" + index);
                  if (index != -1) {
                    this.listbyuser[index].Qact++;
                    this.listbyuser[index].Qview = Object.keys(obj).length;
                    //this.listbyuser[index].Qcorrect = correct;
                    if (user != this.MabowAccount1 && user != this.MabowAccount2) {
                      this.ActionCount++;
                    }
                  }
                }
              } else {
                var answerCount = 0;
                var stayToFinal = false;

                for (var j in obj) {
                  if (j == "5" && Object.keys(obj).length > 1) {
                    stayToFinal = true;
                  }
                  if (obj[j] > 0) {
                    answerCount = answerCount + 1;
                  }
                }

                if (stayToFinal) {
                  if (answerCount >= 1) {
                    //console.log("action 2")
                    this.lineChartData[1].data[indexoftimestamp1]++;
                    this.lineChartData[1].data[indexoftimestamp2]++;
                    this.array2d_mabowQ[indexoftimestamp1].push(user);
                    this.array2d_mabowQ[indexoftimestamp2].push(user);
                    var index = -1;
                    var val = user;
                    index = this.listbyuser.findIndex((item, i) => {
                      return item.id === val
                    });
                    //console.log("id=" + user + " index=" + index);
                    if (index != -1) {
                      this.listbyuser[index].Qact = this.listbyuser[index].Qact + 2;
                      this.listbyuser[index].Qview = Object.keys(obj).length;
                      this.listbyuser[index].Qcorrect = correct;
                      if (user != this.MabowAccount1 && user != this.MabowAccount2) {
                        this.ActionCount = this.ActionCount + 2;
                      }
                    }
                  } else {
                    //console.log("action 1")
                    this.lineChartData[1].data[indexoftimestamp1]++;
                    this.array2d_mabowQ[indexoftimestamp1].push(user);
                    var index = -1;
                    var val = user;
                    index = this.listbyuser.findIndex((item, i) => {
                      return item.id === val
                    });
                    //console.log("id=" + user + " index=" + index);
                    if (index != -1) {
                      this.listbyuser[index].Qact++;
                      this.listbyuser[index].Qview = Object.keys(obj).length;
                      //this.listbyuser[index].Qcorrect = correct;
                      if (user != this.MabowAccount1 && user != this.MabowAccount2) {
                        this.ActionCount++;
                      }
                    }
                  }
                } else {
                  if (answerCount >= 1) {
                    //console.log("action 1")
                    this.lineChartData[1].data[indexoftimestamp1]++;
                    this.array2d_mabowQ[indexoftimestamp1].push(user);
                    var val = user;
                    index = this.listbyuser.findIndex((item, i) => {
                      return item.id === val
                    });
                    //console.log("id=" + user + " index=" + index);
                    if (index != -1) {
                      this.listbyuser[index].Qact++;
                      this.listbyuser[index].Qview = Object.keys(obj).length;
                      this.listbyuser[index].Qcorrect = correct;
                      if (user != this.MabowAccount1 && user != this.MabowAccount2) {
                        this.ActionCount++;
                      }
                    }
                  } else {
                    this.lineChartData[1].data[indexoftimestamp1]++;
                    this.array2d_mabowQ[indexoftimestamp1].push(user);
                    var val = user;
                    index = this.listbyuser.findIndex((item, i) => {
                      return item.id === val
                    });
                    console.log(user + " doesn't have any action")
                    console.log("id=" + user + " index=" + index + " count=" + Object.keys(obj).length);
                    if (index != -1) {
                      this.listbyuser[index].Qview = Object.keys(obj).length;

                    }
                  }
                }
              }
            }
          }
        }

      },
      error: (error) => {
        alert("統計人數錯誤 請再按一次\n錯誤 : " + error.code + " " + error.message);
      }
    });
  }

  uploadLiveInteractionStatus() {
    if (this.correct_guess_number.length < 1 && this.selected.has_guess_number) {
      alert("尚未上傳猜數字正解,猜數字結果尚未比對");
      return;
    }
    console.log("in uploadLiveInteractionStatus")
    this.isLoading = true;
    var TimeToday = new Date();
    var today = TimeToday.getFullYear() + "/" + (TimeToday.getMonth() + 1) + "/" + TimeToday.getDate();// + " (星期" + TimeToday.getDay() + ")";
    var live_time = TimeToday.getHours() + ":" + TimeToday.getMinutes();
    var request_data = {};
    //request_data["日期"] = today;
    var weekday = ["日", "一", "二", "三", "四", "五", "六"];
    var weekdaystr = "";
    var livedate;
    switch (this.selected.type) {
      case 1:
        request_data["節目"] = "KTV";
        //livedate = new Date(TimeToday.getFullYear(), Number(this.selected.name.substr(0, 2)) - 1, Number(this.selected.name.substr(2, 2)));
        livedate = this.selected.end;
        console.log("livedate=" + livedate);
        weekdaystr = weekday[livedate.getDay()];
        //request_data["日期"] = this.selected.name.substr(0, 4) + "(" + weekdaystr + ")";
        request_data["日期"] = (this.selected.end.getMonth() + 1) + "" + this.paddingLeft(this.selected.end.getDate()) + "(" + weekdaystr + ")";
        break;
      case 2:
        request_data["節目"] = "Q";
        //livedate = new Date(TimeToday.getFullYear(), Number(this.selected.name.substr(5, 2)) - 1, Number(this.selected.name.substr(7, 2)));
        livedate = this.selected.end;
        console.log("livedate=" + livedate);
        weekdaystr = weekday[livedate.getDay()];
        //request_data["日期"] = this.selected.name.substr(5, 4) + "(" + weekdaystr + ")";
        request_data["日期"] = (this.selected.end.getMonth() + 1) + "" + this.paddingLeft(this.selected.end.getDate()) + "(" + weekdaystr + ")";
        break;
      case 3:
        request_data["節目"] = "TALK";
        //livedate = new Date(TimeToday.getFullYear(), Number(this.selected.name.substr(0, 2)) - 1, Number(this.selected.name.substr(2, 2)));
        livedate = this.selected.end;
        console.log("livedate=" + livedate);
        weekdaystr = weekday[livedate.getDay()];
        //request_data["日期"] = this.selected.name.substr(0, 4) + "(" + weekdaystr + ")";
        request_data["日期"] = (this.selected.end.getMonth() + 1) + "" + this.paddingLeft(this.selected.end.getDate()) + "(" + weekdaystr + ")";
        break;
      case 4:
        request_data["節目"] = "10分fun輕鬆";
        //livedate = new Date(TimeToday.getFullYear(), Number(this.selected.name.substr(0, 2)) - 1, Number(this.selected.name.substr(2, 2)));
        livedate = this.selected.end;
        console.log("livedate=" + livedate);
        weekdaystr = weekday[livedate.getDay()];
        //request_data["日期"] = this.selected.name.substr(0, 4) + "(" + weekdaystr + ")";
        request_data["日期"] = (this.selected.end.getMonth() + 1) + "" + this.paddingLeft(this.selected.end.getDate()) + "(" + weekdaystr + ")";
        break;
      default:
        request_data["節目"] = "未知";
        //livedate = new Date(TimeToday.getFullYear(), Number(this.selected.name.substr(0, 2)) - 1, Number(this.selected.name.substr(2, 2)));
        livedate = this.selected.end;
        console.log("livedate=" + livedate);
        weekdaystr = weekday[livedate.getDay()];
        //request_data["日期"] = this.selected.name.substr(5, 4) + "(" + weekdaystr + ")";
        request_data["日期"] = (this.selected.end.getMonth() + 1) + "" + this.paddingLeft(this.selected.end.getDate()) + "(" + weekdaystr + ")";
        break;
    }

    request_data["LiveInteractionObjectId"] = this.selected.id;
    request_data["房名"] = this.selected.name;
    request_data["上傳時間"] = today + " " + live_time;
    request_data["成功收看"] = this.WatchCount;
    //request_data["互動次數"] = this.ActionCount;

    var user = {};
    for (var i = 0; i < this.listbyuser.length; i++) {
      if (this.selected.type == 2) { //Q
        if (this.listbyuser[i].callintoktvroom > 0 || this.listbyuser[i].denytime != "") {
          var obj = {};
          obj["name"] = this.listbyuser[i].name;
          obj["elderid"] = this.listbyuser[i].elderid;
          obj["zendeskid"] = this.listbyuser[i].zendeskid;
          var leaveStatus = "";
          if (this.listbyuser[i].denytime != "" && Number(this.listbyuser[i].callintimestamp) == 0 && Number(this.listbyuser[i].leavetimestamp) == 0) {
            if (this.listbyuser[i].isVIPdeny) {
              obj["callintoktvroom"] = this.listbyuser[i].denytime + "VIP拒接";
            } else {
              obj["callintoktvroom"] = this.listbyuser[i].denytime + "拒接";
            }
            console.log(obj["name"] + " " + obj["callintoktvroom"])
          } else {
            console.log("callintimestamp=" + this.listbyuser[i].callintimestamp + " leavetimestamp=" + this.listbyuser[i].leavetimestamp)
            if (Number(this.listbyuser[i].leavetimestamp) > 0) {
              var liveEndtimestamp = this.selected.end.getTime();
              console.log("liveEndtimestamp=" + liveEndtimestamp + " leavecheck=" + (liveEndtimestamp - Number(this.listbyuser[i].leavetimestamp)) / 1000 + " sec")
              if ((liveEndtimestamp - Number(this.listbyuser[i].leavetimestamp)) / 1000 <= 900) {
                leaveStatus = "(看到最後)"
              }
            }
            if (Number(this.listbyuser[i].callintimestamp) > 0 && Number(this.listbyuser[i].leavetimestamp) > 0) {
              var watch_time = (Number(this.listbyuser[i].leavetimestamp) - Number(this.listbyuser[i].callintimestamp)) / 1000; //seconds
              console.log(obj["name"] + "收看 " + watch_time + " 秒")
              if (watch_time < 600 && leaveStatus != "(看到最後)") {
                leaveStatus = "(快速離開)";
              }
            }

            obj["callintoktvroom"] = this.listbyuser[i].callintime + "成功進入," + this.listbyuser[i].leavetime + "離開" + leaveStatus;
            console.log(i + " " + this.listbyuser[i].name + ":" + this.listbyuser[i].Qview)
            console.dir(this.listbyuser[i])
            obj["qview"] = "觀看" + this.listbyuser[i].Qview + "題";
            if (this.listbyuser[i].Qcorrect != -1) {
              obj["qcorrect"] = "答對" + this.listbyuser[i].Qcorrect + "題";

            } else {
              obj["qcorrect"] = "未作答";
            }
          }
          user[this.listbyuser[i].number] = obj;
          request_data["data"] = user;
        }
      } else if (this.selected.type == 4 || this.selected.type == 5) {  // 10分fun輕鬆
        if (this.listbyuser[i].callintoktvroom > 0 || this.listbyuser[i].denytime != "") {
          var obj = {};
          obj["name"] = this.listbyuser[i].name;
          obj["elderid"] = this.listbyuser[i].elderid;
          obj["zendeskid"] = this.listbyuser[i].zendeskid;
          var leaveStatus = "";
          if (this.listbyuser[i].denytime != "" && Number(this.listbyuser[i].callintimestamp) == 0 && Number(this.listbyuser[i].leavetimestamp) == 0) {
            if (this.listbyuser[i].isVIPdeny) {
              obj["callintoktvroom"] = this.listbyuser[i].denytime + "VIP拒接";
            } else {
              obj["callintoktvroom"] = this.listbyuser[i].denytime + "拒接";
            }
            console.log(obj["name"] + " " + obj["callintoktvroom"])
          } else {
            console.log("callintimestamp=" + this.listbyuser[i].callintimestamp + " leavetimestamp=" + this.listbyuser[i].leavetimestamp)

            /*if (Number(this.listbyuser[i].leavetimestamp) > 0) {
              var liveEndtimestamp = this.selected.end.getTime();
              console.log("liveEndtimestamp=" + liveEndtimestamp + " leavecheck=" + (liveEndtimestamp - Number(this.listbyuser[i].leavetimestamp)) / 1000 + " sec")
              if ((liveEndtimestamp - Number(this.listbyuser[i].leavetimestamp)) / 1000 <= 900) {
                leaveStatus = "(看到最後)"
              }
            }*/
            if (Number(this.listbyuser[i].callintimestamp) > 0 && Number(this.listbyuser[i].leavetimestamp) > 0) {
              var watch_time = (Number(this.listbyuser[i].leavetimestamp) - Number(this.listbyuser[i].callintimestamp)) / 1000; //seconds
              console.log(obj["name"] + "收看 " + watch_time + " 秒")
              if (watch_time > (this.selected.duration + 5)) {
                leaveStatus = "(狀態可能異常)";
              } else if (watch_time < 10) {
                leaveStatus = "(快速離開)";
              } else if (watch_time > (this.selected.duration - 5)) {
                leaveStatus = "(看到最後)";
                this.listbyuser[i].likeinktv = 1; // treat as 1 action
              } else {
                leaveStatus = "";
              }
            }
            obj["callintoktvroom"] = this.listbyuser[i].callintime + "成功進入," + this.listbyuser[i].leavetime + "離開" + leaveStatus;
          }

          if (this.listbyuser[i].likeinktv > 0) {
            obj["likeinktv"] = 1;
            this.ActionCount = this.ActionCount + 1;
            console.log("看到最後:" + this.listbyuser[i].name)
          }
          user[this.listbyuser[i].number] = obj;
          request_data["data"] = user;

        }
      } else { //KTV, TALK
        if (this.listbyuser[i].callintoktvroom > 0 || this.listbyuser[i].denytime != "") {
          var obj = {};
          obj["name"] = this.listbyuser[i].name;
          obj["elderid"] = this.listbyuser[i].elderid;
          obj["zendeskid"] = this.listbyuser[i].zendeskid;
          var leaveStatus = "";
          if (this.listbyuser[i].denytime != "" && Number(this.listbyuser[i].callintimestamp) == 0 && Number(this.listbyuser[i].leavetimestamp) == 0) {
            if (this.listbyuser[i].isVIPdeny) {
              obj["callintoktvroom"] = this.listbyuser[i].denytime + "VIP拒接";
            } else {
              obj["callintoktvroom"] = this.listbyuser[i].denytime + "拒接";
            }
            console.log(obj["name"] + " " + obj["callintoktvroom"])
          } else {
            console.log("callintimestamp=" + this.listbyuser[i].callintimestamp + " leavetimestamp=" + this.listbyuser[i].leavetimestamp)

            if (Number(this.listbyuser[i].leavetimestamp) > 0) {
              var liveEndtimestamp = this.selected.end.getTime();
              console.log("liveEndtimestamp=" + liveEndtimestamp + " leavecheck=" + (liveEndtimestamp - Number(this.listbyuser[i].leavetimestamp)) / 1000 + " sec")
              if ((liveEndtimestamp - Number(this.listbyuser[i].leavetimestamp)) / 1000 <= 900) {
                leaveStatus = "(看到最後)"
              }
            }
            if (Number(this.listbyuser[i].callintimestamp) > 0 && Number(this.listbyuser[i].leavetimestamp) > 0) {
              var watch_time = (Number(this.listbyuser[i].leavetimestamp) - Number(this.listbyuser[i].callintimestamp)) / 1000; //seconds
              console.log(obj["name"] + "收看 " + watch_time + " 秒")
              if (watch_time < 600 && leaveStatus != "(看到最後)") {
                leaveStatus = "(快速離開)";
              }
            }
            obj["callintoktvroom"] = this.listbyuser[i].callintime + "成功進入," + this.listbyuser[i].leavetime + "離開" + leaveStatus;
          }
          if (this.listbyuser[i].singing > 0) {
            obj["enterktvroom"] = 1;
          }
          if (this.listbyuser[i].likeinktv > 0) {
            obj["likeinktv"] = 1;
          }
          if (this.listbyuser[i].guess > 0) {
            obj["guess"] = this.listbyuser[i].guess;
          }
          if (this.listbyuser[i].choose > 0) {
            obj["choose"] = 1;
          }
          user[this.listbyuser[i].number] = obj;
          request_data["data"] = user;

        }
      }
    }
    request_data["互動次數"] = this.ActionCount;
    console.log("request_data >>>");
    console.dir(request_data);
    this.sendPost(request_data);
  }

  checkVIPStatus() {
    console.log("in checkVIPStatus")
    this.isLoading = true;
    var request_data = {};
    request_data["日期"] = "VIP指數"
    this.sendPost(request_data);
  }

  sendPost(body) {
    console.log("sendPost...")
    const url = 'https://nbt1ttri0k.execute-api.ap-northeast-1.amazonaws.com/pro/send';
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    // use HTPP POST
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this._http
      .post(url, body, httpOptions).subscribe(data => {   // get response
        this.isLoading = false;
        console.log("HTTP POST done")
        console.dir(data)
        alert(data)
      }, error => {  // error callback
        this.isLoading = false;
        console.log("HTTP POST error")
        console.dir(error)
        alert("發生錯誤 : " + error.text());
      });
  }

  /**
   *quary_live_interaction() 
   */
  private quary_live_interaction() {
    console.log("quary_live_interaction ");
    var LiveInteraction = Parse.Object.extend("LiveInteraction");
    var LiveInteraction = new LiveInteraction();
    var query = new Parse.Query(LiveInteraction);
    query.descending("createdAt");
    //query.equalTo('status', -1);//status = 1, on air
    query.exists('live_start');//status = 1, on air
    query.exists('live_end');//status = 1, on air

    query.limit(1000);
    query.find({
      success: (results) => {
        this.liveinteractionlist = [];
        for (var i = 0; i < results.length; i++) {
          var live_temp: liveinteraction = {
            id: "default",
            createdAt: "default",
            name: "default",
            start: new Date,
            end: new Date,
            type: 0,
            duration: 0,
            has_guess_number: false,
            correct_guess_number: [],
          };
          live_temp.createdAt = results[i].get("createdAt");
          live_temp.id = results[i].id;
          live_temp.name = results[i].get("name");
          var start_time = results[i].get("live_start");
          if (start_time)
            start_time.setSeconds(0);
          live_temp.start = start_time;
          var end_time = results[i].get("live_end");
          if (end_time)
            end_time.setSeconds(0);
          live_temp.end = end_time;
          if (results[i].get("videoDuration")) {
            live_temp.duration = results[i].get("videoDuration");
          }
          if (results[i].get("programStatus")) {
            var obj = results[i].get("programStatus");
            live_temp.type = Number(Object.keys(obj)[0]);
            if (JSON.stringify(obj).includes("724gXDynIS"))//有猜數字活動
              live_temp.has_guess_number = true;
          }
          if (results[i].get("guess_ball")) {
            var array = results[i].get("guess_ball");
            console.dir(array);
            for (var num in array)
              live_temp.correct_guess_number.push(array[num]);
          }
          this.liveinteractionlist.push(live_temp);
        }

      },
      error: (error) => {
        alert("Error: " + error.code + " " + error.message);
      }
    });

    var InteractionTypeDefine = Parse.Object.extend("interactionTypeDefine");
    var interactionTypeDefine = new InteractionTypeDefine();
    var queryType = new Parse.Query(interactionTypeDefine);
    queryType.include("layout");
    queryType.find({
      success: (results) => {
        console.log("get interactionTypeDefine done..." + results.length)
        for (var i = 0; i < results.length; i++) {
          if (results[i].get("layout") != null && results[i].get("layout").id == "3FL5NoBFsW") {  // layout of Like 
            var TypeLikeStr = "Pinpoint_EVENT_LIVEINTERACTION_" + results[i].id;
            this.arrayLikeEventType.push(TypeLikeStr)
          }
        }
        this.arrayLikeEventType.push(this.RecordEvent.Pinpoint_EVENT_LIKE_WATCHING_LIVEINTERACTION);
        console.dir(this.arrayLikeEventType)
      },
      error: (error) => {
        alert("Find InteractionTypeDefine Error: " + error.code + " " + error.message);
      }
    });
  }

  select_onChange() {
    console.log("select change " + this.selected.id + " type=" + this.selected.type);
    console.dir(this.selected.correct_guess_number);
    this.clear_init_Data();
    this.retrieveDataFromAWS();
    this.correct_guess_number = this.selected.correct_guess_number;
  }

  /**
   * 
   * @param url interface fot heep get protocol
   */
  private getDataObservable(url: string) {
    return this._http.get(url)
  }

  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }


  /**
   * retrive pinpont from aws api
   */
  private retrieveDataFromAWS() {
    var start_t = this.selected.start.getTime();
    this.isLoading = true;
    var GET_URL_with_ID = this.aws_analysis_url + "'" + this.selected.id + "'" + "?start_index=" + start_t;
    console.log("retrieveDataFromAWS id_url = " + GET_URL_with_ID);
    this.getDataObservable(GET_URL_with_ID).subscribe(
      data => {
        console.dir(data)
        this.liveinteractionDatalist = data['records'];
        //Sort by time to help filling in 2darray logic
        this.liveinteractionDatalist.sort((a, b) => (a.event_timestamp > b.event_timestamp) ? 1 : ((b.event_timestamp > a.event_timestamp) ? -1 : 0));
        console.dir(this.liveinteractionDatalist)
        console.log("event length: ", this.liveinteractionDatalist.length);
        const period_minute = (this.selected.end.getTime() - this.selected.start.getTime()) / 60 / 1000;
        console.log("period_minute: ", period_minute);
        this.goPlot(this.selected.start.getTime(), period_minute);
      },
      error => {
        this.errorCallback(-1, null);
      }
    );

  }

  /**
   * 
   * @param t 
   * @param message 
   */
  private errorCallback(t: number, message: string) {
    switch (t) {
      case -1:
        alert(" 擷取aws data 錯誤代碼：" + t + " " + message);
        this.isLoading = false;

        break;
    }
  }


  /**
   * goPlot
   * @param first_event_time 
   * @param period_minute 
   */
  goPlot(first_event_time: number, period_minute: number) {
    console.error("in fopgoPlotlot")
    if (period_minute < 0)
      console.error("first_event_time or first_event_last wrong");

    // clean all data
    this.lineChartLabels = [];
    //this.lineChartDataCache = [];
    this.array2d_interaction = [];
    this.array2d_mabowQ = [];
    this.array2d_callinevent = [];
    this.array2d_ktvevent = [];
    this.array2d_likeevent = [];
    this.array2d_guessevent = [];
    this.array2d_chooseevent = [];

    const data_l = period_minute + 1;
    // init the x time plot
    for (var i = 0; i < data_l; i++) {
      var formattedDate = moment.unix(first_event_time / 1000 + i * 60).format("HH:mm");
      this.lineChartLabels.push(formattedDate); //create x axis
      this.lineChartData[0].data[i] = 0;//Interaction
      this.lineChartData[1].data[i] = 0;//Mabow Q
      this.lineChartData[2].data[i] = 0;//Call in event
      this.lineChartData[3].data[i] = 0;//KTV event
      this.lineChartData[4].data[i] = 0;//Like event
      this.lineChartData[5].data[i] = 0;//Guess event
      this.lineChartData[6].data[i] = 0;//Choose event
      this.array2d_interaction.push([]);
      this.array2d_mabowQ.push([]);
      this.array2d_callinevent.push([]);
      this.array2d_ktvevent.push([]);
      this.array2d_likeevent.push([]);
      this.array2d_guessevent.push([]);
      this.array2d_chooseevent.push([]);
    }

    // MabowQ
    if (this.selected.type == 2) {
      this.parse_data(first_event_time, period_minute);
      this.parse_Q_data(first_event_time, period_minute);
    } else { // KTV or TALK
      // calculate plot number
      this.parse_data(first_event_time, period_minute);
    }

  }

  getItems() {  // only show ktv events
    return this.listbyuser.filter((item) =>
      item.enterktvroom != 0 || item.callintoktvroom != 0);
  }

  getItemsQ() {  // only show Q events
    return this.listbyuser.filter((item) =>
      item.enterktvroom != 0 || item.callintoktvroom != 0 || item.Qact != 0);
  }

  /* Update/Add the guess_ball number */
  upload_correct_guess_number() {
    this.isLoading = true;
    if (this.correct_guess_number.indexOf(this.correct_guess_number_candidate) < 0) {
      this.correct_guess_number.push(this.correct_guess_number_candidate);
      var LiveInteraction = Parse.Object.extend("LiveInteraction");
      var query = new Parse.Query(LiveInteraction);
      query.get(this.selected.id)
        .then((liveinteraction) => {
          liveinteraction.set("guess_ball", this.correct_guess_number);
          liveinteraction.save();
          this.check_guess_ball_correction();
          this.isLoading = false;
        }, (error) => {
          console.error(error);
        });
    } else
      alert("重複輸入或格式錯誤");
  }

  /* Clear guess_ball by setting a null object  */
  clear_guess_number() {
    console.log("clear_guess_number")
    this.isLoading = true;
    var LiveInteraction = Parse.Object.extend("LiveInteraction");
    var query = new Parse.Query(LiveInteraction);
    query.get(this.selected.id)
      .then((liveinteraction) => {
        liveinteraction.set("guess_ball", null);
        liveinteraction.save();
        this.check_guess_ball_correction();
        this.isLoading = false;
      }, (error) => {
        console.error(error);
      });
    this.correct_guess_number = [];
  }

  paddingLeft(str) {

    //0~9 -> 00~09
    if (str < 10 && str >= 0) {
      return ("0" + str);
    } else {
      return str;
    }
  }

}
