import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'CareTodayPipe'
})
export class CareTodayPipe {
    strcmp(a, b) {
        if (a.toString() < b.toString()) return -1;
        if (a.toString() > b.toString()) return 1;
        return 0;
    }
    transform(value, args?) {
        var sortby = args;
        return value.filter(caretoday => {
            if (!this.strcmp(sortby, "全部"))
                return caretoday.IsDone;
            else if (!this.strcmp(sortby, "成功CARE"))
                return caretoday.IsDone == "是";
            else if (!this.strcmp(sortby, "未成功CARE"))
                return caretoday.IsDone == "否";
            else
                return caretoday.elder == sortby;
        });
    }


}