export class ComminnicatonParameterDefine {
    public compPurpose: Array<String> = ["call-out關懷", "純粹溝通", "長輩call-in", "其他"] // can not contain any space!!!!!!!!!!!

    comSuccessCallbackDefine = [
        {
            'successWithi': true,
            'successWithouti': true,
            'failWithi': true,
            'failWithouti': true,
            'issue': true
        },
        {
            'successWithi': true,
            'successWithouti': true,
            'failWithi': true,
            'failWithouti': true,
            'issue': true
        },
        {
            'successWithi': true,
            'successWithouti': true,
            'failWithi': true,
            'failWithouti': true,
            'issue': true
        },
        {
            'successWithi': true,
            'successWithouti': true,
            'failWithi': true,
            'failWithouti': true,
            'issue': true
        },
        {
            'successWithi': true,
            'successWithouti': true,
            'failWithi': true,
            'failWithouti': true,
            'issue': true
        },
    ];

    addMessageDefine = [
        {
            'careInfo': true,
            'careVideo': true,
            'i': true,
            'detailI': true,
            'note': true,
            'isDone': true
        }, {
            'careInfo': false,
            'careVideo': true,
            'i': false,
            'detailI': false,
            'note': true,
            'isDone': true
        },
        {
            'careInfo': true,
            'careVideo': true,
            'i': true,
            'detailI': true,
            'note': true,
            'isDone': false

        },
        {
            'careInfo': false,
            'careVideo': true,
            'i': false,
            'detailI': false,
            'note': true,
            'isDone': false
        },

    ]
    comTools: Array<String> = ["JPhone", "市話", "Line", "網頁版"];
    comTools_zoom: Array<String> = ["JPhone(390使用)", "JPhone", "ZOOM", "市話", "Line", "網頁版"];
    comPC: Array<String> = ["PC1", "PC2", "PC3", "Others"];

}
