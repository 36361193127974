import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
import { user } from '../eldermanagement/user';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { ElderData } from '../select-elder/elder_data';

declare var Parse: any;

@Component({
  selector: 'app-eldermanagementdetail',
  templateUrl: './eldermanagementdetail.component.html',
  styleUrls: ['./eldermanagementdetail.component.css']
})
export class EldermanagementdetailComponent extends ParentClass implements OnInit {
  globalService: GlobalService;
  group: number;
  isLoading: boolean = true;
  secretarylists: user[] = [];
  elderlists: user[] = [];
  elderuseridArray = [];

  constructor(router: Router, private _globalservice: GlobalService) {
    super(router);
    this.group = _globalservice.eldermanagementdetailgroup;
    this.globalService = _globalservice;



  }
  ngOnInit() {
    console.log("group: " + this.group);
    if (this.group == -1)
      this.findmygroup();
    else
      this.QueryCareGroupManagement(this.group);
  }

  findmygroup() {
    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    var caregroupmanagement = new CareGroupManagement();
    var query = new Parse.Query(CareGroupManagement);
    //query.include('user');
    var userPointer = {
      __type: 'Pointer',
      className: '_User',
      objectId: Parse.User.current().id
    }
    query.equalTo('user', userPointer);
    query.find({
      success: (results) => {
        console.log("Successfully retrieved " + results.length + " users.");
        if (results.length == 0)
          this.isLoading = false;
        else {
          this.group = results[0].get("group");
          if (this.group > 0)
            this.QueryCareGroupManagement(this.group);
          else
            this.isLoading = false;
        }
      },
      error: function (error) {
        this.isLoading = false;
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  QueryCareGroupManagement(group: number) {
    console.log("in QueryCareGroupManagement ")
    //this.isLoading = true;
    //this.eldergroups = [];
    this.secretarylists = [];
    this.elderlists = [];
    //this.nogroupelder = [];
    //this.nogroupsecretary = [];
    //this.userlistincaregroupmanagement = [];
    var linksecretary = "";
    var secretary_no = 0;
    var lastcare;
    var userNickname;
    var current_contract;
    var careexpiredate;

    // empty array prevent duplicate
    this.elderuseridArray = [];


    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    var caregroupmanagement = new CareGroupManagement();
    var query = new Parse.Query(CareGroupManagement);
    //var group_count = 1;
    query.include('user');
    query.include('elderdetailinfo');
    query.equalTo("group", group);
    query.descending("type");
    query.addAscending("lastcare");
    query.limit(1000);

    query.find({
      success: (results) => {
        console.log("Successfully retrieved " + results.length + " users.");

        for (var i = 0; i < results.length; i++) {
          var object = results[i];
          if (object.get('type') == 1) {// elder
            var secretary_no_ = 0;
            if (object.get('linksecretary') && object.get("newcomer") == true) {
              linksecretary = object.get('linksecretary').id;
              if (this.secretarylists) {
                var arr = this.secretarylists.filter(function (value) {
                  return value.id == linksecretary;
                });
                if (arr.length > 0)
                  secretary_no_ = arr[0].secretary_no;
              }

            } else
              linksecretary = "";

            if (object.get('lastcare')) {
              var date = object.get('lastcare');
              lastcare = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
              // lastcare = object.get('lastcare').replace(/-/g,'/');
              //lastcare =new Date(lastcare);
            } else
              lastcare = "";

            var imageUrl = "http://semantic-ui.com/images/avatar2/large/kristy.png";
            if (object.get('user').get("userProfileImageSmall") != null) {
              imageUrl = object.get('user').get("userProfileImageSmall").url();
            }

            userNickname = "";
            current_contract = null;
            careexpiredate = null;
            if (object.get("elderdetailinfo")) {
              if (object.get("elderdetailinfo").get("NickName"))
                userNickname = object.get('elderdetailinfo').get("NickName");

              if (object.get("elderdetailinfo").get("current_contract")) {
                current_contract = object.get('elderdetailinfo').get("current_contract");
              }

              if (object.get("elderdetailinfo").get("careexpiredate")) {
                var date1 = object.get('elderdetailinfo').get("careexpiredate");
                careexpiredate = date1.getFullYear() + "/" + (date1.getMonth() + 1) + "/" + date1.getDate();
              }
            }

            this.elderlists.push({
              name: userNickname,
              account: object.get('user').get("username"),
              ImageURL: imageUrl,
              id: object.id,
              group: object.get('group'),
              linksecretary: linksecretary,
              secretary_no: secretary_no_,
              lastcare: lastcare,
              userobject: object.get('user'),
              status: {
                deviceStatus: 0,
                screenStatus: 0
              },
              current_contract: current_contract,
              careexpiredate: careexpiredate,
              selected: false,
              elderDetailInfoObject: object.get("elderdetailinfo")
            });

            // fill elder user id
            this.elderuseridArray.push(object.get('user').id);

          } else if (object.get('type') == 2) {// secretary
            secretary_no++;
            var imageUrl = "http://semantic-ui.com/images/avatar2/large/kristy.png";
            if (object.get('user').get("userProfileImageSmall") != null) {
              imageUrl = object.get('user').get("userProfileImageSmall").url();
            }

            userNickname = "";
            if (object.get("user").get("userNickname")) {
              userNickname = object.get("user").get("userNickname");
            }

            this.secretarylists.push({
              name: userNickname,
              account: object.get('user').get("username"),
              ImageURL: imageUrl,
              id: object.id,
              group: object.get('group'),
              linksecretary: "",
              secretary_no: secretary_no,
              lastcare: lastcare,
              userobject: object.get('user'),
              status: {
                deviceStatus: 0,
                screenStatus: 0
              },
              current_contract: current_contract,
              careexpiredate: careexpiredate,
              selected: false,
              elderDetailInfoObject: object.get("elderdetailinfo")
            });
          }

          // last run
          if (i == results.length - 1) {
            this.checkIsUserDeviceOnline();
            this.checkIsDeviceScreenon();
            this.isLoading = false;
            this.globalService.eldermanagementdetailgroup = -1;

          }
        }


      },
      error: function (error) {
        this.isLoading = false;
        alert("Error: " + error.code + " " + error.message + ' please retry ');
        this.globalService.eldermanagementdetailgroup = -1;

      }
    });
  }

  getStyle(secretary_no: number) {
    switch (secretary_no) {
      case 1:
        return "#fff3aa";
      case 2:
        return "#ffd0a8";
      case 3:
        return "#ffb1b1";
      case 4:
        return "#d9d1ff";
      case 5:
        return "#b7efff";
      default:
        return "";
    }
  }

  goto(user_: user) {
    if (!confirm("，1. 檢查Ocam麥克風打勾 2. 確認是 screen recording 3. 請確認已經開始使用 OCAM 錄影 "))
      return;
    var elderdata = new ElderData();
    elderdata.fullUserObject = user_.userobject;
    elderdata.pmi = user_.userobject.get('pmi');
    elderdata.mabowid = user_.userobject.get("username");
    elderdata.user_object_id = user_.userobject.id;
    elderdata.elderdetailobjectid = user_.elderDetailInfoObject.id;
    elderdata.elderDetailInfoObject = user_.elderDetailInfoObject;
    if (user_.userobject.get("userProfileImageSmall")) {
      elderdata.imageUrl = user_.userobject.get("userProfileImageSmall").url();

    }
    console.log("in goto function , and go to id page =" + elderdata.mabowid);
    this.globalService.focusParseUserObject = elderdata;
    this.router.navigateByUrl('/prepareCare');
  }

  checkrecord(user_: user) {
    var elderdata = new ElderData();
    elderdata.fullUserObject = user_.userobject;
    elderdata.pmi = user_.userobject.get('pmi');
    elderdata.user_object_id = user_.userobject.id;
    elderdata.mabowid = user_.userobject.get("username");
    console.log("in checkrecord function , and go to id page =" + elderdata.mabowid);
    this.globalService.focusParseUserObject = elderdata;
    this.router.navigateByUrl('/caredetails');
  }


  /**
   * checkIsUserDeviceOnline
   */
  checkIsUserDeviceOnline() {

    console.log("in checkIsUserDeviceOnline");

    Parse.Cloud.run("checkIsDevicePrescence", {
      "channelsArray": this.elderuseridArray,

    }).then((results) => {
      // make sure the set the enail sent flag on the object
      console.dir(results)

      results.forEach((isonline: boolean, index: number) => {
        if (isonline == true) {
          this.elderlists[index].status.deviceStatus = 1;
        } else {
          //console.dir(this.elderlists)
          const OwnerDeviceArray = this.elderlists[index].userobject.get("loginDevice");

          if (OwnerDeviceArray == null) {
            console.warn("user " + this.elderlists[index].userobject.id + " old0 so cannot detact");

          } else {

            if (OwnerDeviceArray.length == 0) {
              console.warn("user " + this.elderlists[index].userobject.id + " old so cannot detact");
            } else if (OwnerDeviceArray.length == 1 && (OwnerDeviceArray.indexOf("gateMerge") != -1 || OwnerDeviceArray.indexOf("gatemerge") != -1)) {
              console.warn("user " + this.elderlists[index].userobject.id + " old2 so cannot detact");
            } else {
              console.error("user " + this.elderlists[index].userobject.id + " offline " + this.elderlists[index].userobject);
              this.elderlists[index].status.deviceStatus = -1;
            }
          }

        }
      });

    }, (error) => {
      console.error("in checkIsUserDeviceOnline prescence error " + error);

    });
    this.getDeviceonlineOld();
  }


  checkIsDeviceScreenon() {
    console.log("checkIsDeviceScreenon");


  }

  getDeviceonlineOld() {
    console.log("getDeviceonlineOld")
    /*
    this.http.get('https://s.mabow.com.tw/getuserinfo/alex9551623')
      .map(res => res.text())
      .subscribe(
      //data => this.randomQuote = data,
      //err => this.logError(err),
      () => console.log('Random Quote Complete')
      );*/

  }

}
