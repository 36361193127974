import { ListTemplete } from './ListTemplete'
import { Validators } from '@angular/forms';



export const _list: ListTemplete[] = [{
    input: {
        enable: true,
        type: "",
        isRequired: true,
        placeHolded: "a",
        content: "",
        title: "a",
        icon: "",
        control: 1

    },
    dropwown: {
        enable: false,
        type: "",
        isRequired: false,
        placeHolded: "",
        content: [],
        title: "",

    },
    sectionTitle: "",
    sectionsubTitle: "",

    isCheckbox: false,
    isCheckboxRequired: false,
    problemUrl: "",
},
{
    input: {
        enable: true,
        type: "",
        isRequired: true,
        placeHolded: "",
        content: "",
        title: "",
        icon: "",
        control: 2

    },
    dropwown: {
        enable: false,
        type: "",
        isRequired: false,
        placeHolded: "",
        content: [],
        title: "",

    },
    sectionTitle: "",
    sectionsubTitle: "",

    isCheckbox: false,
    isCheckboxRequired: false,
    problemUrl: "",
}

];