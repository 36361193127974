import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'LiveKtvsingerPipe2Pipe'
})
export class LiveKtvsingerPipe2Pipe implements PipeTransform {

  transform(value, args?) {
    var sortby = args;
    return value.filter(song => {
      return song.singer == sortby;
    });
  }


}