import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { secretary_data, elder_data, manage_data } from './statistic_data';

import * as moment from "moment";
declare var Parse: any;

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css']
})
export class StatisticComponent extends ParentClass implements OnInit {
  public formattedDay;
  public selectedDay;
  public selectedMonth;
  sel: number;
  Secretary_data: secretary_data[] = [];
  Elder_data: elder_data[] = [];
  Manage_data: manage_data = {
    name: "default",
    success_count_by_Noprotocal: 0,
    success_count_by_protocalA: 0,//顧幫手
    success_count_by_protocalB: 0,//秘書服
    success_rate_by_Noprotocal: 0,
    success_rate_by_protocalA: 0,//顧幫手
    success_rate_by_protocalB: 0,//秘書服
    total_conunt_by_Noprotocal: 0,
    total_conunt_by_protocalA: 0,
    total_conunt_by_protocalB: 0,
  };

  public NoprotocalLabels: string[] = ['未達成人數', '已達成人數'];
  public NoprotocalChartData: number[];
  public NoprotocalChartType: string = 'pie';
  public protocalALabels: string[] = ['未達成人數', '已達成人數'];
  public protocalAChartData: number[];
  public protocalAChartType: string = 'pie';
  public protocalBLabels: string[] = ['未達成人數', '已達成人數'];
  public protocalBChartData: number[];
  public protocalBChartType: string = 'pie';


  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  isLoading: boolean = true;

  constructor(router: Router) {
    super(router);

  }

  ngOnInit(): void {



    let time = moment();
    this.selectedDay = { year: time.year(), month: time.month(), day: time.date() };
    this.formattedDay = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).format("YYYY/MM");

    this.QueryCareRecordInfobySecretary();
    this.QueryCareRecordInfobyelder();

  }

  QueryCareRecordInfobySecretary() {
    this.Secretary_data = [];

    var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
    var carerecordinfo = new CareRecordInfo();
    var query = new Parse.Query(CareRecordInfo);
    query.include('careGiver');
    query.include('elder');
    var currentDate_start = new Date(this.selectedDay.year, this.selectedDay.month, 1, 0, 0, 0, 0);
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day);
    var currentDate_end = new Date(time.year(), time.month(), time.daysInMonth(), 23, 59, 59, 99);

    console.log(" currentDate_start: " + currentDate_start);
    console.log(" currentDate_end: " + currentDate_end);

    query.greaterThan("createdAt", currentDate_start);
    query.lessThan("createdAt", currentDate_end);

    query.ascending("careGiver");
    query.addAscending("elder");
    query.exists("careGiver");
    query.limit(1000);

    query.find({
      success: (results) => {
        var secretary_data_temp: secretary_data = {
          name: "default",
          success_count_by_elder: 0,
          success_count_by_call: 0,
          total_conunt_by_call: 0,
          total_conunt_by_elder: 0
        };

        var elder = "undefined";
        var isdone;

        //        console.log("Successfully retrieved " + results.length + " CareRecordInfo");
        for (var i = 0; i < results.length; i++) {
          var object = results[i];
          //  console.log(" retrieved " + i + " caregiver " + object.get('careGiver').get('username') + " length " + this.Secretary_data.length + " id: " + object.id);

          var secretaryname;
          if (object.get('careGiver').get('userNickname'))
            secretaryname = object.get('careGiver').get('userNickname')
          else
            secretaryname = object.get('careGiver').get('username')

          if (secretary_data_temp.name != secretaryname && secretary_data_temp.name != "default") {
            /* console.log(" push " + secretary_data_temp.name + " " +
               secretary_data_temp.success_count_by_call + " " +
               secretary_data_temp.success_count_by_elder + " " +
               secretary_data_temp.total_conunt_by_call + " " +
               secretary_data_temp.total_conunt_by_elder);*/
            this.Secretary_data.push({
              name: secretary_data_temp.name,
              success_count_by_call: secretary_data_temp.success_count_by_call,
              success_count_by_elder: secretary_data_temp.success_count_by_elder,
              total_conunt_by_call: secretary_data_temp.total_conunt_by_call,
              total_conunt_by_elder: secretary_data_temp.total_conunt_by_elder
            });

            secretary_data_temp.success_count_by_call = 0;
            secretary_data_temp.total_conunt_by_call = 0;
            secretary_data_temp.success_count_by_elder = 0;
            secretary_data_temp.total_conunt_by_elder = 0;

          }

          secretary_data_temp.name = secretaryname;

          secretary_data_temp.total_conunt_by_call++;
          if (object.get('isDone'))
            secretary_data_temp.success_count_by_call++;

          if (elder != object.get('elder').get("username")) {   // different elder
            isdone = false;
            elder = object.get('elder').get("username");
            secretary_data_temp.total_conunt_by_elder++;
            if (object.get('isDone')) {
              isdone = true;
              secretary_data_temp.success_count_by_elder++;
            }
          } else if (object.get('isDone') && isdone == false) {
            isdone = true;
            secretary_data_temp.success_count_by_elder++;
          }
        }
        this.Secretary_data.push(secretary_data_temp);
        //       this.isLoading = false;
      },
      error: function (error) {
        //       this.isLoading = false;
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  QueryCareRecordInfobyelder() {
    this.isLoading = true;
    var elder_data_temp: elder_data = {
      name: "default",
      nickname: "default",
      contract: 0,
      success_call_count: 0,
      total_call_count: 0,
      call_time_minute: 0,
      iscomplete: "否",
      caretimeneeded: 0,
      start_time: 0,
      end_time: 0,
    };
    this.Manage_data = {
      name: "default",
      success_count_by_Noprotocal: 0,
      success_count_by_protocalA: 0,//顧幫手
      success_count_by_protocalB: 0,//秘書服
      success_rate_by_Noprotocal: 0,
      success_rate_by_protocalA: 0,//顧幫手
      success_rate_by_protocalB: 0,//秘書服
      total_conunt_by_Noprotocal: 0,
      total_conunt_by_protocalA: 0,
      total_conunt_by_protocalB: 0,
    };

    this.Elder_data = [];

    var SubscriptionDetail = Parse.Object.extend("SubscriptionDetail");
    var subscriptionDetail = new SubscriptionDetail();
    var query = new Parse.Query(SubscriptionDetail);
    query.include('user');
    var currentDate_start = new Date(this.selectedDay.year, this.selectedDay.month, 1, 0, 0, 0, 0);
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day);//.add(1, 'day');;
    var currentDate_end = new Date(time.year(), time.month(), time.daysInMonth(), 23, 59, 59, 99);
    var total_miute_this_month = (currentDate_end.getTime() - currentDate_start.getTime()) / 60000;



    query.greaterThan("subscriptionEndDate", currentDate_start);
    query.lessThan("subscriptionStartDate", currentDate_end);
    query.ascending("user");
    query.limit(1000);


    query.find({
      success: (results0) => {

        var temp_array = [];
        var count = 0;

        for (var i = 0; i < results0.length; i++) {
          var elder_data_temp: elder_data = {
            name: "default",
            nickname: "default",
            contract: 0,
            success_call_count: 0,
            total_call_count: 0,
            call_time_minute: 0,
            iscomplete: "否",
            caretimeneeded: 0,
            start_time: 0,
            end_time: 0,
          };
          var object = results0[i];
          var caretimeneeded = 0;
          var currentDate_start = new Date(this.selectedDay.year, this.selectedDay.month, 1, 0, 0, 0, 0);
          let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day);//.add(1, 'day');;
          var currentDate_end = new Date(time.year(), time.month(), time.daysInMonth(), 23, 59, 59, 99);

          if (object.get('subscriptionStartDate') > currentDate_start) {//A
            caretimeneeded = (currentDate_end.getTime() - object.get('subscriptionStartDate').getTime()) * object.get('subscriptionMinutes') / (object.get('subscriptionEndDate').getTime() - object.get('subscriptionStartDate').getTime());
            elder_data_temp.start_time = object.get('subscriptionStartDate').getTime();
            elder_data_temp.end_time = currentDate_end.getTime();
          } else if (currentDate_end > object.get('subscriptionEndDate')) {//C
            caretimeneeded = (object.get('subscriptionEndDate').getTime() - currentDate_start.getTime()) * object.get('subscriptionMinutes') / (object.get('subscriptionEndDate').getTime() - object.get('subscriptionStartDate').getTime());
            elder_data_temp.start_time = currentDate_start.getTime();
            elder_data_temp.end_time = object.get('subscriptionEndDate').getTime();
          } else {//B
            caretimeneeded = object.get('subscriptionMinutes') * total_miute_this_month / ((object.get('subscriptionEndDate').getTime() - object.get('subscriptionStartDate').getTime()) / 60000);
            elder_data_temp.start_time = currentDate_start.getTime();
            elder_data_temp.end_time = currentDate_end.getTime();
          }

          var eldername = object.get('user').get('username'); // Nickname > userNickname > username
          if (object.get('user').get('ElderInfo')) {
            if (object.get('user').get('ElderInfo').get('NickName'))
              eldername = object.get('user').get('ElderInfo').get('NickName') + "/" + object.get('user').get('username');
            else if (object.get('user').get('userNickname')) {
              eldername = object.get('user').get('userNickname') + "/" + object.get('user').get('username');
            }
          } else if (object.get('user').get('userNickname')) {
            eldername = object.get('user').get('userNickname') + "/" + object.get('user').get('username');
          }

          elder_data_temp.nickname = eldername;
          elder_data_temp.name = object.get('user').get('username');

          elder_data_temp.caretimeneeded = Math.round(caretimeneeded * 10) / 10;;

          if (object.get('contract_type') != null)
            elder_data_temp.contract = object.get('contract_type');

          this.Elder_data.push(elder_data_temp);
          if (elder_data_temp.contract == 0)
            this.Manage_data.total_conunt_by_Noprotocal++;
          else if (elder_data_temp.contract == 1)
            this.Manage_data.total_conunt_by_protocalA++;
          else if (elder_data_temp.contract == 2)
            this.Manage_data.total_conunt_by_protocalB++;


        }//for

        var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
        var carerecordinfo = new CareRecordInfo();
        var query = new Parse.Query(CareRecordInfo);
        query.include('careGiver');
        query.include('elder');
        var currentDate_start = new Date(this.selectedDay.year, this.selectedDay.month, 1, 0, 0, 0, 0);
        let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day);//.add(1, 'day');;
        var currentDate_end = new Date(time.year(), time.month(), time.daysInMonth(), 23, 59, 59, 99);

        query.greaterThan("createdAt", currentDate_start);
        query.lessThan("createdAt", currentDate_end);
        query.ascending("elder");
        query.limit(1000);

        query.find({
          success: (results1) => {
            this.isLoading = true;

            for (var j = 0; j < results1.length; j++) {
              var object = results1[j];

              var temp_elder_data_protocalA = this.Elder_data.filter(function (a) { return a.name == object.get('elder').get('username') && a.contract == 1 });
              var temp_elder_data_protocalB = this.Elder_data.filter(function (a) { return a.name == object.get('elder').get('username') && a.contract == 2 });
              var temp_elder_data_Noprotocal = this.Elder_data.filter(function (a) { return a.name == object.get('elder').get('username') && a.contract == 0 });

              var starttime;
              var calldonetime;
              var total_call_time = 0;

              if (object.get('calldonetime') && object.get('starttime') && object.get('isDone')) {
                starttime = new Date(object.get('starttime'));
                calldonetime = new Date(object.get('calldonetime'));
                total_call_time = Math.round((calldonetime.getTime() - starttime.getTime()) / 1000);
              }

              if (temp_elder_data_protocalA) { //顧幫手
                for (var i = 0; i < temp_elder_data_protocalA.length; i++) {

                  if (object.get('calldonetime')) {
                    if (object.get('calldonetime').getTime() > temp_elder_data_protocalA[i].start_time &&
                      object.get('calldonetime').getTime() < temp_elder_data_protocalA[i].end_time
                    ) {

                      temp_elder_data_protocalA[i].call_time_minute = temp_elder_data_protocalA[i].call_time_minute + total_call_time;
                      temp_elder_data_protocalA[i].total_call_count++;

                      if (temp_elder_data_protocalA[i].call_time_minute) { // have 1 call at least
                        if (temp_elder_data_protocalA[i].iscomplete == "否")
                            this.Manage_data.success_count_by_protocalA++;
                        temp_elder_data_protocalA[i].iscomplete = "是";
                        temp_elder_data_protocalA[i].success_call_count++;
                      }

                      this.Elder_data = this.Elder_data.map(function (a) { return (a.name == temp_elder_data_protocalA[i].name && a.contract == 1 && a.end_time == temp_elder_data_protocalA[i].end_time) ? temp_elder_data_protocalA[i] : a; });
                    }
                  }
                }
              }
              if (temp_elder_data_protocalB) { //秘書服務
                for (var i = 0; i < temp_elder_data_protocalB.length; i++) {

                  if (object.get('calldonetime')) {
                    if (object.get('calldonetime').getTime() > temp_elder_data_protocalB[i].start_time &&
                      object.get('calldonetime').getTime() < temp_elder_data_protocalB[i].end_time
                    ) {

                      temp_elder_data_protocalB[i].call_time_minute = temp_elder_data_protocalB[i].call_time_minute + total_call_time;
                      temp_elder_data_protocalB[i].total_call_count++;

                      if (temp_elder_data_protocalB[i].call_time_minute / 60 > temp_elder_data_protocalB[i].caretimeneeded) {
                        if (temp_elder_data_protocalB[i].iscomplete == "否")
                          this.Manage_data.success_count_by_protocalB++;
                        temp_elder_data_protocalB[i].iscomplete = "是";
                        temp_elder_data_protocalB[i].success_call_count++;
                      }

                      if (object.get('isDone'))
                        temp_elder_data_protocalB[i].success_call_count++;
                      this.Elder_data = this.Elder_data.map(function (a) { return (a.name == temp_elder_data_protocalB[i].name && a.contract == 1 && a.end_time == temp_elder_data_protocalB[i].end_time) ? temp_elder_data_protocalB[i] : a; });
                    }
                  }
                }
              }

              if (temp_elder_data_Noprotocal) { //其他服務
                for (var i = 0; i < temp_elder_data_Noprotocal.length; i++) {

                  if (object.get('calldonetime')) {
                    if (object.get('calldonetime').getTime() > temp_elder_data_Noprotocal[i].start_time &&
                      object.get('calldonetime').getTime() < temp_elder_data_Noprotocal[i].end_time
                    ) {

                      temp_elder_data_Noprotocal[i].call_time_minute = temp_elder_data_Noprotocal[i].call_time_minute + total_call_time;
                      temp_elder_data_Noprotocal[i].total_call_count++;

                      if (temp_elder_data_Noprotocal[i].call_time_minute / 60 > temp_elder_data_Noprotocal[i].caretimeneeded) {
                        if (temp_elder_data_Noprotocal[i].iscomplete == "否")
                            this.Manage_data.success_count_by_Noprotocal++;
                        temp_elder_data_Noprotocal[i].iscomplete = "是";
                        temp_elder_data_Noprotocal[i].success_call_count++;
                      }

                      if (object.get('isDone'))
                        temp_elder_data_Noprotocal[i].success_call_count++;
                      this.Elder_data = this.Elder_data.map(function (a) { return (a.name == temp_elder_data_Noprotocal[i].name && a.contract == 1 && a.end_time == temp_elder_data_Noprotocal[i].end_time) ? temp_elder_data_Noprotocal[i] : a; });
                    }
                  }
                }
              }

            } // for

            this.Elder_data.map(function (a) { //call time四捨五入
              a.call_time_minute = Math.round(a.call_time_minute / 60 * 10) / 10;
              return a;
            });


            this.isLoading = false;
            this.NoprotocalChartData = [this.Manage_data.total_conunt_by_Noprotocal - this.Manage_data.success_count_by_Noprotocal, this.Manage_data.success_count_by_Noprotocal];
            this.protocalAChartData = [this.Manage_data.total_conunt_by_protocalA - this.Manage_data.success_count_by_protocalA, this.Manage_data.success_count_by_protocalA];
            this.protocalBChartData = [this.Manage_data.total_conunt_by_protocalB - this.Manage_data.success_count_by_protocalB, this.Manage_data.success_count_by_protocalB];
            this.Manage_data.success_rate_by_Noprotocal = Math.round(this.Manage_data.success_count_by_Noprotocal / this.Manage_data.total_conunt_by_Noprotocal * 100);
            this.Manage_data.success_rate_by_protocalA = Math.round(this.Manage_data.success_count_by_protocalA / this.Manage_data.total_conunt_by_protocalA * 100);
            this.Manage_data.success_rate_by_protocalB = Math.round(this.Manage_data.success_count_by_protocalB / this.Manage_data.total_conunt_by_protocalB * 100);

          },
          error: function (error) {
            this.isLoading = false;
            alert("Error: " + error.code + " " + error.message);
          }
        });
      },
      error: function (error) {
        this.isLoading = false;
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }



  padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
  }

  public previous(): void {
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).add(-1, 'months');;
    this.selectedDay = { year: time.year(), month: time.month(), day: time.date() };
    this.formattedDay = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).format("YYYY/MM");
    this.QueryCareRecordInfobySecretary();
    this.QueryCareRecordInfobyelder();

  }

  public next(): void {
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).add(1, 'months');
    this.selectedDay = { year: time.year(), month: time.month(), day: time.date() };
    this.formattedDay = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).format("YYYY/MM");
    this.QueryCareRecordInfobySecretary();
    this.QueryCareRecordInfobyelder();

  }

  public selected(value: any): void {
    this.sel = value;
  }
}
