import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';
import { ElderData } from '../select-elder/elder_data';
import { caredetails } from './caredetails';
declare var Parse: any;

@Component({
  selector: 'CareDetailsModify',
  templateUrl: 'caredetailsmodify.component.html',
  styleUrls: ['caredetailsmodify.component.css']
})

export class CareDetailsModifyComponent implements OnInit {
  focusParseUserData: ElderData;
  localnextCareTime: string = "";
  Caredetails: caredetails[] = [];

  constructor(
    private router: Router, private _globalservice: GlobalService
  ) {
    this.focusParseUserData = _globalservice.focusParseUserObject;
    if (this.focusParseUserData.nextCareTime) {
      this.localnextCareTime = this.focusParseUserData.nextCareTime.getFullYear() + "-" +
        this.padStr(1 + this.focusParseUserData.nextCareTime.getMonth()) + "-" +
        this.padStr(this.focusParseUserData.nextCareTime.getDate());
    }
   /* window.onbeforeunload = function () {
      return 'window close';
    };*/
  }


  ngOnInit(): void {

  }


  save() {
    console.log("in save function , and go to caredetail page");
    this.UpdateElderDetailInfo();
  }

  discard() {
    console.log("in discard function , and go to caredetail page");
    this.router.navigateByUrl('/caredetails');
  }


  UpdateElderDetailInfo() {
    console.log("in UpdateElderDetailInfo ID: " + this.focusParseUserData.elderdetailobjectid);
    console.log("in UpdateElderDetailInfo user_object_id: " + this.focusParseUserData.user_object_id);
    var query = new Parse.Query(Parse.User);
    query.equalTo("username", this.focusParseUserData.mabowid);
    query.find({
      success: (user) => {
        console.log(user[0]);
        if (user[0] != null) {
          this.UpdateElderDetailInfo_2(user[0]);
        } else {
          console.error("no elderly user find");
        }
      },
      error: function (user, error) {
        console.error('Failed to find user: ' + this.focusParseUserData.user_object_id + ' , with error code: ' + error.message);
      }
    });
  }
  UpdateElderDetailInfo_2(user) {

    var ElderDetailInfo = Parse.Object.extend("ElderDetailInfo");
    var elderdetailinfo = new ElderDetailInfo();
    if (this.focusParseUserData.elderdetailobjectid) {
      elderdetailinfo.id = this.focusParseUserData.elderdetailobjectid;
    } else {
      // create new ElderDetailInfo
      console.log("create new ElderDetailInfo");
      elderdetailinfo.set("User", user);
    }

    elderdetailinfo.set("SerialNumber", this.focusParseUserData.serialnumber);
    elderdetailinfo.set("CareInterface", this.focusParseUserData.careinterface);
    elderdetailinfo.set("LocalPhoneNumber", this.focusParseUserData.localphonenumber);
    elderdetailinfo.set("Name", this.focusParseUserData.name);
    elderdetailinfo.set("NickName", this.focusParseUserData.nickname);
    elderdetailinfo.set("CareAccount", this.focusParseUserData.careaccount);
    elderdetailinfo.set("CellPhoneNumber", this.focusParseUserData.cellphonenumber);
    elderdetailinfo.set("Age", this.focusParseUserData.age);
    elderdetailinfo.set("HelthStatus", this.focusParseUserData.helthstatus);
    elderdetailinfo.set("CareOwner", this.focusParseUserData.careowner);
    elderdetailinfo.set("FamilyStatus", this.focusParseUserData.familystatus);
    elderdetailinfo.set("Occupation", this.focusParseUserData.occupation);
    elderdetailinfo.set("LivingLocation", this.focusParseUserData.livinglocation);
    elderdetailinfo.set("DailyActivity", this.focusParseUserData.dailyactivity);
    if (this.focusParseUserData.nextCareTime) {
      elderdetailinfo.set("NextCareTime", this.focusParseUserData.nextCareTime);
    }
    elderdetailinfo.set("Others", this.focusParseUserData.others);
    elderdetailinfo.save(null, {
      success: (elderdetailinfo) => {
        console.log("Saved successfully id: " + elderdetailinfo.id);
        this.focusParseUserData.elderdetailobjectid = elderdetailinfo.id;
        this.router.navigateByUrl('/caredetails');
      },
      error: function (elderdetailinfo, error) {
        console.log("save failed " + error.message);
        this.router.navigateByUrl('/caredetails');
      }
    });
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }
  padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
  }
}
