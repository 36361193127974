import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';
declare var Parse: any;
declare var ParseObject: any;

@Component({
  selector: 'manegeCareginerModule',
  templateUrl: 'manegeCareginerModule.component.html',
  styleUrls: ['manegeCareginerModule.component.css']
})

export class manegeCareginerModuleComponent implements OnInit {

  /**
   * function enable flag
   */
  operationEnable = {
    familyAdmin: {
      isAdd: false,
    },
    secretary: {
      isAdd: false,
    }
  }

  // secretary
  secretaryInfo = {
    secretaryObjectArray: [],
    secretaryCallinObjectArray: [],
    secretaryJustAdded: "",  // username
    secretaryCallinJustAdded: "", // username
    secretaryJustAddedTMPObject: "",
    callIn_roleObj: {}, // name: calling_role
    //callOut_roleObj: {},
  }

  targetElderUserName = ""
  targetElderUserObj: any
  isLoading = false;

  constructor(
    private router: Router, private _globalservice: GlobalService
  ) {
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }


  ngOnInit(): void {

    this.queryRoleObjectAndGetRelatedUser("calling_agent_group", true);

  }

  /**
   * queryRoleObjectAndGetRelatedUser
   * get callin user
   * @param roleName
   * @param isCallin
   * 
   */
  queryRoleObjectAndGetRelatedUser(roleName: String, isCallin: boolean) {
    console.log("in queryRoleObjectAndGetRelatedUser name=" + roleName + " isCallin= " + isCallin);
    var query = new Parse.Query(Parse.Role);
    query.equalTo("name", roleName);
    query.first()
      .then((roleObj) => {
        if (!roleObj) {
          throw new Error("roleName " + roleName + " not existed");
        }

        if (isCallin) {
          this.secretaryInfo.callIn_roleObj = roleObj;
        } else {
          // this.secretaryInfo.callOut_roleObj = roleObj;
        }

        var userrelation = roleObj.relation('users')
        userrelation.query().include('userProfileImageSmall');
        return userrelation.query().find()
      })
      .then((users) => {
        for (var j = 0; j < users.length; j++) {
          console.info("queryRoleObjectAndGetRelatedUser/ query relation success");
          if (isCallin) {
            if (this.secretaryInfo.secretaryCallinObjectArray.indexOf(users[j]) == -1)
              this.secretaryInfo.secretaryCallinObjectArray.push(users[j])
          } else {
            if (this.secretaryInfo.secretaryObjectArray.indexOf(users[j]) == -1)
              this.secretaryInfo.secretaryObjectArray.push(users[j])
          }
        }

      })
      .catch((e) => {
        console.error("in queryRoleObjectAndGetRelatedUser e=" + e);

      })
  }

  /**
   * queryRoleObjectAndGetRelatedUser
   * @param roleName
   * @param isCallin
   * @return Parse.promise
   */
  querySelectedUsereEngUser(selectedUserName: String) {
    console.log("in querySelectedUsereEngUser elder name=" + selectedUserName);
    this.secretaryInfo.secretaryObjectArray = [];

    var query = new Parse.Query(Parse.Role);
    query.equalTo("name", selectedUserName + "_eng");
    query.first()
      .then((roleObj) => {
        if (!roleObj) {
          return Parse.Promise.error();
        }

        var userrelation = roleObj.relation('users')
        userrelation.query().include('userProfileImageSmall');
        return userrelation.query().find()
      })
      .then((users) => {
        for (var j = 0; j < users.length; j++) {
          console.info("queryRoleObjectAndGetRelatedUser/ query relation success");
          if (this.secretaryInfo.secretaryObjectArray.indexOf(users[j]) == -1)
            this.secretaryInfo.secretaryObjectArray.push(users[j])
        }

      })
      .catch((e) => {
        console.error("in queryRoleObjectAndGetRelatedUser e=" + e);
      })
  }

  /***
  * username input onchange callback 
  */
  findElderUseronChange(data) {
    console.log("in findElderUseronChange() ");
    this.isLoading = true;
    this.queryUserObjectAndCreateIfConfirmNeeded(this.targetElderUserName).then(
      (user) => {
        console.log("in findElderUseronChange/ With user callback");
        this.targetElderUserObj = user;
        this.isLoading = false;
        this.querySelectedUsereEngUser(this.targetElderUserName);

        //this.saveDeviceInfo(user);
      }).catch((error) => {
        console.error("in useronChange()/exception");

        this.errorCallback(-2)
      })



  }


  /**
   * removeSecretary
   * @param type 0=> callout 1=> callin
   * @param removeUserObject
   */
  removeSecretary(type, removeUserObject) {
    console.log("in removeSecretary() type= " + type);
    this.isLoading = true;;

    // check is already added
    if (removeUserObject.id === Parse.User.current().id) {
      console.warn("in removeSecretary / cannot remove self");
      this.errorCallback(-5);
      return;
    }

    if (type == 0) {
      // 2. request add into user's friendlist
      var input = { relation: "_eng", toUserId: this.targetElderUserObj.id, fromUserId: removeUserObject.id, toUserUsername: this.targetElderUserObj.getUsername() }
      Parse.Cloud.run('removeRelationShipWithUser', input).then(() => {
        console.log('in removeFamilyAdmin success');
        this.isLoading = false;
        this.secretaryInfo.secretaryObjectArray.splice(this.secretaryInfo.secretaryObjectArray.indexOf(removeUserObject), 1);
      }).catch((e) => {
        this.errorCallback(-2);
        console.error('in removeFamilyAdmin error');

      });

    } else {
      this.removeRoleUserInBackground(this.secretaryInfo.callIn_roleObj, removeUserObject).then(() => {
        this.secretaryInfo.secretaryCallinObjectArray.splice(this.secretaryInfo.secretaryCallinObjectArray.indexOf(removeUserObject), 1);
        this.isLoading = false;;
      });

    }



  }


  /**
   * addScretaryToCallinGroup
   */
  addScretaryToCallinGroup() {
    console.log("in addScretaryToCallinGroup ");
    var justAddedS = this.secretaryInfo.secretaryCallinJustAdded || "";
    if (justAddedS == "" /*|| this.familyInfo.elderFamilyAdminRoleObject == null*/) {
      this.errorCallback(-4);
      return;
    }
    console.log("in addScretaryToCallinGroup justAddedS= " + justAddedS);

    this.isLoading = true;
    // 1. query input user
    this.queryUserObjectAndCreateIfConfirmNeeded(justAddedS).then(
      (secretaryJustAddedObject: any) => {
        console.log("in queryInputUserObj success ");
        // this.g_secretary = secretaryJustAddedObject;
        // 1.1. check is already added
        for (var i = 0; i < this.secretaryInfo.secretaryCallinObjectArray.length; i++) {
          if (this.secretaryInfo.secretaryCallinObjectArray[i].id === secretaryJustAddedObject.id) {
            console.warn("in queryInputUserObj / input user is already added ");
            throw new Error('-6');
          }
        }
        this.secretaryInfo.secretaryJustAddedTMPObject = secretaryJustAddedObject;
        return this.saveRoleInBackground(this.secretaryInfo.callIn_roleObj, secretaryJustAddedObject);

      })
      .then((_callIn_roleObj) => {
        console.log("addUserToEng success");
        this.secretaryInfo.callIn_roleObj = _callIn_roleObj;
        if (this.secretaryInfo.secretaryCallinObjectArray.indexOf(this.secretaryInfo.secretaryJustAddedTMPObject) == -1)
          this.secretaryInfo.secretaryCallinObjectArray.push(this.secretaryInfo.secretaryJustAddedTMPObject);
        this.isLoading = false;;
      }).catch((error) => {
        console.error("addUserToEng error " + error);
        this.errorCallback(-2);

      });

  }


  /**
   * add eng to user
   */
  addScretaryToEng() {
    console.log("in addScretaryToEng added ");
    var justAddedS = this.secretaryInfo.secretaryJustAdded || "";
    if (justAddedS == "" /*|| this.familyInfo.elderFamilyAdminRoleObject == null*/) {
      this.errorCallback(-4);
      return;
    }
    this.isLoading = true;

    this.queryUserObjectAndCreateIfConfirmNeeded(justAddedS).then(
      (secretaryJustAddedObject: any) => {
        console.log("in queryInputUserObj success ");
        this.secretaryInfo.secretaryJustAddedTMPObject = secretaryJustAddedObject;
        // 1.1. check is already added
        for (var i = 0; i < this.secretaryInfo.secretaryObjectArray.length; i++) {
          if (this.secretaryInfo.secretaryObjectArray[i].id === secretaryJustAddedObject.id) {
            console.warn("in queryInputUserObj / input user is already added ");
            throw new Error('-6');
          }
        }

        // 2. request add into user's friendlist
        // 老人 加 輸入的人
        var input = { toUserId: this.targetElderUserObj.id, fromUserId: secretaryJustAddedObject.id, fromUserUsername: secretaryJustAddedObject.getUsername(), toUserUsername: this.targetElderUserObj.getUsername() }
        return Parse.Cloud.run('setUserEngWithUser', input);
      }).then((res) => {
        console.log("addUserToEng success");
        this.isLoading = false;;
        if (this.secretaryInfo.secretaryObjectArray.indexOf(this.secretaryInfo.secretaryJustAddedTMPObject) == -1)
          this.secretaryInfo.secretaryObjectArray.push(this.secretaryInfo.secretaryJustAddedTMPObject);

      }).catch((error) => {
        console.error("addUserToEng error " + error);
        this.errorCallback(-2);

      });;

  }

  /***
  * get the user object by username. create if needed
  * @param username
  */
  queryUserObjectAndCreateIfConfirmNeeded(username: string) {
    console.log("in queryUserObjectAndCreateIfConfirmNeeded username= " + username);
    return new Promise((resolve, reject) => {
      if (username == "" || username == null) {
        reject();
      }
      this.queryUser(username).then((user) => {
        if (user) {
          resolve(user);
        } else {

          var confirmPhone: string = window.prompt(username + " 不存在將會幫您新增帳號，請在輸入一次您要新增的帳號");

          console.log(confirmPhone)
          if (username === confirmPhone) {
            return this.createInputUser(username, true)
          } else {
            console.log("in save function/ no user callback/ user deny create user");
            throw new Error("user deny create user");
          }
        }
      })
        .then((userobj) => {
          console.log("in save function /createInputUser success");
          resolve(userobj);
        })
        .catch((error) => {
          console.log("in save function  no user callback error= " + error);
          reject(error);
        });
    });

  }

  /**
   * saveRoleInBackground
   * @param roleObj
   * @param insertUserobj
   */
  saveRoleInBackground(roleObj, insertUserobj) {
    console.log("in saveRoleInBackground()");
    //return new Promise((resolve, reject) => {
    console.dir(roleObj)
    roleObj.getUsers().add(insertUserobj);
    return roleObj.save()
    //});
  }

  /**
   * removeRoleUserInBackground
   * @param roleObj
   * @param insertUserobj
   */
  removeRoleUserInBackground(roleObj, insertUserobj) {
    console.log("in removeRoleUserInBackground()");
    //return new Promise((resolve, reject) => {
    console.dir(roleObj)
    roleObj.getUsers().remove(insertUserobj);
    return roleObj.save()
    //});
  }

  /**
  *  create user by input data 
  *  @param username
  *  @param isNeedUserConfirm
  */
  createInputUser(username: string, isNeedUserConfirm: boolean) {
    console.log("in createInputUser function isNeedUserConfirm= " + isNeedUserConfirm);

    return new Promise((resolve, reject) => {
      Parse.Cloud.run('SignUpUser', { username: username, isNeedUserConfirm: isNeedUserConfirm/* secretary: this.secretaryInfo.secretary */ }).then(
        (userObf) => {
          console.log("createuser user: " + userObf.getUsername());
          resolve(userObf);
        }, (error) => {
          console.error("SignUpUser error");
          reject();

        });    //this.router.navigateByUrl('/caredetails');
    });
  }

  /***
   * query useronject by usernamre
   * @param username
  */
  queryUser(username) {
    console.log("in queryUser username= " + username)
    var User = Parse.Object.extend("_User");
    var query = new Parse.Query(User);
    query.equalTo("username", username);
    query.include('userProfileImageSmall');
    return query.first();
  }
  /***
   * errorCallback
   * @param type
   */
  errorCallback(type: number) {
    console.error("in errorCallback type= " + type);
    switch (type) {
      case -1:
        alert("請重新整理網頁");
        break;
      case -2:
        alert("失敗！！，請再試一次");
        this.isLoading = false;;

        break;
      case -3:
        alert("失敗！！，找不到這台機器的資訊！");
        this.isLoading = false;;

        break;
      case -4:
        alert("請輸入正確資訊");
        this.isLoading = false;;
      case -5:
        alert("無法移除自己的權限");
        this.isLoading = false;;


        break;
      case -6:
        alert("已經新增過了喔");
        this.isLoading = false;;
        break;

    }

  }

  /***
   * successCallback
   * @param type
   */
  successCallback(type: number) {
    console.error("in successCallback type= " + type);
    switch (type) {
      case 1:
        this.isLoading = false;;

        alert("成功");
        break;

    }

  }


}