import { Component, OnInit } from '@angular/core';
import { eldergroup } from './eldergroup';
import { user } from './user';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { GlobalService } from '../global.service';
import { IfObservable } from 'rxjs/observable/IfObservable';

declare var Parse: any;
declare var $: any;

@Component({
  selector: 'app-eldermanagement',
  templateUrl: './eldermanagement.component.html',
  styleUrls: ['./eldermanagement.component.css']
})
export class EldermanagementComponent extends ParentClass implements OnInit {
  eldergroups: eldergroup[] = [];
  secretarylists: user[] = [];
  elderlists: user[] = [];
  nogroupelder: user[] = [];
  nogroupsecretary: user[] = [];
  isLoading: boolean = true;
  newelder: string = "";
  newsecretary: string = "";
  mutiSelectedGroupInput: number = 0;
  userlistincaregroupmanagement: string[] = [];
  globalService: GlobalService;
  modifyGroupOrder: number;
  modifyGroupName: string = "";
  constructor(router: Router, _globalservice: GlobalService) {
    super(router);
    this.globalService = _globalservice;

  }

  ngOnInit() {
    this.QueryCareGroupManagement();
  }

  _CareGroupArrayCache = [];
  private QueryCareGroupManagementBase(skipI) {
    console.log('QueryCareGroupManagementBase skipI= ' + skipI)
    if (skipI == 0)
      this._CareGroupArrayCache = []
    let CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    let query = new Parse.Query(CareGroupManagement);
    query.include('user');
    query.include('elderdetailinfo');
    query.include('careGroup');
    query.ascending("group");
    //query.addAscending("lastcare");
    query.addDescending("type");
    query.addAscending("lastcare");
    query.limit(100);
    query.skip(skipI * 100);
    return query.find().then((results) => {
      this._CareGroupArrayCache.push(...results)
      if (results.length == 100)
        return this.QueryCareGroupManagementBase(skipI + 1)
      else {
        console.dir(this._CareGroupArrayCache.length)
        return Promise.resolve(this._CareGroupArrayCache)
      }
    })
  }

  QueryCareGroupManagement() {
    console.log("in QueryCareGroupManagement ")
    this.isLoading = true;
    var group_count = 1;
    var linksecretary = "";
    var tempGroupName = "";
    var secretary_no = 0;
    var userNickname;
    var current_contract;
    var careexpiredate;

    this.QueryCareGroupManagementBase(0).then((results) => {
      console.log("Successfully retrieved " + results.length + " users.");
      this.eldergroups = [];
      this.secretarylists = [];
      this.elderlists = [];
      this.nogroupelder = [];
      this.nogroupsecretary = [];
      this.userlistincaregroupmanagement = [];
      for (var i = 0; i < results.length; i++) {
        var object = results[i];
        this.userlistincaregroupmanagement.push(object.get('user').get("username"));
        if (object.get('group') > 0) {// has group
          if (object.get('group') != group_count) { //reset push and clean temp array
            tempGroupName = "";
            if (object.get('careGroup')) {
              tempGroupName = object.get('careGroup').get('groupName')
            }
            this.eldergroups.push({
              elderlists: this.elderlists,
              secretarylists: this.secretarylists,
              order: group_count,
              name: tempGroupName,
            });
            this.secretarylists = [];
            this.elderlists = [];
            secretary_no = 0;
          }

          if (object.get('type') == 1) {// elder
            var secretary_no_ = 0;
            if (object.get('linksecretary') && object.get("newcomer") == true) {
              linksecretary = object.get('linksecretary').id;
              if (this.secretarylists) {
                //console.dir(this.secretarylists)
                var arr = this.secretarylists.filter(function (value) {
                  return value.id == linksecretary;
                });
                //console.warn(object.get('user').id+" -> "+object.get('linksecretary').id+" "+arr.length);

                if (arr.length > 0) {
                  secretary_no_ = arr[0].secretary_no;
                  // console.warn(object.get('user').id+" -> "+object.get('linksecretary').id+"secretary_no_= "+secretary_no_);
                }
              }

            } else
              linksecretary = "";
            var imageUrl = "";
            if (object.get('user').get("userProfileImageSmall") != null) {
              imageUrl = object.get('user').get("userProfileImageSmall").url();
            }

            userNickname = "";
            if (object.get("elderdetailinfo")) {
              if (object.get("elderdetailinfo").get("NickName"))
                userNickname = object.get('elderdetailinfo').get("NickName");
            }

            this.elderlists.push({
              name: userNickname,
              account: object.get('user').get("username"),
              ImageURL: imageUrl,
              id: object.id,
              group: object.get('group'),
              linksecretary: linksecretary,
              secretary_no: secretary_no_,
              lastcare: "",
              userobject: object.get('user'),
              status: {
                deviceStatus: 0,
                screenStatus: 0
              },
              current_contract: current_contract,
              careexpiredate: careexpiredate,
              selected: false,
              elderDetailInfoObject: object.get("elderdetailinfo")
            });

          } else if (object.get('type') == 2) {// secretary
            secretary_no++;

            var imageUrl = "";
            if (object.get('user').get("userProfileImageSmall") != null) {
              imageUrl = object.get('user').get("userProfileImageSmall").url();
            }

            userNickname = "";
            if (object.get("user").get("userNickname")) {
              userNickname = object.get("user").get("userNickname");
            }

            this.secretarylists.push({
              name: userNickname,
              account: object.get('user').get("username"),
              ImageURL: imageUrl,
              id: object.id,
              group: object.get('group'),
              linksecretary: "",
              secretary_no: secretary_no,
              lastcare: "",
              userobject: object.get('user'),
              status: {
                deviceStatus: 0,
                screenStatus: 0
              },
              current_contract: current_contract,
              careexpiredate: careexpiredate,
              selected: false,
              elderDetailInfoObject: object.get("elderdetailinfo")
            });
          }
          group_count = object.get('group');
          if (object.get('careGroup')) {
            tempGroupName = object.get('careGroup').get('groupName')
          }
        } else {//no group
          if (object.get('type') == 1) {// elder
            if (object.get('linksecretary'))
              linksecretary = object.get('linksecretary').id;
            else
              linksecretary = "";
            var imageUrl = "";
            if (object.get('user').get("userProfileImageSmall") != null) {
              imageUrl = object.get('user').get("userProfileImageSmall").url();
            }

            userNickname = "";
            if (object.get("elderdetailinfo")) {
              if (object.get("elderdetailinfo").get("NickName"))
                userNickname = object.get('elderdetailinfo').get("NickName");
            }

            this.nogroupelder.push({
              name: userNickname,
              account: object.get('user').get("username"),
              ImageURL: imageUrl,
              id: object.id,// caregroup id
              group: object.get('group'),
              linksecretary: linksecretary,
              secretary_no: 0,
              lastcare: "",
              userobject: object.get('user'),
              status: {
                deviceStatus: 0,
                screenStatus: 0
              },
              current_contract: current_contract,
              careexpiredate: careexpiredate,
              selected: false,
              elderDetailInfoObject: object.get("elderdetailinfo")
            });
          } else if (object.get('type') == 2) {// secretary
            var imageUrl = "";
            if (object.get('user').get("userProfileImageSmall") != null) {
              imageUrl = object.get('user').get("userProfileImageSmall").url();
            }

            userNickname = "";
            if (object.get("user").get("userNickname")) {
              userNickname = object.get("user").get("userNickname");
            }

            this.nogroupsecretary.push({
              name: userNickname,
              account: object.get('user').get("username"),
              ImageURL: imageUrl,
              id: object.id,
              group: object.get('group'),
              linksecretary: object.get('linksecretary'),
              secretary_no: 0,
              lastcare: "",
              userobject: object.get('user'),
              status: {
                deviceStatus: 0,
                screenStatus: 0
              },
              current_contract: current_contract,
              careexpiredate: careexpiredate,
              selected: false,
              elderDetailInfoObject: object.get("elderdetailinfo")
            });
          }
        }

      }
      this.eldergroups.push({
        elderlists: this.elderlists,
        secretarylists: this.secretarylists,
        order: group_count,
        name: tempGroupName,
      });
      this.isLoading = false;
    }).catch((error) => {
      console.error(error)
      this.isLoading = false;
      alert("Error: " + error.code + " " + error.message);
    })

  }


  remove(user: user) {
    this.isLoading = true;
    console.log("remove user: " + user.name);
    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    var caregroupmanagement = new CareGroupManagement();
    if (user.id) {
      caregroupmanagement.id = user.id;
      caregroupmanagement.set("group", 0);
      caregroupmanagement.set("linksecretary", null);
      caregroupmanagement.set("careGroup", null);
    }
    caregroupmanagement.save(null, {
      success: (caregroupmanagement) => {
        console.log("Saved successfully id: " + caregroupmanagement.id);
        this.QueryCareGroupManagement();

      },
      error: function (caregroupmanagement, error) {
        console.log("save " + caregroupmanagement.id + " failed " + error.message);
        alert("Error: " + error.code + " " + error.message);
        this.isLoading = false;

      }
    });

  }

  movebygroup(user: user, careGroup: any) {
    this.isLoading = true;
    console.log("move user: " + user.name + " to group " + user.group);
    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    var caregroupmanagement = new CareGroupManagement();
    if (user.id) {
      caregroupmanagement.id = user.id;
      caregroupmanagement.set("group", user.group);
      caregroupmanagement.set("careGroup", careGroup);
    }
    caregroupmanagement.save(null, {
      success: (caregroupmanagement) => {
        console.log("Saved successfully id: " + caregroupmanagement.id);
        this.QueryCareGroupManagement();
      },
      error: function (caregroupmanagement, error) {
        this.isLoading = false;
        console.log("save " + caregroupmanagement.id + " failed " + error.message);
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  moveToGroupBySelected() {
    var careGroup = Parse.Object.extend("CareGroup");
    var careGroupInfo = new careGroup();
    var query = new Parse.Query(careGroupInfo);
    query.equalTo("group", this.mutiSelectedGroupInput);
    query.first({
      success: (results) => {
        var object = results;
        if (object.get('group')) {
          for (var user of this.nogroupelder) {
            if (user.selected === true) {
              user.group = this.mutiSelectedGroupInput;
              this.movebygroup(user, object);
            }
          }
        } else {
          var careGroup = Parse.Object.extend("CareGroup");
          var careGroupInfo = new careGroup();
          careGroupInfo.set("group", this.mutiSelectedGroupInput);
          careGroupInfo.set("groupName", "新群組");
          careGroupInfo.save(null, {
            success: (careGroupInfo) => {
              console.log("Saved successfully group: " + careGroupInfo.get('group'));
              var object = careGroupInfo;
              if (object.get('group')) {
                for (var user of this.nogroupelder) {
                  if (user.selected === true) {
                    user.group = this.mutiSelectedGroupInput;
                    this.movebygroup(user, object);
                  }
                }
              }
            },
            error: function (careGroupInfo, error) {
              this.isLoading = false;
              console.log("save " + careGroupInfo.id + " failed " + error.message);
              alert("Error: " + error.code + " " + error.message);
            }
          });
        }
      },
      error: function (error) {
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  /**
   * movebysecretary
   * move the elder to the group which contain selected secretary
   * @param elderUserObj
   */
  movebysecretary(elderCareGroup: user) {

    if (/^\d+$/.test(elderCareGroup.linksecretary) == false) {
      //proceed with rest of code
      alert("僅能輸入數字 請檢查是否有空格或其他字元");
      return;
    }

    this.isLoading = true;
    console.log("movebysecretary user: " + elderCareGroup.name + "/" + elderCareGroup.linksecretary + " from group " + elderCareGroup.group);
    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");

    var innerQuery = new Parse.Query(Parse.User);
    innerQuery.equalTo("username", elderCareGroup.linksecretary);
    var query = new Parse.Query(CareGroupManagement);
    query.notEqualTo("group", 0);
    query.matchesQuery("user", innerQuery);
    /*
        query.equalTo('user', {
          __type: 'Pointer',
          className: '_User',
          objectId: user_[0].id
        });*/
    query.first().then((secreGroupMresult) => {
      if (secreGroupMresult == null) {
        console.log("secretary not exist");
        alert("此秘書號碼尚未分組或是沒有新增此秘書");
        this.isLoading = false;
        return;
      }
      console.log("secretary exist ");


      var eldercaregroupmanagement = new CareGroupManagement();
      console.log("elderCareGroup id  " + elderCareGroup.id + " secretary ID= " + secreGroupMresult.id);

      //if (elderCareGroup.id) {
      eldercaregroupmanagement.id = elderCareGroup.id;

      //var linksecretary_ = new CareGroupManagement();
      //linksecretary_.id = results[0].id;
      eldercaregroupmanagement.set("linksecretary", secreGroupMresult);
      eldercaregroupmanagement.set("group", secreGroupMresult.get("group"));
      //}
      eldercaregroupmanagement.save(null, {
        success: (_eldercaregroupmanagement) => {
          this.isLoading = false;
          console.log("Saved successfully id: " + _eldercaregroupmanagement.id);
          this.QueryCareGroupManagement();
        },
        error: (_eldercaregroupmanagement, error) => {
          this.isLoading = false;
          console.log("save " + _eldercaregroupmanagement.id + " failed " + error.message);
          alert("Error: " + error.code + " " + error.message);
        }
      });

    }).catch((error) => {
      this.isLoading = false;
      console.log("error " + error);
      alert(error)
    })
    /*query.first({
      success: (results) => {
        if (results.length == 0) {
          console.log("secretary not exist");
          alert("此秘書號碼尚未分組或是沒有新增此秘書");
          this.isLoading = false;
          return;
        } else {
          console.log("secretary exist ");
          var caregroupmanagement = new CareGroupManagement();
          console.log("user id  " + elderUserObj.id + " secretary id " + results[0].id);
          if (elderUserObj.id) {
            caregroupmanagement.id = elderUserObj.id;
            var linksecretary_ = new CareGroupManagement();
            linksecretary_.id = results[0].id;
            caregroupmanagement.set("linksecretary", linksecretary_);
            caregroupmanagement.set("group", results[0].get("group"));
          }
          caregroupmanagement.save(null, {
            success: (caregroupmanagement) => {
              this.isLoading = false;
              console.log("Saved successfully id: " + caregroupmanagement.id);
              this.QueryCareGroupManagement();
            },
            error: (caregroupmanagement, error) => {
              this.isLoading = false;
              console.log("save " + caregroupmanagement.id + " failed " + error.message);
              alert("Error: " + error.code + " " + error.message);
            }
          });
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log("error " + error);
      }
    });*/


    /*var userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo("username", user.linksecretary);
    userQuery.find({
      success: (user_) => {
        console.dir(user_);
        if (user_.length == 0) {
          console.log("secretary not exist");
          alert("秘書號碼不存在");
          this.isLoading = false;
          return;
        }
        var query = new Parse.Query(CareGroupManagement);
        query.notEqualTo("group", 0);
        query.equalTo('user', {
          __type: 'Pointer',
          className: '_User',
          objectId: user_[0].id
        });
        query.find({
          success: (results) => {
            if (results.length == 0) {
              console.log("secretary not exist");
              alert("此秘書號碼尚未分組");
              this.isLoading = false;
              return;
            } else {
              console.log("secretary exist ");
              var caregroupmanagement = new CareGroupManagement();
              console.log("user id  " + user.id + " secretary id " + results[0].id);
              if (user.id) {
                caregroupmanagement.id = user.id;
                var linksecretary_ = new CareGroupManagement();
                linksecretary_.id = results[0].id;
                caregroupmanagement.set("linksecretary", linksecretary_);
                caregroupmanagement.set("group", results[0].get("group"));
              }
              caregroupmanagement.save(null, {
                success: (caregroupmanagement) => {
                  this.isLoading = false;
                  console.log("Saved successfully id: " + caregroupmanagement.id);
                  this.QueryCareGroupManagement();
                },
                error: (caregroupmanagement, error) => {
                  this.isLoading = false;
                  console.log("save " + caregroupmanagement.id + " failed " + error.message);
                  alert("Error: " + error.code + " " + error.message);
                }
              });
            }
          },
          error: (error) => {
            this.isLoading = false;
            console.log("error " + error);
          }
        });
      },
      error: () => {
        console.log("guery " + user.linksecretary + " fail");
        this.isLoading = false;
      }
    });*/
  }

  move(user: user) {
    this.isLoading = true;
    console.log("move user: " + user.name + " to group " + user.group);
    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    var caregroupmanagement = new CareGroupManagement();
    if (user.id) {
      caregroupmanagement.id = user.id;
      caregroupmanagement.set("group", user.group);
    }
    caregroupmanagement.save(null, {
      success: (caregroupmanagement) => {
        this.isLoading = false;

        console.log("Saved successfully id: " + caregroupmanagement.id);
        this.QueryCareGroupManagement();
      },
      error: function (caregroupmanagement, error) {
        console.log("save " + caregroupmanagement.id + " failed " + error.message);
        this.isLoading = false;
        alert("Error: " + error.code + " " + error.message);

      }
    });
  }

  addelder() {
    var name = this.newelder;

    if (/^\d+$/.test(name) == false) {
      //proceed with rest of code
      alert("僅能輸入數字 請檢查是否有空格或其他字元");
      return;
    }


    var found = false;
    for (var i = 0; i < this.userlistincaregroupmanagement.length && !found; i++) {
      if (this.userlistincaregroupmanagement[i] === name) {
        found = true;
        console.log("add user: " + name + " is already exist");
        alert("無法重複新增");
        return;
      }
    }
    this.isLoading = true;
    console.log("addelder " + name);
    var innerQuery = new Parse.Query(Parse.User);
    innerQuery.equalTo("username", name);
    var ElderDetailInfo = Parse.Object.extend("ElderDetailInfo");
    var query = new Parse.Query(ElderDetailInfo);
    query.matchesQuery("User", innerQuery);
    query.first().then((elderdetailinfo) => {
      if (elderdetailinfo == null) {
        console.log("add user: " + name + " is not activated or elderdetailinfo not exist");
        alert("號碼未註冊 or  elderdetailinfo不存在 無法新增");
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
      var caregroupmanagement = new CareGroupManagement();
      caregroupmanagement.set("user", elderdetailinfo.get("User"));
      caregroupmanagement.set("type", 1);
      caregroupmanagement.set("group", 0);
      caregroupmanagement.set("newcomer", true);
      caregroupmanagement.set("elderdetailinfo", elderdetailinfo);

      caregroupmanagement.save(null, {
        success: (caregroupmanagement) => {
          console.log("add successfully id: " + caregroupmanagement.id);
          this.QueryCareGroupManagement();
        },
        error: function (caregroupmanagement, error) {
          console.log("add " + caregroupmanagement.id + " failed " + error.message);
          alert("Error: " + error.code + " " + error.message);
        }
      });

    });
  }

  addsecretary() {
    var name = this.newsecretary;
    if (/^\d+$/.test(name) == false) {
      //proceed with rest of code
      alert("僅能輸入數字 請檢查是否有空格或其他字元");
      return;
    }
    var found = false;
    for (var i = 0; i < this.userlistincaregroupmanagement.length && !found; i++) {
      if (this.userlistincaregroupmanagement[i] === name) {
        found = true;
        console.log("add user: " + name + " is already exist");
        alert("無法重複新增");
        return;
      }
    }
    this.isLoading = true;
    console.log("addsecretary " + name);
    var query = new Parse.Query(Parse.User);
    query.equalTo("username", name);
    query.find({
      success: (results) => {
        this.isLoading = false;
        if (results.length > 0) {
          var user = results[0];
          var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
          var caregroupmanagement = new CareGroupManagement();
          caregroupmanagement.set("user", user);
          caregroupmanagement.set("type", 2);
          caregroupmanagement.set("group", 0);
          caregroupmanagement.set("newcomer", true);

          caregroupmanagement.save(null, {
            success: (caregroupmanagement) => {
              console.log("add successfully id: " + caregroupmanagement.id);
              this.QueryCareGroupManagement();
            },
            error: function (caregroupmanagement, error) {
              console.log("add " + caregroupmanagement.id + " failed " + error.message);
              alert("Error: " + error.code + " " + error.message);
            }
          });
          var query = new Parse.Query(Parse.Role);
          query.equalTo("name", "callout_agent_group");
          query.first()
            .then((roleObj) => {
              if (!roleObj) {
                console.log(" callout_agent_group not existed");
              }
              roleObj.getUsers().add(user);
              roleObj.save();
            })

        } else {
          console.log("add user: " + name + " is not activated");
          alert("號碼未註冊 無法新增");
        }
      },
      error: function (results, error) {
        this.isLoading = false;
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  remove_from_eldermanagement(user: user) {
    this.isLoading = true;
    console.log("remove user: " + user.name + "/" + user.account + "from groupmanagement");
    var CareGroupManagement = Parse.Object.extend("CareGroupManagement");
    var query = new Parse.Query(CareGroupManagement);
    query.get(user.id, {
      success: (object) => {
        // The object was retrieved successfully.
        //Parse.Promise destroy();
        object.destroy({
          success: (object) => {
            this.isLoading = false;
            console.log("destroy successfully id: " + object.id);
            this.QueryCareGroupManagement();
          },
          error: (object, error) => {
            console.log("destroy " + object.id + " failed " + error.message);
            this.isLoading = false;
            alert("Error: " + error.code + " " + error.message);
          }

        });
      },
      error: (object, error) => {
        console.log("destroy " + object.id + " failed " + error.message);
        this.isLoading = false;
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  modifyGroupNameModal(groupOrder: number, groupName: string) {
    $('.ui.group.modal')
      .modal('show')
      ;
    this.modifyGroupOrder = groupOrder;
    this.modifyGroupName = groupName;
    console.log("modifyGroupOrder:" + groupOrder + " modifyGroupName:" + groupName);
  }

  modifyGroupNameClass() {
    this.isLoading = true;
    console.log("modifyGroupName: group " + this.modifyGroupOrder);
    var careGroup = Parse.Object.extend("CareGroup");
    var query = new Parse.Query(careGroup);
    query.equalTo("group", this.modifyGroupOrder);
    query.first().then((careGroupObject) => {
      this.isLoading = false;
      if (careGroupObject == null) {
        var GroupObject = Parse.Object.extend("CareGroup");
        var groupObject = new GroupObject();
        groupObject.set("group", this.modifyGroupOrder);
        groupObject.set("groupName", this.modifyGroupName);
        groupObject.save(null, {
          success: (result) => {
            console.log("modifyGroupNameClass success group" + this.modifyGroupOrder);
            this.QueryCareGroupManagement();
          },
          error: function (result, error) {
            alert("Error: " + error.code + " " + error.message);
          }
        });
      } else {
        careGroupObject.set("groupName", this.modifyGroupName);
        careGroupObject.save(null, {
          success: (result) => {
            console.log("modifyGroupNameClass success group" + this.modifyGroupOrder);
            this.QueryCareGroupManagement();
          },
          error: function (result, error) {
            alert("Error: " + error.code + " " + error.message);
          }
        });
      }

    });
  }

  goto(no: number) {
    console.log("goto: " + no);
    this.globalService.eldermanagementdetailgroup = no;
    this.router.navigateByUrl('/eldermanagementdetail');

  }

  getStyle(secretary_no: number) {
    switch (secretary_no) {
      case 1:
        return "#fff3aa";
      case 2:
        return "#ffd0a8";
      case 3:
        return "#ffb1b1";
      case 4:
        return "#d9d1ff";
      case 5:
        return "#b7efff";
      default:
        return "";
    }
  }
}
