export class ElderData {

    nickname: string;
    mabowid: string;
    nextCareTime: Date;
    Device: string;
    imageUrl: string = "http://semantic-ui.com/images/avatar2/large/kristy.png";
    note: string;
    isEnable: boolean = true;
    fullUserObject: any;
    careinterface: string;
    livinglocation: string;
    name: string;
    careaccount: string;
    careowner: string;
    serialnumber: number;
    localphonenumber: string;
    cellphonenumber: string;
    age: number;
    helthstatus: string;
    dailyactivity: string;
    familystatus: string;
    occupation: string;
    others: string;
    user_object_id: string;
    elderdetailobjectid: string;
    elderDetailInfoObject: any;
    pmi: string;
}
