import {MonthOfYear} from "./enums";
import * as _ from "lodash";

export class Appointment {
    public id: string = _.uniqueId();

    constructor(public ParseID: string, public date: Date, public description: string) {
    }
}
export interface Day {
    year: number;
    month: MonthOfYear;
    day: number;
}

export interface Month {
    year: number;
    month: MonthOfYear;
}
export class DayWithAppointments {
    constructor(public day: Day, public appointments: Array<Appointment>) {
    }
}

