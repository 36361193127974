import { Component, OnInit } from '@angular/core';

declare var Parse: any;

@Component({
  selector: 'app-check-program-list',
  templateUrl: './check-program-list.component.html',
  styleUrls: ['./check-program-list.component.css']
})
export class CheckProgramListComponent implements OnInit {


  notificationTypeArray = [];
  ProgramListArray = [];

  constructor() { }

  ngOnInit() {

    this.queryLiveInteractionNotificationType();
  }


  queryLiveInteractionNotificationType() {
    console.log("queryLiveInteractionNotificationType")

    var _LiveInteractionNotificationType = Parse.Object.extend("LiveInteractionNotificationType");
    var _LiveInteractionNotificationType = new _LiveInteractionNotificationType();
    var query = new Parse.Query(_LiveInteractionNotificationType);
    query.ascending("roomType");
    query.find().then((results) => {
      if (results.length <= 0) {
        console.log("not foun any programs")
        return;
      }

      results.forEach((room) => {

        let obj = {};
        obj["roomType"] = room.get("roomType");
        obj["roomTypeV2"] = room.get("roomTypeV2");
        obj["image"] = room.get("image");
        obj["voice"] = room.get("voice");
        obj["timeout"] = room.get("timeout");
        this.notificationTypeArray.push(obj);

      })

      console.dir(this.notificationTypeArray)
      this.queryProgramList();

    });

  }


  queryProgramList() {
    console.log("queryProgramList")

    var _ProgramList = Parse.Object.extend("ProgramList");
    var ProgramList = new _ProgramList();
    var query = new Parse.Query(ProgramList);
    query.ascending("programIndex");
    query.find().then((results) => {
      if (results.length <= 0) {
        console.log("not foun any programs")
        return;
      }


      for (var i = 0; i < results.length; i++) {
        let obj = {};

        obj["programIndex"] = results[i].get("programIndex");
        obj["name"] = results[i].get("name");
        obj["type"] = results[i].get("type");
        obj["statusV2"] = results[i].get("statusV2");
        obj["expectStartWeekDay"] = results[i].get("expectStartWeekDay");
        if (obj["type"] == 4) {
          obj["expectStartDayTime"] = results[i].get("schedule_start")
          obj["expectEndDayTime"] = results[i].get("schedule_last_start")
        } else {
          obj["expectStartDayTime"] = results[i].get("expectStartTime");
          obj["expectEndDayTime"] = results[i].get("expectEndTime");
        }

        this.notificationTypeArray.forEach((typeObj) => {
          if (obj["statusV2"] == typeObj.roomTypeV2) {
            obj["image"] = typeObj.image;
            obj["voice"] = typeObj.voice;
            obj["timeoutMin"] = Number(typeObj.timeout) / 60;
          }
        })


        this.ProgramListArray.push(obj);

      }

      console.dir(this.ProgramListArray)

    });




  }








}
