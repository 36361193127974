import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'LiveErrorMonitorPipe'
})
export class LiveErrorMonitorPipe {
    strcmp(a, b) {
        if (a.toString() < b.toString()) return -1;
        if (a.toString() > b.toString()) return 1;
        return 0;
    }
    transform(value, args?) {
        var sortby = args;
        return value.filter(liveerror => {
            if (!this.strcmp(sortby, "全部"))
                return liveerror;
            else if (!this.strcmp(sortby, "未澄清"))
                return liveerror.clarified == false;
            else
                return liveerror.elder == sortby;
        });
    }

}