import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { ElderData } from './elder_data';
import { GlobalService } from '../global.service';

declare var Parse: any;



@Component({
  selector: 'app-select-elder',
  templateUrl: './select-elder.component.html',
  styleUrls: ['./select-elder.component.css']
})
export class SelectElderComponent extends ParentClass implements OnInit {

  elderDatas = new Array<ElderData>();
  isLoading = true;
  userDataWaitProcesscount: number;
  imageUrlTempArray = new Array<String>();
  globalService: GlobalService;
  constructor(
    router: Router, _globalservice: GlobalService
  ) {
    super(router);
    this.queryForElderList();
    this.globalService = _globalservice;
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }


  ngOnInit() {

  }


  queryElderuserObject(ServicedCareUserArray: String[]) {
    console.log("in queryElderuserObject()");
    if (ServicedCareUserArray == null || ServicedCareUserArray == undefined || ServicedCareUserArray.length == 0) {
      console.warn("in queryElderuserObject() /no data so return;");

      return;
    }
    this.userDataWaitProcesscount = ServicedCareUserArray.length;

    for (var mabowidIndex in ServicedCareUserArray) {

      console.log("in queryElderuserObject/ find mabowid= " + ServicedCareUserArray[mabowidIndex]);
      /// for (var mabowid in ServicedCareUserArray) {
      var userTask = Parse.Object.extend("_User");
      var query = new Parse.Query(userTask);
      query.include('ElderInfo');
      query.equalTo("username", ServicedCareUserArray[mabowidIndex]);
      //todo 沒有data 該如何拿他的mabowid
      query.find({
        success: (userObjects) => {
          var userObject = userObjects[0];
          if (userObject) {
            this.queryElderObjectCallback(true, userObject);
          } else {
            this.queryElderObjectCallback(false, null);
          }
        }, error: (object, error) => {
          console.error("in queryElderuserObject query  exception " + error + " : " + error.message);
          this.queryElderObjectCallback(false, undefined);
        }
      });




    }

  }


  queryElderObjectCallback(isSuccess: boolean, userObject) {
    console.log("in queryElderObjectCallback  isSuccess= " + isSuccess);

    // fatal error
    if (!isSuccess && userObject == undefined) {
      console.error("in queryElderObjectCallback fatal error");
      alert("遇到問題，請重新整理");
      this.userDataWaitProcesscount--;
      return;
    }
    var data = new ElderData();
    // no such user existed
    if (!isSuccess && userObject == null) {
      data.isEnable === false;
    } else {
      // console.dir(userObject)
      data.mabowid = userObject.get("username");
      data.Device = userObject.get("device");
      if (userObject.get('userProfileImageSmall')) {
        data.imageUrl = userObject.get('userProfileImageSmall').url();
      }
      data.note = userObject.get('note');
      data.nickname = userObject.get("userNickname") || data.mabowid;
      data.fullUserObject = userObject;
      data.user_object_id = userObject.id;
      data.pmi = userObject.get('pmi');
      data.name = "";
      if (userObject.get('ElderInfo')) {
        data.name = userObject.get('ElderInfo').get('Name');
      }

    }
    this.elderDatas.push(data);

    this.userDataWaitProcesscount--;
    // check is the last run
    if (this.userDataWaitProcesscount == 0) {
      console.log("in queryElderObjectCallback this is the last run ");

      //this.globalService.focusParseUserObject = this.elderDatas
      console.dir(this.elderDatas);
      this.isLoading = false;

    }

  }

  /**
   * rederict to elder
   */
  go(elderdata: ElderData) {
    console.log("in go function , and go to id page =" + elderdata.mabowid);
    this.globalService.focusParseUserObject = elderdata;

    this.router.navigateByUrl('/prepareCare');
  }

  /**
   * rederict to caredetails
   */
  gotocaredetail(elderdata: ElderData) {
    console.log("in gotocaredetail function , and go to id page =" + elderdata.mabowid);
    this.globalService.focusParseUserObject = elderdata;
    this.router.navigateByUrl('/caredetails');
  }
  /*
 query the elder for this sacretary  
 */
  queryForElderList() {
    console.log("in queryForElderList()");
    var currentUser = Parse.User.current();
    //console.dir(currentUser);

    var ServicedCareUserArray: String[] = currentUser.get("ServicedCareUser");
    /*
    ServicedCareUserArray.forEach((mabowid: String, index: Number, array: String[]) => {
      //var data = new ElderData();
      //data.mabowid = mabowid;
      //console.log(data.mabowid)
      //console.dir(this.elderDatas.length)
     // this.elderDatas.push(data)
    })*/


    this.queryElderuserObject(ServicedCareUserArray);




    /*
 
    var userTask = Parse.Object.extend("_User");
    var query = new Parse.Query(userTask);
    query.get(currentUser.objectId, {
        success: function(mObj) {
 
            //get current User's nickName
            if (typeof mObj.get("userNickname") === 'undefined') {
 
                currentUserName = mObj.get("username");
 
            } else {
                currentUserName = mObj.get("userNickname");
            }
            currentUserId = mObj.get("username");
            //get current User's ACL String
            //userACLString = mObj.get("username").concat("_eng");
            //console.log("userACLString = "+ userACLString);
            console.log("currentUserName = " + currentUserName);
            var elderArray = mObj.get("ServicedCareUser");
            var elderNameArray = [];
            var elderPhotoArray = [];
            for (var k = 0; k < elderArray.length; k++) {
                elderNameArray.push(elderArray[k]);
                elderObjectIdArray.push(elderArray[k]);
                elderPhotoArray.push("./image/care_head.png");
 
            }
            console.log("elderNameArray = " + elderNameArray);
            console.log("elderObjectIdArray = " + elderObjectIdArray);
            console.log("find user success!, ServicedCareUser = " + elderArray);
            //console.log("mObj.id = " + mObj.id); 
 
            var count = 0;
            for (var i = 0; i < elderArray.length; i++) {
                var grandsonTask = Parse.Object.extend("_User");
                var query2 = new Parse.Query(grandsonTask);
                query2.equalTo("username", elderArray[i]);
                query2.find({
                    success: function(results) {
                        count = count + 1;
                        console.log("Successfully retrieved " + results.length + " scores, count = " + count);
                        // Do something with the returned Parse.Object values
                        for (var j = 0; j < elderArray.length; j++) {
                            if (results[0].get('username') == elderNameArray[j]) {
 
                                elderObjectIdArray[j] = results[0].id;
                               
                                if (typeof results[0].get('userNickname') === 'undefined') {
 
                                    elderNameArray[j] = results[0].get('username');
 
                                } else {
                                    elderNameArray[j] = results[0].get('userNickname');
 
                                }
                                if (typeof results[0].get('userProfileImageSmall') === 'undefined') {
                                    elderPhotoArray[j] = "./image/care_head.png";
                                } else {
                                    var profilePhoto = results[0].get('userProfileImageSmall');
                                    elderPhotoArray[j] = profilePhoto.url();
                                    console.log("url " + j + " = " + profilePhoto.url());
                                    
                                }
                                console.log("eeeeelderPhotoArray " + j + " = " + elderPhotoArray[j]);
                            }
                        }
                       
                        console.log("eeeeelderNameArray = " + elderNameArray);
                        console.log("eeeeelderObjectIdArray = " + elderObjectIdArray);
 
 
                        if (count == elderArray.length) {
 
                            var sel = document.getElementById('elderNameList');
                            sel.onchange = function(){ //run some code when "onchange" event fires
                            document.getElementById('elderUserPhoto').src = "./image/reload_icon.png";
                            var chosenoption = this.options[this.selectedIndex]; //this refers to "selectmenu"
                            if (chosenoption.value!="nothing"){
                                //var photo = document.getElementById('elderUserPhoto');
                                document.getElementById('elderUserPhoto').src = elderPhotoArray[this.selectedIndex];
                            //alert("choose" + chosenoption.value);
                            }
                            }
                            console.log("into it");
                            for (var i = 0; i < elderArray.length; i++) {
                                var opt = document.createElement('option');
                                opt.innerHTML = elderArray[i] + " ( " + elderNameArray[i] + " )";
                                opt.value = elderArray[i];
                                sel.appendChild(opt);
 
                            }
                        }
 
                    },
                    error: function(error) {
                        alert("Error: " + error.code + " " + error.message);
                    }
                });
 
            }
 
 
 
        },
        error: function(error) {
            alert("Error: " + error.code + " " + error.message);
 
        }
    });*/

  }

}
