import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
import { Router } from '@angular/router';
import { ElderData } from '../select-elder/elder_data';
import { ComminnicatonParameterDefine } from '../comminnicatonParameterDefine';
import { BackGroundCommunicationService } from '../backgroundcommunication.service'
//import { Observable } from 'rxjs/Rx';
import { Observable } from 'rxjs';
import { KTV } from './ktv.component'


declare var Parse: any;
declare var videojs: any;




@Component({
  selector: 'app-careing',
  templateUrl: './careing.component.html',
  styleUrls: ['./careing.component.css']
})

export class CareingComponent extends KTV implements OnInit {

  globalService: GlobalService;
  backGroundCommunicationService: BackGroundCommunicationService;
  router: Router;
  focusParseUserData: ElderData;
  comminnicatonParameterDefine: ComminnicatonParameterDefine
  userACLString;
  adminACLString;
  readonlyAdminACLString;
  compurpose: number;
  isLoading = false;
  zoom_url: string;
  zoomMeetingID: string;
  jphoneMeetingID: string;
  //parseLiveInteraction;
  isSendClick: boolean = false;

  constructor(router: Router, public _globalservice: GlobalService, private _comminnicatonParameterDefine: ComminnicatonParameterDefine, public _backcommunicationservice: BackGroundCommunicationService) {
    super(_backcommunicationservice);
    _globalservice.userACLString = _globalservice.focusParseUserObject.mabowid.concat("_eng");// save userACLString
    this.globalService = _globalservice;
    this.globalService.communicationTool = this.globalService.communicationTool.replace(/(\r\n|\n|\r)/gm, "");
    this.backGroundCommunicationService = _backcommunicationservice;



    this.router = router;
    if (this.globalService.CareRecordInfoObject === null || this.globalService.CareRecordInfoObject === undefined) {
      this.router.navigateByUrl('/selectElder');

    }
    this.focusParseUserData = this.globalService.focusParseUserObject;
    this.comminnicatonParameterDefine = _comminnicatonParameterDefine;
    this.compurpose = this.globalService.CareRecordInfoObject.get("purpose");
    console.log(" care purpise= " + this.compurpose)
    this.adminACLString = this.globalService.adminACLString;
    this.readonlyAdminACLString = this.globalService.readonlyAdminACLString;
    this.userACLString = this.globalService.userACLString;

  }




  ngOnInit() {
  }

  /***
   * faceIssueCallback
   * @param event
   */
  faceIssueCallback(event) {
    console.log("in problemCallback");
    alert("請參照排解說明書");
    this.globalService.CareRecordInfoObject.set("isFaceIssue", true);
    console.dir(this.globalService.CareRecordInfoObject.get("isFaceIssue"));
  }
  /***
    * canDeactivate
    */
  canDeactivate() {
    console.log('i am navigating away');
    if (this.isSendClick == true) {
      return true
    } else {
      var r = window.confirm('放棄本次通話嗎?');
      if (r) {
        //this.globalService.forcestopRecording();
        return true
      }
    }
  }
  /***
   * directFinishTheCareRecordProcess
   * @param {boolean} isDone
   */
  directFinishTheCareRecordProcess(isDone: boolean) {
    console.log("in directFinishTheCareRecordProcess and direct save the recordobject")
    this.isLoading = true;
    this.saveRecordInfoObject(isDone);
  }


  /***
   * goToAddmessage
   * @param {number} successType
   */
  goToAddmessage(successType: number) {
    console.log("in goToAddmessage type= " + successType);
    var r = confirm("前往發訊息？");
    if (r) {
      this.isSendClick = true;
      this.router.navigate(['/addMessageBoard', successType]);
    } else {
      this.isSendClick = false;
    }

  }

  /***
   * saveRecordInfoObject
   * @param {boolean} isDone
   */
  saveRecordInfoObject(isDone: boolean) {
    console.log("in saveRecordInfoObject");

    // fill acl
    var roleACL = new Parse.ACL();
    roleACL.setRoleWriteAccess(this.adminACLString, true);
    roleACL.setRoleReadAccess(this.adminACLString, true);
    roleACL.setRoleWriteAccess(this.userACLString, true);
    roleACL.setRoleReadAccess(this.userACLString, true);
    roleACL.setRoleReadAccess(this.readonlyAdminACLString, true);
    roleACL.setRoleWriteAccess(this.readonlyAdminACLString, false);



    console.log(this.userACLString)
    // fill object 
    this.globalService.CareRecordInfoObject.set("isThisClassFillComplete", true);
    this.globalService.CareRecordInfoObject.set("endtime", new Date());
    this.globalService.CareRecordInfoObject.set("isDone", isDone);
    this.globalService.CareRecordInfoObject.setACL(roleACL);

    // saving data
    console.log("in saveRecordInfoObject/ start to save message object");
    this.globalService.CareRecordInfoObject.save(null, {
      success: (task) => {
        var taskObjId = task.id;
        console.log('in saveRecordInfoObject/ New object created with objectId: ' + task.id);
        if (!task.id) {
          this.retrySaveRecordInfoObject(isDone);
          return;
        }
        //errorlog("Success")
        this.successCallbak("本次任務完成，謝謝", true)
      },
      error: (gameScore, error) => {
        console.error('in saveRecordInfoObject messageboardObjeciId= / Failed to create new object, with error code: ' + error.message);
        this.retrySaveRecordInfoObject(isDone);
      }
    });
  }


  /***
  * retrySaveRecordInfoObject
  * @param {boolean} isDone
  */
  retrySaveRecordInfoObject(isDone: boolean) {
    console.log("in retrySaveRecordInfoObject");
    this.saveRecordInfoObject(isDone);
  }

  successCallbak(code, isSuccess) {
    console.log("in errorlog code= " + code + " issuccess " + isSuccess);

    //submit messageboard success and save recordinfo
    if (isSuccess) {
      console.log(" in errorlog / trigger save recordinfo");
      alert(code);
      this.router.navigateByUrl('/selectElder');
      this.isLoading = false;


    }


  }
  sendRemoteRing(type) {

    if (type == 1 && (this.zoomMeetingID == null && this.jphoneMeetingID == null)) {
      this.errorHandler(-4);

      return;
    }

    if (type == 2 && this.jphoneMeetingID == null) {
      this.errorHandler(-6);

      return;
    }

    var type;
    var sessionId;
    if (type == 1) {
      type = "zoom"
      sessionId = this.zoomMeetingID || this.jphoneMeetingID;
    } else if (type == 2) {
      type = "jphone"
      sessionId = this.jphoneMeetingID;

    }
    console.log("type=" + type + " sessionId=" + sessionId);

    let parms = {
      "sessionId": sessionId,
      "callerObjId": Parse.User.current().id,
      "callerUsername": Parse.User.current().get("userNickname"),
      "calleeObjId": this.focusParseUserData.user_object_id,
      "type": type
    }
    if (this.jphoneMeetingID) {
      console.log("callSomeOne with 390(jphone)")
      parms["mpd"] = sessionId;
    }

    Parse.Cloud.run("callSomeOne", parms).then((result) => {
      console.log(result)
      console.log("callSomeOne success");
      this.zoomMeetingID = null;
      this.jphoneMeetingID = null;
      var callRecordingParse = Parse.Object.extend("CallRecording");
      this.KTVlive.callRecording_pointer = new callRecordingParse();
      this.KTVlive.callRecording_pointer.id = result.recordObjId;




      alert("通知響鈴成功，請回到通訊 app")
    }, (error) => {
      console.log(error);
      // error
      this.jphoneMeetingID = null;
      this.zoomMeetingID = null;
      this.errorHandler(-3);
    });

  }

  /**
   * jphonestart
   */
  jphonestart() {
    this.copyToBoard(Parse.User.current().id);
    alert("1. 請手動打開app（如果已經開過請關掉重新開） \n2. 在會議號碼還有密碼欄位ctrl+v貼上房間號碼 " + Parse.User.current().id + "（已經自動複製）\n" + "3. 按加入會議\n4. 當成功看到自己後，記得要回來這裡按 1.2 按鍵gate 才會響鈴!!")
    this.jphoneMeetingID = Parse.User.current().id

  }
  /**
   * copyToBoard
   * @param copyString 
   */
  copyToBoard(copyString) {
    var range = document.createRange();

    let selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyString;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    var copyStatus = document.execCommand('copy');
    var msg = copyStatus ? 'ok' : 'failed';
    //alert("copy: '" + copyString + "' " + msg);
  }

  /**
   * zoomstart
   */
  zoomstart() {
    if (this.zoom_url != null) {
      this.errorHandler(-5);
      return;
    }
    alert("當成功打開ZOOM 程式並可以看到自己後，\n記得要回來這裡按 1.2 按鍵gate 才會響鈴!!")
    this.isLoading = true;

    Parse.Cloud.run("createZoomMetting", {
      "hostId": Parse.User.current().get("zoompUserId"),
      //"callerObjId": Parse.User.current().id,
      //"callerUsername": Parse.User.current().get("zoompUserId"),
      //"calleeObjId": this.focusParseUserData.user_object_id,
      //"type": "zoom"
    }).then((result) => {
      // make sure the set the enail sent flag on the object
      console.log("success!")
      console.dir(result)
      console.log("result :" + JSON.parse(result))
      const meetingInfo = JSON.parse(result)
      this.zoom_url = meetingInfo["start_url"];
      console.log(this.zoom_url)

      console.log("id= " + meetingInfo["id"])
      this.zoomMeetingID = meetingInfo["id"];
      window.open(this.zoom_url, 'zoom');
      this.isLoading = false;



    }, (error) => {
      this.errorHandler(-2);
      // error
    });

  }


  /***
   * errorHandler
   */
  errorHandler(code) {
    if (code == -4) {
      alert("請先起動zoom");

    } else if (code == -5) {
      alert("zoom已經啟動過了，如不小心關閉zoom程式，請按下面連結再打開程式");

    } else if (code == -6) {
      alert("請先按 1.1");

    } else {

      alert("發現錯誤，請再嘗試" + code);

    }

    this.isLoading = false;

  }





}
