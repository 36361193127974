import { Component } from '@angular/core';
import { Router } from '@angular/router';

declare var Parse: any;



export class ParentClass {
    router: Router;

    constructor(private _router: Router) {
        console.log("in ParentClass consttruct")
        this.router = _router;
        //this.loginIfPossible();
    }

    ngOnInit(): void {
        console.log("in ParentClass/ ngOnInit")
    }




    loginIfPossible() {
        var currentUser = Parse.User.current();
        if (!currentUser) {
            console.log("in ParentClass/ parseLoginIfNeeded/ currentUser NOT existed ");
            console.dir(currentUser);
            console.log("in ParentClass/ parseLoginIfNeeded/ redericet to login ");
            this.router.navigateByUrl('/login');


        }
    }


    /***
     * query useronject by usernamre
     * @param username
     * @return Promise User
    */

    queryUser(username) {
        console.log("in queryUser username= " + username)
        var User = Parse.Object.extend("_User");
        var query = new Parse.Query(User);
        query.equalTo("username", username);
        query.include('userProfileImageSmall');
        return query.first();
    }

    /**
     *  create user by input data 
     */
    createInputUser(username: string, isNeedUserConfirm: boolean) {
        console.log("in createInputUser function isNeedUserConfirm= " + isNeedUserConfirm);


        return new Promise((resolve, reject) => {
            Parse.Cloud.run('SignUpUser', { username: username, isNeedUserConfirm: isNeedUserConfirm/* secretary: this.secretaryInfo.secretary */}).then(
                (userObf) => {
                    console.log("createuser user: " + userObf.getUsername());
                    resolve(userObf);
                }, (error) => {
                    console.error("SignUpUser error");
                    reject();

                });    //this.router.navigateByUrl('/caredetails');
        });
    }

    /**
    *  create user by input data 
    */
    createInputUserWithNick(username: string, nick: string, isNeedUserConfirm: boolean) {
        console.log("in createInputUser function createInputUserWithNick= " + isNeedUserConfirm);
        return new Promise((resolve, reject) => {
            Parse.Cloud.run('SignUpUserWithNick', { username: username, isNeedUserConfirm: isNeedUserConfirm/* secretary: this.secretaryInfo.secretary */, userNick: nick }).then(
                (userObf) => {
                    console.log("createuser user: " + userObf.getUsername());
                    resolve(userObf);
                }, (error) => {
                    console.error("SignUpUser error");
                    reject();
                });    //this.router.navigateByUrl('/caredetails');
        });
    }



}