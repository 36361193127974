export class SubscriptionInfo {
  mabowid: number;
  buytime: number;
  usedMinutes: number;
  startdate: Date;
  startdateString:any
  enddate: Date;
  enddateString:any
  option: number;
  productType: number;
  productName: String;
  serialNum:String;
  isFormatValidate:boolean;
  product: Array<String> = [undefined, "顧幫手", "秘書服務"];
  originObject:any;
}