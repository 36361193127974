import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';
import { BackGroundCommunicationService } from '../backgroundcommunication.service';
import { ElderData } from '../select-elder/elder_data';

declare var OT: any;
declare var Parse: any;

export class CheckSubResult {
  isConnect: boolean
  code: number
}

@Component({
  selector: 'app-video-chat-module',
  templateUrl: './video-chat-module.component.html',
  styleUrls: ['./video-chat-module.component.css']
})
export class VideoChatModuleComponent implements OnInit {
  session = null;
  backGroundCommunicationService: BackGroundCommunicationService;
  sessionConnectionCount: number = 0;
  streamConnectionCount: number = 0;
  subscribeStreamTimer;
  publisher;
  router
  subscriber;
  resolution;
  globalService: GlobalService;

  //record info data object
  //CareRecordInfoObject;
  isLoading = false;
  //focusParseUserData: ElderData;

  constructor(private _globalService: GlobalService, private _BackGroundCommunicationService: BackGroundCommunicationService, _router: Router) {
    this.globalService = _globalService;
    this.backGroundCommunicationService = _BackGroundCommunicationService;
    this.router = _router;
    this.resolution = { 'h': document.documentElement.clientHeight, 'w': document.documentElement.clientWidth }
    //.h. this.focusParseUserData = _appservice.focusParseUserObject;

    //this.initOpentok(_appservice.opentokData.opentokSessionid)
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }



  ngOnInit() {
    console.log("@VideoChatModuleComponent/ in ngOnInit  ");
    console.dir(this.backGroundCommunicationService.opentokData);
    // init the opentok object
    this.queryRemoteUserObject();
    if (this.initTheOpentokSessionsessionID(this.backGroundCommunicationService.opentokData.connectionObject.hosterOpentokSessionId)) {
      this.connectToSession();
    } else {
      this.errorCallback("初使化opentok 錯誤");
    }


  }

  /**
   * zero step init the opentok onject
   */
  initTheOpentokSessionsessionID(sessionID): boolean {
    console.log("in initTheOpentokSession with sessionID= " + sessionID);

    this.session = OT.initSession("45721072", sessionID);
    if (this.session) {
      this.registerSessionEvent();
    }
    return this.session;
  }
  /***
   * 
   * first connect to opentok session
   */

  connectToSession() {
    console.log('@VideoChatModuleComponent/ in connectToSession');

    this.session.connect(this.backGroundCommunicationService.opentokData.connectionObject.hosterOpentokToken, (error) => {
      if (error) {
        console.log('@VideoChatModuleComponent/ Unable to connect: ', error.message);
        this.errorCallback(error.message);
      } else {
        //document.getElementById('disconnectBtn').style.display = 'block';
        console.log('@VideoChatModuleComponent/ Connected to the session.');
        //this.sessionConnectionCount = 1;
        this.publishSelfVideo(
          () => {

            console.log('@VideoChatModuleComponent/ publishSelfVideo success');

          },
          (error) => {
            console.error('@VideoChatModuleComponent/ publishSelfVideo fail');
            this.errorCallback(error.message);


          })
      }
    });
  }

  /***
   * 
   * register session connection  event
   */

  registerSessionEvent() {
    console.log("@VideoChatModuleComponent /in registerSessionEvent ");
    this.session.on({
      connectionCreated: (event) => {
        console.dir(event)

        this.sessionConnectionCount++;
        console.log("@VideoChatModuleComponent / session event " + this.sessionConnectionCount + ' connections.');
      },
      connectionDestroyed: (event) => {
        console.dir(event)
        this.sessionConnectionCount--;
        console.log("@VideoChatModuleComponent / session event " + this.sessionConnectionCount + ' connections.');
      },
      sessionDisconnected: function sessionDisconnectHandler(event) {
        // The event is defined by the SessionDisconnectEvent class
        console.log('@VideoChatModuleComponent / session event/ Disconnected from the session.');

        //document.getElementById('disconnectBtn').style.display = 'none';
        if (event.reason == 'networkDisconnected') {
          alert('Your network connection terminated.')
        }
      }
      ,
      "streamCreated": (event) => {
        console.log("New stream in the session: " + event.stream.streamId);
        this.streamConnectionCount++
        console.dir(event.stream);
        console.info("@VideoChatModuleComponent / stream event " + this.streamConnectionCount + ' connections.');

        // start sub stream
        if (this.subscribeStreamTimer) {
          clearTimeout(this.subscribeStreamTimer);

        }
        this.subscribeStreamTimer = setTimeout(() => {
          this.startSubscripStream(event.stream);
        }, 500);

      },
      "streamDestroyed": (event) => {
        console.warn("@VideoChatModuleComponent/ Stream stopped. Reason: " + event.reason);
        this.streamConnectionCount--
        console.info("@VideoChatModuleComponent / stream event " + this.streamConnectionCount + ' connections.');
        this.hangup();


      }
    });


  }

  /***
    * 
    * second publish self video
    */
  publishSelfVideo(scb, fcb) {
    console.log("@VideoChatModuleComponent/ publishSelfVideo");
    if (this.session.capabilities.publish == 1) {
      // The client can publish. See the next section.
      var name;
      if (this.backGroundCommunicationService.opentokData.connectionObject.amIHoster) {
        name = this.backGroundCommunicationService.opentokData.connectionObject.callerObj.ObjId;
      } else {
        name = this.backGroundCommunicationService.opentokData.connectionObject.calleeObj.ObjId;
      }
      var width = this.resolution.w * 0.24;
      var height = width * (3 / 4)
      var targetElement = 'selfVideo';
      var publisherOptions = {
        insertMode: 'append',
        width: width,
        height: height,
        name: name
      };
      this.publisher = OT.initPublisher(targetElement, publisherOptions, (error) => {
        if (error) {
          // The client cannot publish.
          // You may want to notify the user.
          console.error('Publisher fail.');

          fcb(error);

        } else {
          console.log('Publisher initialized.');
          if (this.session) {
            // publish stream
            this.session.publish(this.publisher);
            // register publisher stream created
            this.publisher.on('streamCreated', (event) => {
              scb();
              console.log('@VideoChatModuleComponent/ The publisher started streaming.');
            });
          }
        }

      });
    } else {
      fcb();
      // The client cannot publish.
      // You may want to notify the user.
    }


  }






  errorCallback(message) {
    console.log("in errorCallback() message=" + message);
    alert(message);
  }


  /**
   * 4. subscribe remote stream with some constrain
   * 
   */
  startSubscripStream(stream) {
    var checkResult = this.beforeVideocallConnectCheck(stream)

    console.log("in startSubscripStream checkResult.code= " + checkResult.code);
    switch (checkResult.code) {
      case 1: //  normal answer
        //var subscriberProperties = { insertMode: 'append' };
        var width = this.resolution.w * 0.75;
        var height = width * (16 / 9)
        if (height > this.resolution.h) height = this.resolution.h * 0.88
        var options = { width: width, height: height, insertMode: 'append' }

        this.subscriber = this.session.subscribe(stream,
          'remoteVideo',
          options,
          (error) => {
            if (error) {
              console.log(error);
              this.errorCallback(error.message);
            } else {
              console.log('in startSubscripStream/ Subscriber added.');
            }
          });
        break;
      case -1: // remote is leaving
        this.afterRemoteStreamConnectCallback(-1)
        break;
      case -2: // remote is too many (maybe delay to send incoming, but at the mean time remote call other)
        this.afterRemoteStreamConnectCallback(-2)

        break;
      case -3: // maybe delay to send incoming. but remote is "calling" to other; 
        this.afterRemoteStreamConnectCallback(-3)

        break;
    }

  }

  /**
   * 
   * check this opentok channel contain other person who is not caller and callee
   */
  afterRemoteStreamConnectCallback(code) {
    console.log("in afterRemoteStreamConnectCallback code" + code);
    switch (code) {
      case -1:
        alert("客戶已經在接通前掛掉電話 (-1)")
        this.hangup(-1)

        break;
      case -2:
        alert("因爲傳訊延遲，客戶已經在跟別人視訊了 (-2)")
        this.hangup(-2)
        break;
      case -3:
        alert("因爲傳訊延遲，客戶已經在跟別人視訊了 (-3)")
        this.hangup(-3)


        break;
    }
  }



  /**
   * after call, redirect to different route
   * 
   */
  goRouteToUrl(url: string) {
    console.log("goRouteToUrl() url= " + url);

    this.router.navigateByUrl("/" + url);

  }



  // hangup
  hangup(reason?) {
    console.log("in hangup() reason= " + reason);
    this.disconnectSession();

    if (!reason) {
      this.queryCallinCareRecordinfoObject(() => {
        this.goRouteToUrl('caring')
      },
        (error) => {
          alert(error)
          this.goRouteToUrl('')

        });
    } else {
      this.goRouteToUrl('')
    }



  }


  /**
   * other internal used function
   */
  beforeVideocallConnectCheck(stream): CheckSubResult {
    console.log("@VideoChatModuleComponent /in beforeVideocallConnectCheck ");
    // 1. check is this session contain other session or stream (ONLY ONE ALLOW) 
    // if stream =0  => 
    // 1. condition : 1. caller is leave (stream=0) 2. caller is calling(還沒接) to other callee (stream.name !=agend can prevent)  3. caller is talking with other callee (stream>1)  4. caller is as callee (stream=0) 
    var result: CheckSubResult = { isConnect: true, code: 1 }

    if (this.streamConnectionCount == 0) {
      console.warn("@VideoChatModuleComponent /in beforeVideocallConnectCheck -1 ");

      result.isConnect = false;
      result.code = -1;

    } else if (this.streamConnectionCount > 1) {
      console.warn("@VideoChatModuleComponent /in beforeVideocallConnectCheck -2 ");

      result.isConnect = false;
      result.code = -2;


    } else if (stream.name != "callagent") {
      console.warn("@VideoChatModuleComponent /in beforeVideocallConnectCheck -3 ");

      result.isConnect = false;
      result.code = -3;
    }

    return result

  }

  disconnectSession() {
    console.log("@VideoChatModuleComponent /in disconnectSession ");
    this.session.disconnect();
    this.session = null;
    this.publisher = null;
  }


  unpublishStream(publisher) {
    console.log("in unpublishStream()");
    this.session.unpublish(publisher);

  }
  unSubscribtStream(subscriber) {
    console.log("in unSubscribtStream()")

    this.session.unsubscribe(subscriber);
  }


  /***
   * 完成care 後找到對應的歷史紀錄檔案要前往紀錄
   */
  queryCallinCareRecordinfoObject(scb, fcb) {
    console.log("in queryCallinCareRecordinfoObject()");
    console.dir(this.globalService.focusParseUserObject)
    if (this.globalService.focusParseUserObject.fullUserObject == null) {
      fcb("錯誤：沒有老人資料物件，所以請手動加i");
      return;
    }
    this.isLoading = true;

    var careRecordInfoTask = Parse.Object.extend("CareRecordInfo");
    var query = new Parse.Query(careRecordInfoTask);
    query.equalTo("elder", this.globalService.focusParseUserObject.fullUserObject);
    //query.equalTo("careGiver", Parse.User.current());
    query.notEqualTo("isThisClassFillComplete", true);
    query.equalTo("purpose", 4); // is callin
    query.descending("createdAt");

    query.find({
      success: (careRecordInfoRemotrObjectes) => {
        if (careRecordInfoRemotrObjectes.length > 0) {
          console.info("in queryCareRecordinfoObject()/ query success/ with not finish data");
          //var careRecordInfoRemotrObjecte = careRecordInfoRemotrObjectes[0];
          //this.CareRecordInfoObject =careRecordInfoRemotrObjectes[0];
          careRecordInfoRemotrObjectes[0].set("careGiver", Parse.User.current());
          this.globalService.CareRecordInfoObject = careRecordInfoRemotrObjectes[0];
          scb();
          //this.fillControlGroup();
        } else {
          console.log("in queryCareRecordinfoObject()/ query success/ no not finish careinfoobject");
          //todo
          fcb("錯誤：請手動進入到服務執行去完成後續動作");
        }
        this.isLoading = false;

      }, error: (object, error) => {
        console.error("in queryCareRecordinfoObject  query  exception " + error + " : " + error.message);
        //todo
        fcb("錯誤：請手動進入到服務執行去完成後續動作");


      }
    });
  }

  /**
   * query user full  object from remote
   * 
   */
  queryRemoteUserObject() {
    console.log("@VideoChatModuleComponent/ in queryRemoteUserObject  ");

    if (this.backGroundCommunicationService.opentokData.connectionObject.amIHoster == false) { // self is callee
      var remoteUser = Parse.Object.extend("_User");
      var query = new Parse.Query(remoteUser);
      //query.equalTo("elder", this.backGroundCommunicationService.opentokData.elderUserObjectWithoutData);
      query.get(this.backGroundCommunicationService.opentokData.connectionObject.callerObj.ObjId, {
        success: (userObject) => {
          if (userObject) {
            console.info("in queryRemoteUserObject()/ query success/ ");
            //var data = new ElderData();


            var data = new ElderData();
            data.mabowid = userObject.get("username");
            data.Device = userObject.get("device");
            if (userObject.get('userProfileImageSmall')) {
              data.imageUrl = userObject.get('userProfileImageSmall').url();
            }
            data.note = userObject.get('note');
            data.nickname = userObject.get("userNickname") || data.mabowid;
            data.fullUserObject = userObject;
            data.user_object_id = userObject.id;
            console.info("in queryRemoteUserObject()/ B ");

            this.globalService.focusParseUserObject = data;
            console.info("in queryRemoteUserObject()/ c ");

            console.dir(this.globalService.focusParseUserObject)

          } else {
            console.log("in queryRemoteUserObject()/ query success/ without data");
            this.globalService.focusParseUserObject = null;
          }

        }, error: (object, error) => {
          console.error("in queryRemoteUserObject  query  exception " + error + " : " + error.message);
          //todo
          this.globalService.focusParseUserObject = null;
        }
      });
    }


  }

}
