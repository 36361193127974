import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { GlobalService } from '../global.service';
import { ElderData } from '../select-elder/elder_data';
import { caretoday } from './caretoday';
import { CareTodayPipe } from './caretoday.pipe';

import * as moment from "moment";
import { MESSAGEBOARD } from './caretoday.msgreview';
import { COMMENT } from './caretoday.commentreview';

declare var Parse: any;
declare var $: any;

@Component({
  selector: 'CareToday',
  templateUrl: 'caretoday.component.html',
  styleUrls: ['caretoday.component.css']
})



export class CareTodayComponent extends ParentClass implements OnInit {
  focusParseUserData: ElderData;
  sortBy: string = "";
  CareToday: caretoday[] = [];
  elderlist: string[];

  public formattedDay;
  public selectedDay;
  isLoading: boolean = true;
  isCustomResponseLoading: boolean = true;

  //MessageBoardContent = [];
  check_media = "";

  MessageBoardArray = [];
  MessageCommentArray = [];

  MessageBoardDetail = {
    Message: '',
    Date: '',
    CommentArray: []

  }

  CommentDetail = {
    comment: "",
    date: ""
  }

  newComment = "";

  constructor(
    router: Router, private _globalservice: GlobalService
  ) {
    super(router);
    this.focusParseUserData = _globalservice.focusParseUserObject;
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/

  }

  ngOnInit(): void {
    $('.ui.styled.accordion')
      .accordion()
      ;
    let time = moment();
    this.selectedDay = { year: time.year(), month: time.month(), day: time.date() };
    this.formattedDay = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).format("YYYY/MM/DD");
    this.QueryCareRecordInfo();
    // add queryMessageBoard function
    this.queryMessageBoard();

  }

  onChangeInput(caretoday) {
    console.log("onChangeInput " + caretoday.objectId + " note: " + caretoday.Note);
    var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
    var carerecordinfo = new CareRecordInfo();
    carerecordinfo.id = caretoday.objectId;
    carerecordinfo.set('note', caretoday.Note);
    carerecordinfo.save(null, {
      success: (carerecordinfo1) => {
        console.log("onChangeInput saved successfully id: " + carerecordinfo1.id + " / " + carerecordinfo1.get('note'));
      },
      error: function (carerecordinfo, error) {
        console.log("save failed " + error.message);
      }
    });
  }

  onChangeCheckBox(caretoday, event) {
    var isChecked = event.currentTarget.checked;
    console.log("onChangeCheckBox " + caretoday.objectId + " reviewed: " + isChecked);
    var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
    var carerecordinfo = new CareRecordInfo();
    carerecordinfo.id = caretoday.objectId;
    carerecordinfo.set("reviewed", isChecked);
    carerecordinfo.save(null, {
      success: (carerecordinfo) => {
        console.log("onChangeCheckBox saved successfully id: " + carerecordinfo.id);
      },
      error: function (carerecordinfo, error) {
        console.log("save failed " + error.message);
      }
    });
    this.router.navigateByUrl('/caretoday');

  }

  QueryCareRecordInfo() {
    this.isLoading = true;
    this.sortBy = "";
    this.CareToday = [];
    this.elderlist = [];
    this.elderlist.push("全部");
    this.elderlist.push("成功CARE");
    this.elderlist.push("未成功CARE");

    var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
    var carerecordinfo = new CareRecordInfo();
    var query = new Parse.Query(CareRecordInfo);
    query.include('relateMessageBoard');
    query.include('careGiver');
    query.include('elder');
    query.include('elderDetailInfo');
    query.include('careRecordInfoV2');
    var currentDate_start = new Date(this.selectedDay.year, this.selectedDay.month, this.selectedDay.day, 0, 0, 0, 0);
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).add(1, 'day');;
    var currentDate_end = new Date(time.year(), time.month(), time.date(), 0, 0, 0, 0);

    query.greaterThan("createdAt", currentDate_start);
    query.lessThan("createdAt", currentDate_end);

    query.descending("username");
    query.limit(1000);

    query.find({
      success: (results) => {
        console.log("Successfully retrieved " + results.length + " messages.");
        for (var i = 0; i < results.length; i++) {
          var object = results[i];

          var elder = "undefined";
          var elder_nickname = "undefined";
          if (object.get('elder')) {
            elder = object.get('elder').get("username");
          }

          if (object.get('elderDetailInfo')) {
            elder_nickname = object.get('elderDetailInfo').get("NickName");
          }

          var careGiver = "undefined";
          if (object.get('careGiver'))
            careGiver = object.get('careGiver').get('userNickname');

          var _reviewed = object.get('reviewed');

          var dateStr = "undefined";
          if (object.get('starttime')) {
            dateStr = this.padStr(1 + object.get('starttime').getMonth()) + "/" +
              this.padStr(object.get('starttime').getDate());
          }

          var starttime = "undefined";
          if (object.get('starttime')) {
            starttime = this.padStr(object.get('starttime').getHours()) + ":" +
              this.padStr(object.get('starttime').getMinutes());
          }
          var endtime = "undefined";
          if (object.get('calldonetime')) {
            endtime = this.padStr(object.get('calldonetime').getHours()) + ":" +
              this.padStr(object.get('calldonetime').getMinutes());
          }
          var minutes = '0';
          var _message = "本次溝通沒有填寫 i";
          var _DetailRecord = "本次溝通沒有填寫";
          var _ImageURL = null;

          console.log("/QueryCareRecordInfo/relateMessageBoard ");
          if (object.get('relateMessageBoard')) {
            var messageboard = object.get('relateMessageBoard');
            minutes = (Number(messageboard.get("talkInSecond")) / 60).toFixed(2);
            _message = messageboard.get('MESSAGE');
            _DetailRecord = messageboard.get("DetailRecord");
            if (messageboard.get("ImageUploadSmall")) {
              _ImageURL = messageboard.get("ImageUploadSmall").url();
            }
          }
          var isdone = "否";
          isdone = (object.get('isDone')) ? "是" : "否";

          var fail_reason = "";
          if (object.get('fail_reason'))
            fail_reason = String(object.get('fail_reason'));


          var video_quality = "";
          if (object.get('video_quality'))
            video_quality = object.get('video_quality') ? "好" : "差";

          var pc = "";
          pc = (object.get('comPC')) ? object.get('comPC') : "";


          console.log("try to fetch record media");
          var media = "";

          if (object.get('careRecordInfoV2')) {
            //console.log("try to fetch record media 2");

            media = object.get('careRecordInfoV2').get('url');
            console.log("media=" + media);
          }

          // push data
          this.CareToday.push({
            Time: dateStr,
            Message: _message,
            DetailRecord: _DetailRecord,
            StartTime: starttime,
            IsDone: isdone,
            fail_reason: fail_reason,
            video_quality: video_quality,
            Duration: minutes,
            CommunicationTool: object.get('communicationTool'),
            EndTime: endtime,
            HelpDetail: (object.get('helpDetail') == undefined) ? "N/A" : object.get('helpDetail'),
            Note: (object.get('note') == undefined) ? "N/A" : object.get('note'),
            Secretary: careGiver,
            elder: elder,
            elder_nickname: elder_nickname,
            reviewed: _reviewed,
            objectId: object.id,
            PC: pc,
            ImageURL: _ImageURL,
            VideoUrl: media,

          });

          if (this.elderlist.indexOf(elder) == -1) {
            this.elderlist.push(elder);
          }

        }
        this.isLoading = false;
      },
      error: function (error) {
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }
  onDetailInputfocusOutFunction() {
    console.log("focusOutFunction");

  }

  onDetailInputFocus(a) {
    console.log("onFocus" + a);


  }

  padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
  }

  public previous(): void {
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).add(-1, 'day');;
    this.selectedDay = { year: time.year(), month: time.month(), day: time.date() };
    this.formattedDay = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).format("YYYY/MM/DD");
    this.QueryCareRecordInfo();
  }

  public next(): void {
    let time = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).add(1, 'day');
    this.selectedDay = { year: time.year(), month: time.month(), day: time.date() };
    this.formattedDay = moment().year(this.selectedDay.year).month(this.selectedDay.month).date(this.selectedDay.day).format("YYYY/MM/DD");
    this.QueryCareRecordInfo();
  }

  checkVideo(videoUrl) {

    if (videoUrl == "" || videoUrl == null) {
      alert("此次記錄沒有上傳影片")
    } else {
      /*window.open(videoUrl);*/
      /*window.open(videoUrl, '_blank', 'location=yes,height=500,width=600,scrollbars=no,status=yes');*/

      this.check_media = videoUrl;
      //alert("播放影片")
      var myVideo = document.querySelector('video')
      myVideo.src = videoUrl;
      myVideo.load();
      myVideo.play();

      window.document.body.scrollTop = 0;
      window.document.documentElement.scrollTop = 0;

    }




  }

  /**
   * query response
   */
  queryMessageBoard() {
    this.isCustomResponseLoading = true;
    var d_end = new Date();
    var d_start = d_end;
    d_start.setDate(d_end.getDate() - 60); // find 30 day

    //console.log("queryMessageBoard...date range -> " + d_start + " --- " + d_end);
    var msg_object_array = [];

    var MessageBoardParse = Parse.Object.extend("MessageBoard");
    var query = new Parse.Query(MessageBoardParse);
    query.equalTo("USER_TYPE", 1);
    query.contains("USER_ID", "54668424");
    query.limit(1000);
    query.greaterThanOrEqualTo("updatedAt", d_start);
    query.find((results) => {
      for (var i = 0; i < results.length; i++) {
        var msg_object = results[i];
        var msg_objectId = msg_object.id;
        msg_object_array.push(msg_objectId);
      }
      this.queryActivityCommit(msg_object_array);
    }).catch((error) => {
      this.isCustomResponseLoading = false;

      alert("Error: " + error.code + " " + error.message);
    })


  }

  /**
   * queryActivityCommit
   * @param msg_object_array
   */
  queryActivityCommit(msg_object_array) {
    var MSG = Parse.Object.extend("MessageBoard");
    var innerQuery = new Parse.Query(MSG);
    innerQuery.containedIn("objectId", msg_object_array);
    var ACTIVITY = Parse.Object.extend("Activity");
    var query = new Parse.Query(ACTIVITY);
    query.matchesQuery('message', innerQuery);
    query.equalTo("type", "comment");
    query.descending("updatedAt");
    query.limit(1000);

    query.find((results) => {
      for (var i = 0; i < msg_object_array.length; i++) {
        //console.log("checking MSG objectId = " + msg_object_array[i] + " ...");
        var lastcommentcheck = false;
        for (var j = 0; j < results.length; j++) {
          var commit_object = results[j];
          var commit_user_id = commit_object.get("fromUser").id;
          if (commit_object.get("message").id == msg_object_array[i]) {
            if (!lastcommentcheck) {
              lastcommentcheck = true;
              this.checkActivityCommitLastCommitter(commit_object);
            }
          }
        }
      }
    }).catch((error) => {
      this.isCustomResponseLoading = false;

      alert("Error: " + error.code + " " + error.message);

    });
  }

  /**
   * checkActivityCommitLastCommitter
   * @param commit_object
   */
  checkActivityCommitLastCommitter(commit_object) {
    var fromUser_id = commit_object.get("fromUser").id;
    var USER = Parse.Object.extend("_User");
    var query = new Parse.Query(USER);
    query.get(fromUser_id, {
      success: (usr_object) => {
        this.isCustomResponseLoading = false;
        // is customer reply
        if (!usr_object.get("username").includes("54668424")) {
          var commit_content = commit_object.get("content");
          var commit_date = commit_object.updatedAt;
          var commit_parentMessageBoard_objectId = commit_object.get("message").id;

          this.displayParentMessageBoard(commit_parentMessageBoard_objectId);

        }
      },
      error: (usr_object, error) => {
        this.isCustomResponseLoading = false;

        alert("Error: " + error.code + " " + error.message);
      }
    });
  }


  /**
   *
   * @param commit_parentMessageBoard_objectId
   */
  displayParentMessageBoard(commit_parentMessageBoard_objectId) {

    //console.log("displayParentMessageBoard : " + commit_parentMessageBoard_objectId);
    var MessageBoardParse = Parse.Object.extend("MessageBoard");
    var query = new Parse.Query(MessageBoardParse);
    query.include("UserKey");
    query.get(commit_parentMessageBoard_objectId, {
      success: (object) => {

        var msg_org = object.get("MESSAGE");
        var msg_date = object.createdAt;
        var msg_updater = object.get("UserKey").get("userNickname");
        var msg_updater_id = object.get("UserKey").id;
        var msg_elder_number = object.get("MABOW_ID");
        console.log("Original MSG by " + msg_updater_id + " - " + msg_updater + " : " + msg_org + " --- " + msg_date);


        var UserParse = Parse.Object.extend("_User");
        var query_user = new Parse.Query(UserParse);
        query_user.equalTo("username", msg_elder_number);
        query_user.find({
          success: (ELDER) => {


            var MessageBoardObject = new MESSAGEBOARD();
            MessageBoardObject.MessageBoardObjectId = commit_parentMessageBoard_objectId;
            MessageBoardObject.MessageBoardElderNumber = ELDER[0].get("username");
            MessageBoardObject.MessageBoardElderName = ELDER[0].get("userNickname");
            MessageBoardObject.MessageBoardContent = msg_org;
            MessageBoardObject.MessageBoardUpdateAt = msg_date;
            MessageBoardObject.MessageBoardUpdater = msg_updater;
            MessageBoardObject.MessageBoardUpdaterObjectId = msg_updater_id;
            MessageBoardObject.isReplied = true;


            var MSG = Parse.Object.extend("MessageBoard");
            var innerQuery = new Parse.Query(MSG);
            innerQuery.equalTo("objectId", commit_parentMessageBoard_objectId);
            var ActivityParse = Parse.Object.extend("Activity");
            var query_act = new Parse.Query(ActivityParse);
            query_act.equalTo("type", "comment");
            query_act.matchesQuery('message', innerQuery);
            query.include("fromUser");
            query_act.find({
              success: (totalActivity) => {

                //console.log("comment count = " + totalActivity.length);
                for (var i = 0; i < totalActivity.length; i++) {

                  var CommentObject = new COMMENT();
                  CommentObject.comment = totalActivity[i].get("content");
                  CommentObject.bywho = totalActivity[i].get("fromUser").get("userNickname");
                  CommentObject.date = totalActivity[i].createdAt;
                  //console.log("comment by " + CommentObject.bywho + " : " + totalActivity[i].get("content"));
                  MessageBoardObject.MessageBoardCommentDetail.push(CommentObject);

                }



                this.MessageBoardArray.push(MessageBoardObject);
              },
              error: (act_error) => {
                alert("Error: " + act_error.code + " " + act_error.message);
              }
            });



          },
          error: (user_error) => {
            alert("Error: " + user_error.code + " " + user_error.message);
          }
        });



      },
      error: (msg_object, msg_error) => {
        alert("Error: " + msg_object.code + " " + msg_object.message);
      }
    });



  }

  addNewComment(MessageObject, index) {
    console.log("in addNewComment - " + index + " - " + MessageObject.MessageBoardObjectId + " : " + this.newComment + " by " + MessageObject.MessageBoardUpdaterObjectId + ":" + MessageObject.MessageBoardUpdater);



    if (this.newComment != null && this.newComment != "") {

      var ParseMessageBoard = Parse.Object.extend("MessageBoard");
      //var Parse_User = Parse.Object.extend("_User");
      var ParseActivity = Parse.Object.extend("Activity");
      var act = new ParseActivity();
      var MessageBoardref = ParseMessageBoard.createWithoutData(MessageObject.MessageBoardObjectId);
      //var User = Parse_User.createWithoutData(MessageObject.MessageBoardUpdaterObjectId);
      var User = Parse.User.createWithoutData(MessageObject.MessageBoardUpdaterObjectId);
      act.set("message", MessageBoardref);
      act.set("toUser", User);
      act.set("fromUser", User);
      act.set("content", this.newComment);
      act.set("type", "comment");

      var ACL = new Parse.ACL();
      ACL.setPublicReadAccess(true);
      ACL.setWriteAccess(MessageObject.MessageBoardUpdaterObjectId, true);
      ACL.setWriteAccess(Parse.User.current().id, true);
      act.setACL(ACL);

      act.save(null, {
        success: (reply) => {
          // Execute any logic that should take place after the object is saved.

          this.newComment = "";
          var newActivityParse = Parse.Object.extend("Activity");
          var query_new_act = new Parse.Query(newActivityParse);
          query_new_act.include("fromUser");
          query_new_act.get(reply.id, {
            success: (newReply) => {
              alert('已新增回應');
              var CommentObject = new COMMENT();
              CommentObject.comment = newReply.get("content");
              CommentObject.bywho = newReply.get("fromUser").get("userNickname");
              CommentObject.date = newReply.createdAt;
              CommentObject.bg_color = "#ffffcc";
              console.log("new comment by " + CommentObject.bywho + " : " + newReply.get("content"));
              this.MessageBoardArray[index].MessageBoardCommentDetail.push(CommentObject);


            },
            error: function (object, reply_error) {
              alert('發生錯誤：' + reply_error.message);
            }
          });


        },
        error: function (reply, error) {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          alert('新增回應失敗 錯誤：' + error.message);
        }
      });

    } else {
      alert('留言不能為空白');
    }

    /*var CommentObject = new COMMENT();
    CommentObject.comment = this.newComment;
    CommentObject.bywho = "test";
    CommentObject.date = "now";
    CommentObject.bg_color = "#ffffcc";
    console.log("new comment by " + CommentObject.bywho);
    this.MessageBoardArray[index].MessageBoardCommentDetail.push(CommentObject);
    console.dir(this.MessageBoardArray[index].MessageBoardCommentDetail)
    this.newComment = "";*/

  }

  public selected(value: any): void {
    console.log('Selected value is: ', value);
  }



}
