import { Component, OnInit } from '@angular/core';
import { imageStruct } from './imageStruct';

declare var $: any;
declare var Parse: any;
declare var window: any;

@Component({
  selector: 'app-add-mabowq-images',
  templateUrl: './add-mabowq-images.component.html',
  styleUrls: ['./add-mabowq-images.component.css']
})
export class AddMabowqImagesComponent implements OnInit {

  ParseImageList;
  ImageType = { "basic": "SOP圖", "explanation": "解釋圖", "background_board": "背景底圖" };
  WeekDayAndroid = { "1": "星期日", "2": "星期一", "3": "星期二", "4": "星期三", "5": "星期四", "6": "星期五", "7": "星期六" };
  doCheckImages = false;
  imageArrayBackground = [];
  imageArrayBasic = [];
  imageArrayExplanation = [];
  imageFile;
  isUploading = false;

  constructor() { }

  ngOnInit() {
    console.log("ngOnInit...")
    this.retrieveImagesFromParse();
  }

  check_Images() {
    this.doCheckImages = true;
  }

  /**
   * get images info from Parse and display
   */
  async retrieveImagesFromParse() {
    console.log("retrieveImagesFromParse...")
    let ImagesParse = Parse.Object.extend("MabowQ_Live_Images");
    let query = new Parse.Query(ImagesParse);
    query.find({
      success: (ImageList) => {
        if (ImageList.length == 0) {
          alert("伺服器上沒有任何圖片資訊, 請聯絡工程師");
        } else {
          //console.dir(ImageList)
          //this.ParseImageList = ImageList;

          for (var i = 0; i < ImageList.length; i++) {

            var pobj = ImageList[i]; // single Parse object
            var image = new imageStruct();
            image.objectId = pobj.id;
            image.type = pobj.get("type");
            image.typeStr = this.ImageType[pobj.get("type")];
            image.index = pobj.get("index");
            if (pobj.get("weekday") != null) {
              image.weekday = pobj.get("weekday");
              image.weekdayStr = this.WeekDayAndroid[pobj.get("weekday")];
            } else {
              // not define weekday on Parse, still fine
              image.weekday = "all";
              image.weekdayStr = "all";
            }
            if (pobj.get("image") != null) {
              image.imageUrl = pobj.get("image").url();
              image.size = "?";
            } else {
              // currently doesn't have image uploaded
              image.imageUrl = "";
            }
            //console.log("image obj...");
            //console.dir(image);

            // push data to each list
            switch (image.typeStr) {
              case this.ImageType.background_board:
                this.imageArrayBackground.push(image);
                break;
              case this.ImageType.basic:
                this.imageArrayBasic.push(image);
                break;
              case this.ImageType.explanation:
                this.imageArrayExplanation.push(image);
                break;
            }
          }
          console.dir(this.imageArrayBackground)
          console.dir(this.imageArrayBasic)
          console.dir(this.imageArrayExplanation)
        }
      },
      error: (error) => {
        alert("無法找到圖片, 請聯絡工程師\n錯誤 : " + error.code + " " + error.message);
      }
    });
  }

  delete_image(image) {
    this.isUploading = true;
    let ImagesParse = Parse.Object.extend("MabowQ_Live_Images");
    let query = new Parse.Query(ImagesParse);
    query.get(image.objectId, {
      success: (obj) => {
        obj.unset("image");
        obj.save(null, {
          success: (object) => {
            this.isUploading = false;
            alert("刪除圖片成功");
            this.refresh_data();
          },
          error: (data, error) => {
            this.isUploading = false;
            alert('刪除圖片失敗, 請再試一次\n錯誤 :' + error.message);
          }
        });
      },
      error: (model, error) => {
        this.isUploading = false;
        alert("伺服器找不到此圖片的資訊! 請聯絡工程師\n錯誤 : " + error.code + " " + error.message);
      }
    });



  }

  /**
   * handle image file input
   * @param fileInput 
   * @param image 
   */
  imagefileChangeEvent(fileInput: any, image) {
    console.log("in imagefileChangeEvent");
    console.dir(image);
    var files = fileInput.target.files || fileInput.dataTransfer.files;
    // Our file var now holds the selected file
    this.imageFile = files[0];
    console.dir(this.imageFile);
    console.log("file name=" + this.imageFile.name)

    var ext = this.imageFile.name.split('.').pop().toLowerCase();
    if ($.inArray(ext, ['png']) == -1 && $.inArray(ext, ['jpg']) == -1 && $.inArray(ext, ['jpeg']) == -1) {
      alert('檔案格式不符!需為jpg或png, 請重新選擇');
      return;
    }

    var parseFile = new Parse.File(this.imageFile.name, this.imageFile);
    var _URL = window.URL || window.webkitURL;

    this.checkImageSize(_URL.createObjectURL(this.imageFile), (result) => {
      if (!confirm("確定上傳圖片?\n圖片尺寸 = " + result)) {
        alert("已取消上傳")
        return;
      }

      this.isUploading = true;

      let ImagesParse = Parse.Object.extend("MabowQ_Live_Images");
      let query = new Parse.Query(ImagesParse);
      query.get(image.objectId, {
        success: (imgObj) => {
          console.log("find image Parse object");
          console.dir(imgObj);
          // upload image to ParseFile and then save to original ParseObject
          parseFile.save().then((imgFile) => {
            imgObj.set("image", imgFile);
            imgObj.save(null, {
              success: (object) => {
                this.isUploading = false;
                alert("更新圖片成功");
                this.refresh_data();
              },
              error: (data, error) => {
                this.isUploading = false;
                alert('更新圖片失敗, 請再試一次\n錯誤 :' + error.message);
              }
            });
          }, (error) => {
            this.isUploading = false;
            alert("圖片上傳錯誤, 請再試一次\n錯誤 : " + error.code + " " + error.message);
          });

        },
        error: (object, error) => {
          this.isUploading = false;
          alert("伺服器無法找到圖片, 請再試一次\n錯誤 : " + error.code + " " + error.message);
        }
      });
    });
  }

  /**
   * get size when each image been loaded on html
   */
  imgOnLoad(imageObj, type, index) {
    console.log("in imgOnLoad...");
    var img = new Image();
    img.onload = () => {
      console.log("size=" + img.width + "x" + img.height)
      switch (type) {
        case 0:
          this.imageArrayExplanation[index].size = img.width + "x" + img.height;
          break;
        case 1:
          this.imageArrayBasic[index].size = img.width + "x" + img.height;
          break;
        case 2:
          this.imageArrayBackground[index].size = img.width + "x" + img.height;
          break;
      }
    };
    img.src = imageObj.imageUrl;
  }

  /**
   * load and get size of input url image
   * @param url 
   * @param callback 
   */
  checkImageSize(url, callback) {
    var img = new Image();
    img.onload = function () {
      //alert(img.width + " " + img.height);
      console.log("size=" + img.width + "x" + img.height + " url=" + url)
      callback(img.width + "x" + img.height);
    };
    img.src = url;

  }

  refresh_data() {
    window.location.reload();
  }

}
