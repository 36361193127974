import { Injectable } from '@angular/core';
declare var Parse: any;

@Injectable()
export class UserProfileServiceService {
  //user_inner;
  constructor() { }

  /***
  * query useronject by usernamre
  * @param username
  * @return Promise User
  */
  queryUser(username): Promise<any> {
    console.log('in queryUser username= ' + username)
    const User = Parse.Object.extend('_User');
    const query = new Parse.Query(User);
    query.equalTo('username', username);
    //query.include('userProfileImageSmall');
    return query.first();
  }

  /**
   * queryElderDetailoBJ
   * @param userObj 
   */
  queryElderDetailoBJ(userObj): Promise<any> {
    const ElderDetailInfo = Parse.Object.extend('ElderDetailInfo');
    const _ElderDetailInfo = new ElderDetailInfo();
    const query = new Parse.Query(_ElderDetailInfo);
    query.include('User');
    query.equalTo('User', userObj);
    return query.first();
  }
  /**
     * queryUserObjectAndCreateIfConfirmNeeded
     * @param username 
     */
  queryUserObjectAndCreateIfConfirmNeeded(username: string): Promise<any> {
    console.log('in queryUserObjectAndCreateIfConfirmNeeded username= ' + username);
    if (!username) {
      return Promise.reject('need input')
    }
    return this.queryUser(username)
      .then((user) => {
        if (user) {
          return Promise.resolve(user)
        } else {
          const confirmPhone: string = window.prompt(username + ' 不存在將會幫您新增帳號，請在輸入一次您要新增的帳號');
          if (username === confirmPhone) {
            return this.createInputUser(username, true)
          } else {
            throw new Error("user deny create user");
          }
        }
      }).then((userobj) => {
        console.log('in save function /createInputUser success');
        return Promise.resolve(userobj);
      }).catch((error) => {
        console.log('in save function  no user callback error= ' + error);
        return Promise.reject(error);
      });
  }

  /**
     * create user by input data 
     * @param username 
     * @param isNeedUserConfirm 
     */
  createInputUser(username: string, isNeedUserConfirm: boolean): Promise<any> {
    console.log('in createInputUser function isNeedUserConfirm= ' + isNeedUserConfirm);
    return Parse.Cloud.run('SignUpUser',
      { username: username, isNeedUserConfirm: isNeedUserConfirm })
  }
}
