
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { liveContent_KTV } from './careingModule/careing.livecontent.ktv'
import { Subject ,  Observable } from 'rxjs';
import { ElderData } from './select-elder/elder_data';

declare var PUBNUB: any;
declare var Parse: any;


/**
 * 1. checkSelfIscallInServiceStateAvalable  -> check is avalable  -> if true request queue ;if false request user to login or not 
 * 
 * 2. 
 * 
 */


@Injectable()
export class BackGroundCommunicationService {

    router: Router;
    pubnub;
    liveContentArray: Array<liveContent_KTV> = [];
    liveArray = new Subject<liveContent_KTV[]>();

    state = {
        isCanBeACallinGiver: false,
        isSelfCareAvalible: false, // 包含是否有queue
        //isSelfputIntoAgentWaitQueue: false,
        lockCallinState: false,
        isLiveQueryonline: false
    }

    opentokData = {
        selfOpentokSessionid: "",
        selfOpentokToken: "",
        selfChannelName: '',// pubnub channel
        connectionObject: {
            callerObj: { ObjId: "", sessionId: "", opentokToken: "" },
            calleeObj: { ObjId: "", sessionId: "", opentokToken: "" },
            hosterOpentokSessionId: "",
            hosterOpentokToken: "",
            amIHoster: false,
        },
        //elderUserObjectWithoutData: ""


    }

    constructor(_router: Router) {
        this.router = _router;
        console.log("in BackGroundCommunicationService constructor")
        //this.initTheClientCall();
        //this.initTheliveQuery();


    }


    /**
     * createliveSpecificUserContentArray
     * @param ObjecdIDArray 
     * @param liveContectOject 
     */
    createliveSpecificUserContentArray(liveContectOject, _object) {
        console.log('createliveSpecificUserContentArray');
        try {
            console.dir(liveContectOject)
            var liveContentKTVInfo = new liveContent_KTV();
            liveContentKTVInfo.user_object_id = Parse.User.current().id;

            liveContentKTVInfo.song_url = liveContectOject[Parse.User.current().id].KTV.song_url;
            liveContentKTVInfo.song_name = liveContectOject[Parse.User.current().id].KTV.song_name;
            liveContentKTVInfo.song_singer = "";
            liveContentKTVInfo.song_status = liveContectOject[Parse.User.current().id].KTV.status;
            liveContentKTVInfo.song_timestamp = liveContectOject[Parse.User.current().id].KTV.timestamp;
            liveContentKTVInfo.messengerObject = _object;
            liveContentKTVInfo.newUpdated=true;
            this.liveContentArray.push(liveContentKTVInfo);

            this.liveArray.next(this.liveContentArray);
        } catch (e) {
            console.error("liveArray catch 1 " + e)
        }
    }


    /**
     * initTheliveQuery
     */
    initTheliveQuery(channel: string, callback) {
        if (this.state.isLiveQueryonline) {
            if (callback) {
                callback(false);
            }
            return;
        }
        Parse.liveQueryServerURL = 'wss://live.mabow.com.tw'
        var query = new Parse.Query("Messengering");
        query.equalTo("channel", channel);
        let subscription = query.subscribe();
        subscription.on('open', () => {
            console.log('subscription opened');
            if (callback) {
                callback(true);
            }
        });
        subscription.on('create', (object) => {
            console.log('object created');
            try {
                const liveContectOject = JSON.parse(object.get("liveContent"));
                //const ObjecdIDArray = Object.keys(liveContectOject);
                this.createliveSpecificUserContentArray(liveContectOject, object)
            } catch (e) {
                console.error("liveArray catch 1 " + e)
            }
        });
        subscription.on('update', (object) => {
            //var liveContectOject = {};
            console.log('receive liveqiery object updated');
            const liveContentStr = object.get("liveContent");
            try {
                //console.dir(liveContectOject);
                const liveContectOject = JSON.parse(liveContentStr);
                
                for (var j = 0; j < this.liveContentArray.length; j++) {
                    if (Parse.User.current().id == this.liveContentArray[j].user_object_id) {
                        this.liveContentArray[j].song_url = liveContectOject[Parse.User.current().id].KTV.song_url;
                        this.liveContentArray[j].song_name = liveContectOject[Parse.User.current().id].KTV.song_name;
                        this.liveContentArray[j].song_singer = "";
                        this.liveContentArray[j].song_status = liveContectOject[Parse.User.current().id].KTV.status;
                        this.liveContentArray[j].song_timestamp = liveContectOject[Parse.User.current().id].KTV.timestamp;
                        this.liveContentArray[j].messengerObject = object;
                        this.liveContentArray[j].messengerObject.newUpdated=true;
                        console.log("liveArray.next update......")
                        this.liveArray.next(this.liveContentArray)
                    }else{
                        this.liveContentArray[j].messengerObject.newUpdated=false;
                        
                    }
                }

            } catch (e) {
                console.error("liveArray crash " + e)
            }
        });

        Parse.LiveQuery.on('error', (error) => {
            console.error(error);
            this.state.isLiveQueryonline = false;
            alert("Parse.LiveQuery.on('error')")

        });

        Parse.LiveQuery.on('close', () => {
            console.error('socket connection closed');
            this.state.isLiveQueryonline = false;
        });

        Parse.LiveQuery.on('open', () => {
            this.state.isLiveQueryonline = true;
            console.warn('socket connection established');
        });

    }

    getliveContentArray(): Observable<Array<liveContent_KTV>> {
        return this.liveArray.asObservable();
    }

    initTheClientCall() {
        if (Parse.User.current() == null) {
            console.error("in initTheClientCall/Parse.User.current()==null so return ")

            return;
        }
        console.log("in initTheClientCall")
        Parse.Cloud.run('initTheCallClient', { /*clientRole: this.role*/ }, {
            success: (connectedData) => {
                console.log("in initTheClientCall success")
                console.dir(connectedData);
                this.opentokData.selfOpentokSessionid = connectedData.sessionId;
                this.opentokData.selfChannelName = connectedData.channelName;
                console.log("my session = " + connectedData.sessionId)
                //this.appservice.opentokData.opentokTokenconnectedData.token;
                this.initpubnub(connectedData.sessionId);
                // obtained secret string
            },
            error: function (error) {
                console.error("'initTheCallClient error")

                // error
            }
        });
    }

    fullVideoChatConnectionInfo(message, amIhosted: boolean) {
        console.log("in fullVideoChatConnectionInfo()/ amIhosted= " + amIhosted);
        this.opentokData.connectionObject.amIHoster = amIhosted;
        this.opentokData.connectionObject.callerObj = message.callerObj;
        this.opentokData.connectionObject.calleeObj = message.calleeObj;
        if (amIhosted) {
            this.opentokData.connectionObject.hosterOpentokSessionId = message.calleeObj.sessionId;
            this.opentokData.connectionObject.hosterOpentokToken = message.calleeObj.opentokToken;
        } else {
            this.opentokData.connectionObject.hosterOpentokSessionId = message.callerObj.sessionId;
            this.opentokData.connectionObject.hosterOpentokToken = message.callerObj.opentokToken;

        }

    }

    routeToUrl(url: string) {
        console.log("in routeTourl =" + url);
        this.router.navigateByUrl(url);


    }



    /**
     * 
     * ========================pubnub  專用=======================================
     */


    /**
     * setSelfPubnubState 只有設定remote state 狀態，不改local flag , flag 由 eventlistener 來改
     * only make pubnub state iscallInCareAvalible = input 
     * waitqueue avalible is not set
     * @params {boolean} iscallInCareAvalible
     * @params {function} callback?
     */
    setSelfPubnubState(iscallInCareAvalible: boolean, callback?) {
        console.log("setSelfPubnubState iscallInCareAvalible= " + iscallInCareAvalible + " this.lockCallinState= " + this.state.lockCallinState);
        //this.enableCallinStateFlag(iscallInCareAvalible);
        if (this.state.lockCallinState) {
            return;
        }
        this.pubnub.state({
            channel: this.opentokData.selfChannelName,
            uuid: Parse.User.current().id,
            state: {
                "iscallInCareAvalible": iscallInCareAvalible
            },
            callback: (m) => {
                console.log(JSON.stringify(m));
                if (callback) callback();
            }
        });

    }

    /**
     * check is avalable  -> if true request queue ;if false request user to login or not 
     */
    // 確保第一次跟 remote state sync
    checkSelfIscallInServiceStateAvalable(_callback?) {
        console.dir(this.opentokData);
        console.log("in checkSelfIscallInServiceStateAvalable()")
        if (!this.state.isCanBeACallinGiver) {
            return;
        }
        console.log("in checkSelfIscallInServiceStateAvalable " + this.opentokData.selfChannelName);
        this.pubnub.state({
            channel: this.opentokData.selfChannelName,
            callback: (m) => {
                if (_callback) _callback(true);
                console.log("in checkSelfIscallInServiceStateAvalable  check success callback")
                // INIT THE FLAG
                this.state.isSelfCareAvalible = m.iscallInCareAvalible || false;

                if (this.state.isSelfCareAvalible == true && this.state.isCanBeACallinGiver == true) {
                    // 避免 state eventcallback 第一次沒有回傳。 
                    this.requestAgendWaitQuene(() => {
                        console.log("in requestAgendWaitQuene  success");

                    }, () => {
                        console.log("in requestAgendWaitQuene fail")

                    });
                } else if (this.state.isSelfCareAvalible == false && this.state.isCanBeACallinGiver == true) {
                    var istrue = confirm("您是否要打開客戶 call-in 功能");
                    if (istrue) {
                        // set flag and state
                        this.setSelfPubnubState(true);
                    } else {

                    }
                }



            },
            error: function (m) {
                if (_callback) _callback(false)

                console.log(m)
            }
        });


    }


    /***
     * unsubscribePubnub
     */
    unsubscribePubnub() {
        this.pubnub.unsubscribe({
            channel: this.opentokData.selfChannelName,
        });
    }

    /***
     *  initpubnub
     */
    initpubnub(auth) {
        console.log("in initpubnub  ")
        this.pubnub = PUBNUB.init({
            publish_key: 'pub-c-c7436ad6-f37f-4e1d-b838-79a21f46e9a2',
            subscribe_key: 'sub-c-1b93e64c-a8bf-11e6-be20-0619f8945a4f',
            auth_key: auth,
            ssl: true,
            error: function (error) {
                console.error('in initpubnub Error:', error);
            }
        })
        this.pubnub.set_uuid(this.opentokData.selfChannelName);

        this.subscribeChannel(this.opentokData.selfChannelName);

    }

    /***
     * subscribeChannel
     */
    subscribeChannel(channel: string) {
        console.log("in subscribeChannel channel= " + channel);
        this.pubnub.subscribe({
            channel: channel,
            message: (m) => {

                this.receiveMessageCallback(m);
            },
            state: {
                iscallInCareAvalible: false,
            },
            presence: (m) => {
                console.log("in presence" + JSON.stringify(m));

                if (m.action === "state-change" && m.uuid === this.opentokData.selfChannelName) {
                    console.log("in presence/ self state change");


                    // 如果 care avalable prescence 是真，將會嘗試 put into queue 
                    if (m.data.iscallInCareAvalible && this.state.isCanBeACallinGiver) {

                        this.requestAgendWaitQuene(() => {
                            console.log("in requestAgendWaitQuene  success");
                        }, () => {
                            console.log("in requestAgendWaitQuene fail")
                        })
                    } else if (!m.data.iscallInCareAvalible) {
                        this.enableCallinStateFlag(m.data.iscallInCareAvalible);
                    }
                }


            },
            error: (error) => {
                // Handle error here
                console.log(JSON.stringify(error));
            }, connect: () => {
                console.log("in subscribeChannel Connected");
                //setTimeout(() => { this.checkSelfIsAvalableAndSaveToLocalFlag(); }, 1000);
            },
            disconnect: () => {
                console.log("in subscribeChannel Disconnected");
                this.checkSelfIscallInServiceStateAvalable();

            },
            reconnect: () => {
                this.checkSelfIscallInServiceStateAvalable();
                console.log("in subscribeChannel Reconnected")
            },
        });


    }

    /***
     * receiveMessageCallback
     */
    receiveMessageCallback(message) {
        console.log("in receiveMessageCallback / message= " + message.actionCode);
        console.dir(message)
        switch (message.actionCode) {
            case 102:

                console.log("in receiveMessageCallback()/ love call call in");
                if (!this.state.isSelfCareAvalible) {
                    console.warn("in receiveMessageCallback()/ love call call in/ service is already ing");
                    break;
                }
                // disable first 
                this.setSelfPubnubState(false);
                // display

                // record remote userdata (only object without data)
                //var elderUser = new Parse.Object("_User");
                //elderUser.id = message.callerObj.ObjId;
                //this.opentokData.elderUserObjectWithoutData = elderUser;

                var istrue = confirm(message.callerObj.ObjId + " 來電");
                if (istrue) {
                    // set flag and state
                    this.setSelfPubnubState(false);
                    //this.state.isSelfCareAvalible = false;// 不管有沒有成功都強制 在 client not avalible

                    // route to videochat area
                    this.fullVideoChatConnectionInfo(message, false);
                    this.routeToUrl("/toVideoChat");

                } else {
                    this.setSelfPubnubState(true);
                    //this.state.isSelfCareAvalible = true;

                }
                break;


        }


    }

    /**
     * 
     * ======================================= call in 專用 =======================================
     * 
     */

    enableCallinStateFlag(isEnable: boolean) {

        console.log("in enableCallinStateFlag() isEnable= " + isEnable);
        this.state.isSelfCareAvalible = isEnable;
        //this.state.isSelfputIntoAgentWaitQueue = true;
    }

    enableCallinFunction(isEable: boolean) {
        console.log("in enableCallinFunction isEable= " + isEable);
        this.state.isCanBeACallinGiver = isEable;
        this.checkSelfIscallInServiceStateAvalable();

    }

    requestAgendWaitQuene(scb, fcb) {
        if (!this.state.isCanBeACallinGiver) {
            fcb("in requestAgendWaitQuene/ permison deny/ this.state.isCanBeACallinGiver==false so return; ")
            return;
        }
        var input = { "sessionId": this.opentokData.selfOpentokSessionid, "agentAccessToken": "mabow" }
        console.log("in requestAgendWaitQuene");
        //var opentoksessionId = request.params.sessionId;
        //var agentAccessToken = request.params.agentAccessToken;

        Parse.Cloud.run('putAgentIntoQueue', { sessionId: this.opentokData.selfOpentokSessionid, agentAccessToken: "mabow" }, {
            success: () => {
                this.enableCallinStateFlag(true);
                scb();
            },
            error: function (error) {
                this.enableCallinStateFlag(false);

                fcb(error);
                // 
            }
        });



    }
}