import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { prepareData } from './prepare_data'
import { GlobalService } from '../global.service';
import { ElderData } from '../select-elder/elder_data';
import { CanDeactivate } from '@angular/router';

import { _list } from './List'; // input list
import { ComminnicatonParameterDefine } from '../comminnicatonParameterDefine';


declare var Parse: any;
declare var $: any;




@Component({
  selector: 'prepareCare',
  templateUrl: 'preparecare.component.html',
  styleUrls: ['preparecare.component.css']
})

export class PreCareComponent extends ParentClass {
  filledData: prepareData;
  form: FormGroup;
  formFeedback: FormGroup;
  isLoading: boolean = false;
  focusParseUserData: ElderData;
  inputLists;
  rules: any[];
  currentdate = new Date();


  //record info data object
  CareRecordInfo = Parse.Object.extend("CareRecordInfo");
  CareRecordInfoObject;

  info = {
    //internetSpeed1: "",
    //internetSpeed2: "",
    //internetSpeed3: "",
    communicationTool: "",
    purpose: -1, // -1 unknow; 0 care ; 1 contact ;// map to compPurpose
    comPC: ""
  };
  comTools: Array<String>
  compPurpose: Array<String>
  comPC: Array<String>

  isSavingExecute: boolean = false;

  constructor(
    router: Router, private fb: FormBuilder, private _globalservice: GlobalService, private _comminnicatonParameterDefine: ComminnicatonParameterDefine
  ) {
    super(router);
    console.log("in constructor");
    this.focusParseUserData = _globalservice.focusParseUserObject;
    this.inputLists = _list;
    this.compPurpose = _comminnicatonParameterDefine.compPurpose;
    if (this.focusParseUserData.pmi)
      this.comTools = _comminnicatonParameterDefine.comTools_zoom;
    else
      this.comTools = _comminnicatonParameterDefine.comTools;
    this.comPC = _comminnicatonParameterDefine.comPC;

    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }

  /**init */
  ngOnInit(): void {
    console.log("in ngOnInit");
    // check the data
    if (!this.checkIsParseUserdataCorrect()) {
      this.router.navigateByUrl('/selectElder');
      return;
    }
    /**formbuild */
    this.fillControlGroup();

    // query is existed data
    this.createCareRecordinfoObject();

    console.dir(this.focusParseUserData)



  }


  createCareRecordinfoObject() {
    console.log("in createCareRecordinfoObject()");
    //this.CareRecordInfoObject = new this.CareRecordInfo();
    // input elder info
    //this.CareRecordInfoObject.set("careGiver", Parse.User.current());
    //this.CareRecordInfoObject.set("elder", this.focusParseUserData.fullUserObject);
    //this.CareRecordInfoObject.set("startDate", this.currentdate);
    this.CareRecordInfoObject = new this.CareRecordInfo();
    // input elder info
    this.CareRecordInfoObject.set("careGiver", Parse.User.current());
    this.CareRecordInfoObject.set("elder", this.focusParseUserData.fullUserObject);
    this.CareRecordInfoObject.set("elderDetailInfo", this.focusParseUserData.elderDetailInfoObject);
    this.CareRecordInfoObject.set("starttime", this.currentdate);
    /*


var careRecordInfoTask = Parse.Object.extend("CareRecordInfo");
var query = new Parse.Query(careRecordInfoTask);
query.equalTo("elder", this.focusParseUserData.fullUserObject);
query.equalTo("careGiver", Parse.User.current());
query.notEqualTo("isThisClassFillComplete", true);
query.descending("createdAt");

query.find({
success: (careRecordInfoRemotrObjectes) => {
  if (careRecordInfoRemotrObjectes.length > 0) {
    console.info("in queryCareRecordinfoObject()/ query success/ with not finish data");
    var careRecordInfoRemotrObjecte = careRecordInfoRemotrObjectes[0];

    this.CareRecordInfoObject = careRecordInfoRemotrObjecte;
    this.syncParseLocalObjectToInfoData();
    this.fillInitialValue();
    //this.fillControlGroup();
  } else {
    console.log("in queryCareRecordinfoObject()/ query success/ no not finish careinfoobject");
    this.CareRecordInfoObject = new this.CareRecordInfo();
    // input elder info
    this.CareRecordInfoObject.set("careGiver", Parse.User.current());
    this.CareRecordInfoObject.set("elder", this.focusParseUserData.fullUserObject);
    this.CareRecordInfoObject.set("starttime", this.currentdate);
  }
  this.isLoading = false;

}, error: (object, error) => {
  console.error("in queryCareRecordinfoObject  query  exception " + error + " : " + error.message);
  alert("請重新整理網頁");
}
});*/
  }



  fillControlGroup() {

    this.form = this.fb.group({
      idControl1: ["", Validators.compose([Validators.required, Validators.minLength(4)])],
      idControl2: ["", Validators.compose([Validators.required, Validators.minLength(5)])],
      idControl3: ["", Validators.compose([Validators.required, Validators.minLength(5)])],

    });

  }

  /*fillInitialValue() {
    this.form = this.fb.group({
      idControl1: [this.info.internetSpeed1, Validators.compose([Validators.required, Validators.minLength(4)])],
      idControl2: [this.info.internetSpeed2, Validators.compose([Validators.required, Validators.minLength(5)])],
      idControl3: [this.info.internetSpeed3, Validators.compose([Validators.required, Validators.minLength(5)])],

    });



  }*/



  checkIsParseUserdataCorrect(): boolean {
    console.log("in checkIsParseUserdataCorrect");
    if (!this.focusParseUserData) {
      return false;
    }
    //console.dir(this.focusParseUserData);
    console.log("in checkIsParseUserdataCorrect/ mabowid= " + this.focusParseUserData.mabowid);
    return true;
  }


  /**
   * rederict to addMessageBoard
   */
  /*
  goAddMessage() {
    console.log("in go function , and go to addMessageBoard id page =");

    this.router.navigateByUrl('/addMessageBoard');
  }*/

  goCalling() {
    console.log("in goCalling function ,goCalling");
    //console.log("in goCalling is form vail= " + this.form.valid);
    if (this.info.comPC == "" || this.info.communicationTool == "" || this.info.purpose == -1) {
      alert("請確實填入資料")
      return;
    }
    this._globalservice.CareRecordInfoObject = this.CareRecordInfoObject;
    this._globalservice.communicationTool = this.info.communicationTool;

    this.router.navigateByUrl('/caring');

    /* this.saveCareRecordObjectInfo(
       () => { // success callback
         console.log("in go saveCareRecordObjectInfo success");
         this._globalservice.CareRecordInfoObject = this.CareRecordInfoObject;
         this.router.navigateByUrl('/caring');
       },
       () => { // fail callback
         alert("出現錯誤，請再按一次按鈕");
       });*/

  }

  inputChangeEvent(inputEvent: any) {
    console.log("in inputChangeEvent ");
    this.syncInfoDataWithParseLocalObject();
    //console.dir(inputEvent)
    //console.dir(CareRecordInfoObject);

  }

  syncInfoDataWithParseLocalObject() {
    console.log("in inputChangeEvent ");

    $.each(this.info, (key, value) => {
      //display the key and value pair
      this.CareRecordInfoObject.set(key, value);
    });
    //console.dir(this.CareRecordInfoObject);
    //console.dir(this.info);
  }

  syncParseLocalObjectToInfoData() {
    console.log("in syncParseLocalObjectToInfoData ");

    $.each(this.info, (key, value) => {
      //display the key and value pair

      this.info[key] = this.CareRecordInfoObject.get(key);
    });
    console.dir(this.info);
  }

  saveCareRecordObjectInfo(scb?, fcb?) {
    console.log("in saveCareRecordObjectInfo()");

    this.isSavingExecute = true;
    this.CareRecordInfoObject.save(null, {
      success: (CareRecordInfoObject) => {
        // Execute any logic that should take place after the object is saved.
        console.log('in saveCareRecordObjectInfo success');
        this.isSavingExecute = false;
        console.dir(CareRecordInfoObject)
        scb();

      },
      error: (CareRecordInfoObject, error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        console.error('in saveCareRecordObjectInfo error');
        this.isSavingExecute = false;

      }
    });
  }

  onDropChange(data: string) {
    console.log("inonDropChange " + data);

    this.info.communicationTool = data;
    this.syncInfoDataWithParseLocalObject();

  }
  onPurposeDropChange(data: string) {
    console.log("onPurposeDropChange " + data);
    var inputdata = data.replace(/\s/g, '');
    let index = this.compPurpose.indexOf(inputdata);
    console.log("in onPurposeDropChange/ index= " + index);
    if (index == -1) {
      alert("index=-1 error");
      return;
    }
    this.info.purpose = index;
    this.syncInfoDataWithParseLocalObject();

  }

  onPCDropChange(data: string) {
    console.log("onPCDropChange "  + data);

    this.info.comPC = data;
    this.syncInfoDataWithParseLocalObject();

  }


  /*go() {
    console.log("in go is form vail= " + this.form.valid);
    if (!this.form.valid || this.info.communicationTool == "") {
      alert("請確實填入資料")
      return;
    }
    this.saveCareRecordObjectInfo(
      () => { // success callback
        console.log("in go success")
      },
      () => { // fail callback
        alert("出現錯誤，請再按一次按鈕");
      });

  }*/



}

