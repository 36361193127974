import { Component, OnInit } from '@angular/core';
declare var Parse: any;
declare var $: any;
import { ParentClass } from '../ParentClass';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partner-admin',
  templateUrl: './partner-admin.component.html',
  styleUrls: ['./partner-admin.component.css']
})

export class PartnerAdminComponent extends ParentClass implements OnInit {

  constructor(router: Router, private activatedRoute: ActivatedRoute) {
    super(router);
  }
  isLoading: boolean = false;
  parentUserid: string;
  parentUseridCheck: string;
  gateNick: string;
  gateUserprefix = "001";
  serial: string;
  activePartnerId: string;  // PBJECTID
  activePartnerID: string;  // BEHAVE

  beAddrdFriebdId: string;
  beAddrdFriebdIdidCheck: String
  salesPlan = [{
    salesPlanId: "",
    salesPlanName: ""
  }]

  ngOnInit() {
    // get url status
    this.init();
    this.setFormVail();
    //PhoneNumberFormat.

  }

  showLoading(isOn: boolean) {
    this.isLoading = isOn;
  }

  private init() {
    this.showLoading(true);
    // serial
    this.activatedRoute.params.subscribe(params => {
      this.serial = params['serial']; // (+) converts string 'id' to a number
      //console.log("serial= " + this.serial);
      var regexp = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
      var mac_address = this.serial;

      if (mac_address.split("_").length == 1) {
      } else if (mac_address.split("_").length == 2) {
        mac_address = mac_address.split("_")[1];
      }

      if (!regexp.test(mac_address)) {
        this.errorCallback("開通序號錯誤，請關閉網頁後重新打開網頁");
      }
    });

    // partner id
    var Partner = Parse.Object.extend("partner");
    var query = new Parse.Query(Partner);

    var salesPlan = Parse.Object.extend("salesPlan");
    var query2 = new Parse.Query(salesPlan);


    Parse.Promise.when(query.first(), query2.first()).then((r1, r2) => {

      this.activePartnerId = r1.id;
      this.activePartnerID = r1.get("ID");
      this.salesPlan[0].salesPlanId = r2.id;
      this.salesPlan[0].salesPlanName = r2.get("name");

      //console.log(this.activePartnerId)
      //console.dir(this.salesPlan[0])
      this.showLoading(false);

    }).catch((e) => {
      console.error(e)

      this.errorCallback("初始錯誤，請重新啟動網頁");
    });
  }

  parentUserObj;
  getetUserObj
  /**
   * linkDevice
   * submit callback
   */
  linkDevice() {
    this.showLoading(true);

    const isVail: boolean = $('.ui.form').form('is valid')
    console.log("linkDevice is vail= " + isVail);
    if (!isVail[0]) {
      alert("格式錯誤，請檢查");
      this.showLoading(false);
      return;
    }

    this.queryUserObjectAndCreateIfConfirmNeeded(this.parentUserid, this.gateNick)
      .then((_parentUserObj) => {
        if (_parentUserObj)
          this.parentUserObj = _parentUserObj;
        else {
          return Parse.Promise.error("parentuser craete fail");
        }
        console.log(this.parentUserid.length)
        console.log(this.parentUserid.substring(0, 2))
        // TODO FIX GLOBAL
        if (this.parentUserid.length == 10 && this.parentUserid.substring(0, 2) == "09")
          return this.queryUserObjectAndCreateIfConfirmNeeded(this.parentUserid + this.gateUserprefix, this.gateNick + "的任意門")

        return Parse.Promise.as(this.parentUserObj)

      }).then((_getetUserObj) => {
        if (_getetUserObj)
          this.getetUserObj = _getetUserObj;
        else {
          return Parse.Promise.error("getetUserObj craete fail");
        }
        console.log("parentid=" + this.parentUserObj.id + " this.getetUserObj= " + this.getetUserObj.id)

        if (this.parentUserid.length != 10 || (this.parentUserid.length == 10 && this.parentUserid.substring(0, 2) != "09"))
          console.error("link with landphone");

        // device guard 
        const DeviceInfo = Parse.Object.extend("DeviceInfo");
        const query = new Parse.Query(DeviceInfo);
        query.equalTo("serialId", this.serial);
        query.include("owner");
        return query.first()
      }).then((deviceinfo) => {
        if (!deviceinfo)
          return Parse.Promise.error("no device existed");

        if (deviceinfo.get("owner")) {
          if (!confirm("這台機器的擁有者是: " + deviceinfo.get("owner").getUsername() + " 確定要更換？"))
            return Parse.Promise.error("user deny");
        }


        return this.linkDevicebyPartner(this.activePartnerId, this.parentUserObj.id, this.getetUserObj.id, this.salesPlan[0].salesPlanId, this.activePartnerID)

      }).catch((e) => {
        console.error("link error " + e)
        this.errorCallback("開通失敗，請再嘗試一次");

      })

  }

  /**
   * linkDeviceWithFriend()
   */
  addedUserObj;
  linkDeviceWithFriend() {
    this.showLoading(true);
    const isVail: boolean = $('.ui.form').form('is valid')
    console.log("linkDeviceWithFriend is vail= " + isVail);
    if (!isVail[1]) {
      alert("格式錯誤，請檢查");
      this.showLoading(false);
      return;
    }
    this.queryUserObjectAndCreateIfConfirmNeeded(this.beAddrdFriebdId, null)
      .then((_addedUserObj) => {
        if (_addedUserObj)
          this.addedUserObj = _addedUserObj;
        else {
          return Parse.Promise.error("addedUserObj craete fail");
        }
        return this.linkFriendWithDevicebyPartner()
      }).catch((e) => {
        console.error("link error " + e)
        this.errorCallback("開通失敗，請再嘗試一次");

      })

  }

  /**
   * linkDevicebyPartner
   * @param serialID 
   * @param activePartnerId // object id
   * @param parentUserObjid 
   * @param gateUserObjId 
   * @param salesPlanId 
   * @param activePartnerID // behave id
   */
  private linkFriendWithDevicebyPartner() {

    if (!this.addedUserObj || !this.addedUserObj.id || !this.beAddrdFriebdId || (this.addedUserObj.getUsername() != this.beAddrdFriebdId))
      return Parse.Promise.error("Invaild parameter");

    if (!confirm("確定新增好友 " + this.beAddrdFriebdId + " 到這台機器？"))
      return Parse.Promise.error("user deny");

    const input = {
      deviceSerial: this.serial,
      fromUserId: this.addedUserObj.id,
      fromUserUsername: this.beAddrdFriebdId,
    }

    return Parse.Cloud.run('addFriendWithDeviceInfoAndbyPartner', input)
      .then(() => {
        this.successCallback();
      }).catch((e) => {
        console.error(e)
        this.errorCallback("加好友錯誤")
      });

  }
  /**
   * setFormVail
   */
  private setFormVail() {
    if (!this.serial) {
      alert("mac 號不存在")
    }
    $('.ui.form')
      .form({
        fields: {
          gateNick: ['minLength[1]', 'maxLength[10]'],
          parentid: ['minLength[7]', 'maxLength[10]', 'number'],
          parentidCheck: ['minLength[7]', 'maxLength[10]', 'match[parentid]', 'number'],
          beAddrdFriebdId: ['minLength[7]', 'maxLength[10]', 'number'],
          beAddrdFriebdIdidCheck: ['minLength[7]', 'maxLength[10]', 'match[beAddrdFriebdId]', 'number'],
        }
      });

  }


  /**
   * queryUserObjectAndCreateIfConfirmNeeded
   * @param username 
   */
  private queryUserObjectAndCreateIfConfirmNeeded(username: string, nick: string) {
    console.log("in queryUserObjectAndCreateIfConfirmNeeded username= " + username);
    if (!username) {
      return Parse.Promise.error("Invaild parameter");
    }
    return this.queryUser(username)
      .then((user) => {
        if (user) {
          console.log("in queryUserObjectAndCreateIfConfirmNeeded already existed");
          return Parse.Promise.as(user)
        } else {
          console.log("in queryUserObjectAndCreateIfConfirmNeeded create user");
          return this.createInputUserWithNick(username, nick, true)
        }

      }).catch((e) => {
        console.error("queryUserObjectAndCreateIfConfirmNeeded " + e)
        return Parse.Promise.error("error");
      });
  }
  /**
   * linkDevicebyPartner
   * @param serialID 
   * @param activePartnerId // object id
   * @param parentUserObjid 
   * @param gateUserObjId 
   * @param salesPlanId 
   * @param activePartnerID // behave id
   */
  private linkDevicebyPartner(activePartnerId: string, parentUserObjid: string, gateUserObjId: string, salesPlanId: string, activePartnerID: string) {

    if (!gateUserObjId || !this.serial || !parentUserObjid || !activePartnerId || !activePartnerID) {
      return Parse.Promise.error("Invaild parameter");
    }

    const input = {
      serialID: this.serial,
      activePartnerId: activePartnerId,
      parentUserObjid: parentUserObjid,
      gateUserObjId: gateUserObjId,
      salesPlanId: salesPlanId,
      activepartnerBehaveID: activePartnerID,



    }
    Parse.Cloud.run('gateUserclientLoginOrSignupIfNeededAndLinkDeviceByPartner', input)
      .then(
        (result) => {
          console.log("gateUserclientLoginOrSignupIfNeededAndLinkDeviceByPartner success");
          this.successCallback();
        }, (error) => {
          console.error("gateUserclientLoginOrSignupIfNeededAndLinkDeviceByPartner error " + error);
          this.errorCallback("開通失敗，請再按一次");
        });

  }



  private successCallback() {
    this.showLoading(false);
    alert("成功");

  }


  /**
   * errorCallback
   * @param reason 
   */
  private errorCallback(reason: string) {
    this.showLoading(false)
    alert("遇到錯誤 代碼：" + reason)
  }
}
