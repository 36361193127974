import { Component } from '@angular/core';
import { Menu } from './menu';
import { GlobalService } from './global.service';
import { Router } from '@angular/router';
import { BackGroundCommunicationService } from './backgroundcommunication.service';
import { ParentClass } from './ParentClass';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

declare var Parse: any;
declare var $: any;
declare var ParseException: any;





@Component({
  selector: 'my-app',
  moduleId: "AppComponent",
  template: `
 <!--menu-->
   <div class="ui stackable teal inverted menu">
       <a  class="item" (click)="sidebarclick()"><i class="settings icon big"></i></a>
       <div class="item" >
             <h3 class="ui   header" style="color:white;"> <img  class="ui mini circular  image" [src]="_appservice.profile_image" [routerLink]="['profile']" routerLinkActive="active" ><div class="content">{{username}}</div></h3>
          </div>
      <a  class="item" *ngFor="let item of menuItems"  routerLinkActive="active" [routerLink]="item.url"> <i [class]="item.icon"></i>{{item?.title}}</a>
      
      <a  class="item " *ngIf=" backGroundCommunicationService.state.isCanBeACallinGiver&&backGroundCommunicationService.state.isSelfCareAvalible" icon="checkmark big icon"  (click)="makeCallAccceptCallin(false)"> 狀態：可以 call-in</a>
      <a  class="item" *ngIf="backGroundCommunicationService.state.isCanBeACallinGiver&&(!backGroundCommunicationService.state.isSelfCareAvalible) " icon="checkmark big icon" icon="remove big icon " (click)="makeCallAccceptCallin(true)">狀態：無法接收 call-in</a>
     
  </div>
  <!-- end menu-->



<!--setting menu -->
 <div class="ui left demo vertical inverted sidebar labeled icon menu">             
 <a class="item"  *ngFor="let item of settingMenuItems;let i = index "  (click)="goSettingMenu(i)"  ><i [ngClass]="item.icon"></i>{{item?.title}}</a>  
 </div>
 

  <!-- router display area-->
   <router-outlet></router-outlet>
  <!--end area -->
  <!--sm-loader [complete]="!isLoading" class="inverted" text="Loading..."></sm-loader-->
  <div class="ui active inverted dimmer" *ngIf="isLoading">
  <div class="ui medium text loader">Loading...</div>
  </div>

  <div class="ui basic modal">
  <h1 class="ui icon header">
    <i class="help massive icon"></i>
    {{_appservice.customAlertmeta.head}}
  </h1>
  <div class="content">
    <p align="center"> {{_appservice.customAlertmeta.content}}</p>
  </div>
  <div class="actions">
    <div class="ui red basic cancel inverted button"  *ngIf="_appservice.customAlertmeta.isConfirm">
      <i class="remove icon"></i>
      No
    </div>
    <div class="ui green ok inverted button">
      <i class="checkmark icon"></i>
      Yes
    </div>
  </div>
</div>


<div class="ui normal modal">
  <i class="close icon"></i>
  <div class="header">
   <h1 class="ui header">{{_appservice.customAlertmeta.head2}}</h1>
  </div>
  <div class="image content">
  <i class="info  olive huge icon"  *ngIf="_appservice.customAlertmeta.type2==_appservice.customAlertmeta.TYPEINFO"></i>
  <i class="remove red huge icon"  *ngIf="_appservice.customAlertmeta.type2==_appservice.customAlertmeta.TYPEERROR"></i>
  <i class="warning  yellow huge icon"  *ngIf="_appservice.customAlertmeta.type2==_appservice.customAlertmeta.TYPEWARN"></i>
  <i class="checkmark green   huge icon"  *ngIf="_appservice.customAlertmeta.type2==_appservice.customAlertmeta.TYPESUCCESS"></i>

  
    <div class="description">
    <h2 class="ui header">{{_appservice.customAlertmeta.content2}}</h2>
    </div>
  </div>
  <div class="actions">
    <div class="ui black deny button" *ngIf="_appservice.customAlertmeta.isConfirm2">
      Nope
    </div>
    <div class="ui positive right labeled icon button">
      OK
      <i class="checkmark icon"></i>
    </div>
  </div>
</div>

`,
  styleUrls: ['app.component.css'],
})
export class AppComponent {
  menuItems: Menu[] //= MenuItems;
  settingMenuItems: Menu[] //= SettingMenuItems;

  username: String = "";
  isLoading: boolean = true;
  //router: Router;
  profile_image: SafeUrl;
  //backGroundCommunicationService: BackGroundCommunicationService

  constructor(public _appservice: GlobalService, private router: Router, public backGroundCommunicationService: BackGroundCommunicationService) {

    //this.backGroundCommunicationService = _BackGroundCommunicationService;
    // this.router = _router;

    this.subscribeMenu();

    // 2. sync current user info
    this.updateTheCurrentUserWithRemote();

    // 3. logout pubnub whe leave
    window.onbeforeunload = function (e) {
      e.preventDefault();
      backGroundCommunicationService.unsubscribePubnub();
    };


  }

  /**
   * subscribeMenu
   */
  subscribeMenu() {
    // 1. query menu and self role by subscription
    this._appservice.getMenu().subscribe(menu => { this.menuItems = menu; });
    this._appservice.getSettingMenu().subscribe(smenu => { this.settingMenuItems = smenu; });
  }


  /**
   * sidebarclick
   */
  sidebarclick() {
    $('.ui.labeled.icon.sidebar')
      .sidebar('toggle');
  }
  /**
   * makeCallAccceptCallin
   */
  makeCallAccceptCallin(ison) {
    console.log("in makeCallAccceptCallin isOn= " + ison);
    this.backGroundCommunicationService.setSelfPubnubState(ison);

  }

  /**
   * goSettingMenu
   * @param type 
   */
  goSettingMenu(type: number) {
    console.log("goSettingMenu() type= " + type);
    this.router.navigateByUrl("/" + this.settingMenuItems[type].url);
  }

  /**refresh the current user data withn remote server */
  updateTheCurrentUserWithRemote() {
    console.log("updateTheCurrentUserWithRemote")
    var time = Date.now();
    this._appservice.username.subscribe(n => this.username = n);

    var currentUser = Parse.User.current();
    if (currentUser == undefined || currentUser == null) {
      console.info("no current user and need to login");
      this.disableLoadingView();
      return;
    }
    currentUser.fetch().then((user) => {
      if (user) {
        var diff = Date.now() - time;
        console.error("in currentUser.fetch().then / refresh local currentuser ibject success" + diff);
        currentUser = user;
        this.showTheUserProfile(currentUser);

      } else {
        console.error("in currentUser.fetch().then/ refresh local currentuser ibject fail");
        this.pleaseRefreshThepage();
      }
    }, (error) => {
      console.error("in currentUser.fetch()/ exception " + error);
      if (error.code == 209) {
        this.isLoading = false;
        Parse.User.logOut();
        this.router.navigateByUrl("/");
      }
      this.pleaseRefreshThepage();


    });


  }

  /*** show the local userprofile on web page */
  showTheUserProfile(currentUser) {
    console.log("in showTheUserProfile");
    this.disableLoadingView();
    if (this.username == undefined || this.username == null || this.username == "" && currentUser) {
      console.log("in initTheUserProfilename/ with currentUser so update profilename")
      if (currentUser.get("userNickname") != "" || this.username != null || currentUser.get("userNickname") != undefined) {
        this._appservice.username.next(currentUser.get("userNickname"));
      } else {
        this._appservice.username.next(currentUser.getUsername);
      }
      if (currentUser.get("userProfileImageSmall")) {
        var profilePhoto = currentUser.get("userProfileImageSmall");
        this._appservice.profile_image = profilePhoto.url();
      }
    }

  }

  /**
   * checkTheUserInTheRole
   * @param rolename 
   * @param scb 
   * @param fcb 
   */
  checkTheUserInTheRole(rolename, scb, fcb) {
    console.log("in checkTheUserInTheRole()");
    var queryRole = new Parse.Query(Parse.Role);
    queryRole.equalTo('name', rolename);
    queryRole.first({
      success: function (result) { // Role Object
        var role = result;
        var adminRelation = new Parse.Relation(role, 'users');
        var queryAdmins = adminRelation.query();
        queryAdmins.equalTo('objectId', Parse.User.current().id);
        queryAdmins.first({
          success: function (result) {    // User Object
            var user = result;
            user ? scb() : fcb();
          }
        });
      },
      error: function (error) { }
    });

  }

  /**
   * disableLoadingView
   */
  disableLoadingView() {
    console.log("in disableLoadingView");
    this.isLoading = false;
  }

  /**
   * pleaseRefreshThepage
   */
  pleaseRefreshThepage() {
    alert("出現錯誤，請重新整理網頁");
  }

  ngOnInit(): void {
    console.log("in ngOnInit()");
  }


}




/*
Copyright 2016 MABOW Inc. All Rights Reserved.

*/
