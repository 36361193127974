export class MESSAGEBOARD {

  MessageBoardObjectId = "";
  MessageBoardElderNumber = "";
  MessageBoardElderName = "";
  MessageBoardContent = "";
  MessageBoardUpdateAt;
  MessageBoardUpdaterObjectId = "";
  MessageBoardUpdater;
  MessageBoardCommentDetail = []
  isReplied = false;


}