import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'CareDetailPipe'
})
export class CareDetailPipe {

    transform(value, args?) {
        let [minAge] = args;
        return value.filter(caredetails => {
            if (minAge == "全")
                return caredetails.IsDone
            else
                return caredetails.IsDone == minAge;
        });
    }

}