import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router ,ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
declare var Parse: any;

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  router: Router;
  constructor(private _router: Router) {
    this.router = _router;
    //this.loginIfPossible();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('i am checking to see if you are logged in');
    var currentUser = Parse.User.current();
    if (!currentUser) {
      console.log("in ParentClass/ parseLoginIfNeeded/ currentUser NOT existed ");
      console.dir(currentUser);
      console.log("in ParentClass/ parseLoginIfNeeded/ redericet to login ");
      
      this.router.navigate(['/login'], { queryParams: { returnUrl: decodeURIComponent(state.url) }});
      return false;


    }
    return true;
  }

  canActivateChild() {
    console.log('checking child route access');
    return true;
  }


}