import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-is-recording',
  templateUrl: './check-is-recording.component.html',
  styleUrls: ['./check-is-recording.component.css']
})
export class CheckIsRecordingComponent implements OnInit {

  router: Router
  constructor(_router: Router, ) {
    this.router = _router;
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
  }

  ngOnInit() {
  }
  go() {
    this.router.navigateByUrl('/selectElder');

  }

}
