import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalService } from '../global.service';

declare var Parse: any;
declare var $: any;

var Info = {
  name: null,
  id: null,
  pass: null
};

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginAddSubscriptionComponent implements OnInit {
  formSubmited: boolean = false;
  info = Info;
  returnUrl: string;

  constructor(
    private router: Router, private fb: FormBuilder,private  globalservice: GlobalService
    , private route: ActivatedRoute) {
  }

  
  /**
   * init
   */
  ngOnInit(): void {
    let currentUser = Parse.User.current();
    if (currentUser != null) {
      this.router.navigateByUrl('/');
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        $('.ui.form')
      .form({
        fields: {
          pass: ['minLength[1]', 'empty'],
          account: ['minCount[6]', 'empty'],
        }
      })
      ;
  }

  
  /**
   * login 
   */
  login(): void {
    console.log("in login() ");
    if (!this.info.id || !this.info.pass) {
      console.error("please enter th password or id");
      return;
    }
    this.formSubmited = true;
    Parse.User.logIn(this.info.id, this.info.pass, {
      success: (user: any) => {
        console.log("in login/ currentUser=" + user.getUsername);
        if (user.getUsername != null || user.getUsername != undefined) {
          this.globalservice.username.next(user.get("userNickname"));
        } else {
          this.globalservice.username.next(user.getUsername);
        }
        this.globalservice.queryAvalibleAllMenu();
        this.router.navigate([this.returnUrl]);
      },
      error: (user: any, error: any) => {
        console.error("in parseLogin/ login failed");
        this.formSubmited = false;
        alert("登入失敗 請再試一次");
      }
    });
  }

  /**
   * signup
   */
  signup(): void {
    var user = new Parse.User();
    user.set("username", this.info.id);
    user.set("password", this.info.pass);
    user.signUp(null, {
      success: function (user) {
        alert("註冊成功 請登入");
      },
      error: function (user, error) {
        alert("註冊失敗 Error: " + error.code + " " + error.message);
      }
    });
  }


}

