import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParentClass } from '../ParentClass';
import { GlobalService } from '../global.service';
import { ElderData } from '../select-elder/elder_data';
import { caredetails } from './caredetails';
import { CareDetailPipe } from './caredetails.pipe';


declare var Parse: any;

@Component({
  selector: 'CareDetails',
  templateUrl: 'caredetails.component.html',
  styleUrls: ['caredetails.component.css']
})

export class CareDetailsComponent extends ParentClass implements OnInit {
  focusParseUserData: ElderData;
  carenexttime = "";
  sortBy: string = "";
  check_media = "";

  Caredetails: caredetails[] = [];
  constructor(
    router: Router, private _globalservice: GlobalService
  ) {
    super(router);
    this.focusParseUserData = _globalservice.focusParseUserObject;
    this.QueryElderDetailInfo();
    this.QueryCareRecordInfo();
    window.onbeforeunload = function () {
      return 'window close';
    };
  }



  ngOnInit(): void {
  }

  QueryElderDetailInfo() {
    console.log("in QueryElderDetailInfo id: " + this.focusParseUserData.user_object_id)
    var ElderDetailInfo = Parse.Object.extend("ElderDetailInfo");
    var elderdetailinfo = new ElderDetailInfo();
    var query = new Parse.Query(elderdetailinfo);
    query.equalTo("User", {
      __type: "Pointer",
      className: "_User",
      objectId: this.focusParseUserData.user_object_id
    });
    query.descending("updatedAt");
    query.find({
      success: (results) => {
        console.log("Successfully retrieved " + results.length + " ElderDetailInfo");
        if (results.length > 0) {
          var elderDetailInfoObject = results[0];
          if (elderDetailInfoObject.get('CellPhoneNumber'))
            this.focusParseUserData.cellphonenumber = elderDetailInfoObject.get('CellPhoneNumber');
          if (elderDetailInfoObject.get('LocalPhoneNumber'))
            this.focusParseUserData.localphonenumber = elderDetailInfoObject.get('LocalPhoneNumber');
          if (elderDetailInfoObject.get('LivingLocation'))
            this.focusParseUserData.livinglocation = elderDetailInfoObject.get('LivingLocation');
          if (elderDetailInfoObject.get('CareAccount'))
            this.focusParseUserData.careaccount = elderDetailInfoObject.get('CareAccount');
          if (elderDetailInfoObject.get('CareOwner'))
            this.focusParseUserData.careowner = elderDetailInfoObject.get('CareOwner');
          if (elderDetailInfoObject.get('CareInterface'))
            this.focusParseUserData.careinterface = elderDetailInfoObject.get('CareInterface');
          if (elderDetailInfoObject.get('Name'))
            this.focusParseUserData.name = elderDetailInfoObject.get('Name');
          if (elderDetailInfoObject.get('SerialNumber'))
            this.focusParseUserData.serialnumber = elderDetailInfoObject.get('SerialNumber');
          if (elderDetailInfoObject.get('Age'))
            this.focusParseUserData.age = elderDetailInfoObject.get('Age');
          if (elderDetailInfoObject.get('HelthStatus'))
            this.focusParseUserData.helthstatus = elderDetailInfoObject.get('HelthStatus');
          if (elderDetailInfoObject.get('DailyActivity'))
            this.focusParseUserData.dailyactivity = elderDetailInfoObject.get('DailyActivity');
          if (elderDetailInfoObject.get('FamilyStatus'))
            this.focusParseUserData.familystatus = elderDetailInfoObject.get('FamilyStatus'); if (elderDetailInfoObject.get('FamilyStatus'))
            if (elderDetailInfoObject.get('Occupation'))
              this.focusParseUserData.occupation = elderDetailInfoObject.get('Occupation');
          if (elderDetailInfoObject.get('Others'))
            this.focusParseUserData.others = elderDetailInfoObject.get('Others');
          if (elderDetailInfoObject.get('NextCareTime')) {
            this.focusParseUserData.nextCareTime = elderDetailInfoObject.get('NextCareTime');
            var date = this.focusParseUserData.nextCareTime;
            this.carenexttime = date.getFullYear() + "/" + this.padStr(1 + date.getMonth()) + "/" +
              this.padStr(date.getDate());
          }
          if (elderDetailInfoObject.get('NickName'))
            this.focusParseUserData.nickname = elderDetailInfoObject.get('NickName');
          else
            this.focusParseUserData.nickname = "";
          this.focusParseUserData.elderdetailobjectid = elderDetailInfoObject.id;
        }
      },
      error: function (error) {
        console.log("Error: " + error.code + " " + error.message);
      }
    });
  }


  QueryCareRecordInfo() {
    console.log("in QueryCareRecordInfo ")
    var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
    var carerecordinfo = new CareRecordInfo();
    var query = new Parse.Query(CareRecordInfo);
    query.include('relateMessageBoard');
    query.include('careRecordInfoV2');
    query.equalTo("elder", {
      __type: "Pointer",
      className: "_User",
      objectId: this.focusParseUserData.user_object_id
    });
    console.log("QueryCareRecordInfo mabow_id: " + this.focusParseUserData.mabowid);
    console.log("QueryCareRecordInfo objectId: " + this.focusParseUserData.user_object_id);
    query.limit(100);
    query.descending("createdAt");
    query.find({
      success: (results) => {
        console.log("Successfully retrieved " + results.length + " messages.");
        for (var i = 0; i < results.length; i++) {
          var object = results[i];
          var dateStr = "undefined";
          if (object.get('starttime')) {
            dateStr = this.padStr(1 + object.get('starttime').getMonth()) + "/" +
              this.padStr(object.get('starttime').getDate());
          }

          var starttime = "undefined";
          if (object.get('starttime')) {
            starttime = this.padStr(object.get('starttime').getHours()) + ":" +
              this.padStr(object.get('starttime').getMinutes());
          }
          var endtime = "undefined";
          if (object.get('endtime')) {
            endtime = this.padStr(object.get('endtime').getHours()) + ":" +
              this.padStr(object.get('endtime').getMinutes());
          }
          var minutes = '0';
          var _message = "本次溝通沒有填寫 i";
          var _DetailRecord = "本次溝通沒有填寫";
          var _ImageURL = "";
          if (object.get('relateMessageBoard')) {
            var messageboard = object.get('relateMessageBoard');
            minutes = (Number(messageboard.get("talkInSecond")) / 60).toFixed(2);
            _message = messageboard.get('MESSAGE');
            _DetailRecord = messageboard.get("DetailRecord");
            if (messageboard.get("ImageUploadSmall"))
              _ImageURL = messageboard.get("ImageUploadSmall").url();
          }

          var isdone = "否";
          isdone = (object.get('isDone')) ? "是" : "否";

          var fail_reason = "";
          if (object.get('fail_reason')){
            fail_reason = String(object.get('fail_reason'));
          }


          var video_quality = "";
          if (object.get('video_quality'))
            video_quality = object.get('video_quality') ? "好" : "差";

          var pc = "";
          pc = (object.get('comPC')) ? object.get('comPC') : "";


          console.log("try to fetch record media");
          var media = "";

          if (object.get('careRecordInfoV2')) {
            //console.log("try to fetch record media 2");

            media = object.get('careRecordInfoV2').get('url');
            console.log("media=" + media);
          }

          var careGiver = "undefined";
          if (object.get('careGiver'))
            careGiver = object.get('careGiver').get('userNickname');

          this.Caredetails.push({
            Time: dateStr,
            Message: _message,
            DetailRecord: _DetailRecord,
            StartTime: starttime,
            IsDone: isdone,
            fail_reason: fail_reason,
            video_quality: video_quality,
            Duration: String(minutes),
            CommunicationTool: object.get('communicationTool'),
            EndTime: endtime,
            HelpDetail: (object.get('helpDetail') == undefined) ? "N/A" : object.get('helpDetail'),
            Note: (object.get('note') == undefined) ? "N/A" : object.get('note'),
            Secretary: careGiver,
            ImageURL: _ImageURL,
            objectId: object.id,
            PC: pc,
            VideoUrl: media,
          });

        }
        console.dir(this.Caredetails)
      },
      error: function (error) {
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }

  checkDetailVideo(videoUrl) {

        if (videoUrl == "" || videoUrl == null) {
          alert("此次記錄沒有上傳影片！！！")
        } else {
          /*window.open(videoUrl);*/
          /*window.open(videoUrl, '_blank', 'location=yes,height=500,width=600,scrollbars=no,status=yes');*/

          this.check_media = videoUrl;
          //alert("播放影片")
          var myVideo = document.querySelector('video')
          myVideo.src = videoUrl;
          myVideo.load();
          myVideo.play();

          window.document.body.scrollTop = 0;
          window.document.documentElement.scrollTop = 0;

        }

      }

  padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
  }
  gomodify() {
    console.log("in go function , and go to modify page");
    this.router.navigateByUrl('/caredetailsmodify');
  }

}
