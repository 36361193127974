import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { Appointment, DayWithAppointments } from "./stateTypes";
import * as  moment from "moment";
import { AppointmentDetail } from "./appointment-detail.component";
@Component({
    selector: "day-detail",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <table class="table table-striped">
            <thead>
                <tr>
                    <td>{{dayWithAppointments.day.day}}
                        <span class="ui green label" *ngIf="dayWithAppointments.appointments.length === 0">Free</span>
                        <span class="ui orange label " *ngIf="dayWithAppointments.appointments.length > 0">Care</span>
                    </td>
                    <td>
                        <button class="ui icon button" (click)="onAdd()"><i class="plus icon"></i></button>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let appointment of dayWithAppointments.appointments">
                    <td>
                        <appointment-detail [appointment]="appointment" (remove)="removeAppointment.emit($event)" 
                        (update)="updateAppointment.emit($event)"></appointment-detail>
                    </td>
                    <td>
                        <button class="ui red icon button" (click)="removeAppointment.emit(appointment)"><i class="trash outline icon"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    `
})
export class DayDetail {
    @Input() public dayWithAppointments: DayWithAppointments;
    @Output() public addAppointment = new EventEmitter<Appointment>();
    @Output() public updateAppointment = new EventEmitter<Appointment>();
    @Output() public removeAppointment = new EventEmitter<Appointment>();

    public onAdd(): void {
        let fakeDate = moment().year(this.dayWithAppointments.day.year).month(this.dayWithAppointments.day.month)
            .date(this.dayWithAppointments.day.day).hours(0).minutes(0).seconds(0);
        this.addAppointment.emit(new Appointment("", fakeDate.toDate(), "請輸入care對象"));
    }

    constructor() {
    }
}