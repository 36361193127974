import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ParentClass } from '../ParentClass';
//import { prepareData } from './prepare_data'
import { GlobalService } from '../global.service';
import { ElderData } from '../select-elder/elder_data';
import { BrowserModule } from '@angular/platform-browser';
//import { FormControl } from "@angular/forms";
import { FormControl, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComminnicatonParameterDefine } from '../comminnicatonParameterDefine';
import { POST } from './addMessageBoard.post';








//import * as AWS from 'aws-sdk';



declare var Parse: any;
declare var $: any;
declare var window: any;
//declare var AWS: any;

@Component({
    selector: 'addMessageBoard',
    templateUrl: 'addMessageBoard.component.html',
    styleUrls: ['addMessageBoard.component.css']
})




export class AddMessageBoardComponent extends ParentClass {

    isLoading: boolean = true;
    trustedDashboardUrl: SafeUrl;
    formSubmited: boolean = false;
    element: HTMLImageElement; /* Defining element */
    focusParseUserData: ElderData;
    inputLists;
    rules: any[];
    number4 = 0;
    lastMessage: String = "";
    postItime = 0;
    _comminnicatonParameterDefine: ComminnicatonParameterDefine

    newMessage = {
        talkSecond: "",
        messageContent: "",
        wish: "",
        mood: "",
        messageContent1: "",
        messageContent2: "",
        messageContent3: "",
        messageContent4: "",
        messageContentExample: "",
        messageContentAll: "",
        detailRecord: "",
        fail_reason: "",
        video_quality: "",

    };
    isPostSuccess: boolean = false;

    newRecordInfo = {
        note: "",
        helpDetail: ""
    };

    calldonedate = new Date();
    totalcalltime = 0;  //unit : second

    RecordTimeArray = [];
    RecordTime = {
        time_info: "",
        total_time: ""
    }

    PostTagExampleArray = [];

    post_tag: Array<string> = ["A", "B", "C", "D"];
    //cities: Array<string> = ["範本1", "範本2", "範本3"];
    postA_id = "";
    postB_id = "";
    postC_id = "";
    postD_id = "";
    postSubTag_id = "";

    postA = {
    }
    selectedExampleA = "";

    postA_example_title: Array<string> = [];
    postB_example_title: Array<string> = [];
    postC_example_title: Array<string> = [];
    postD_example_title: Array<string> = [];

    postA_example: Array<string> = [];
    postB_example: Array<string> = [];
    postC_example: Array<string> = [];
    postD_example: Array<string> = [];
    post_subTag: Array<string> = [];

    cities: Array<string> = ["New York", "Belgrade", "Stockholm", "Sarajevo"];
    choosePostTag = [];
    multipleControl: FormControl = new FormControl("", Validators.required);

    elderUserPhoto = "/app/images/default_photo.png";
    elderUsermabowId = "";
    elderUserName = "";
    uploadPhotoPreview: SafeUrl;
    CareRecordInfoObject;
    CareRecordMediaFile;
    addMessageDefinea: any;

    upload_percent = 0;

    DefaultBlockSize = 1024 * 1024; // Default to 1MB
    upload_azure_config = {
        baseUrl: 'https://alexprivatestorage.blob.core.windows.net/test/',// baseUrl for blob file uri (i.e. http://<accountName>.blob.core.windows.net/<container>/<blobname>),
        sasToken: '?ZByHHKQRCQdCElRuaNHM67EjtLba9vChaMZyHLy1lsisd+cVvFNqcJbpTY7WMupc1zI59HowKX8JXPOXmGrWhw==',// Shared access signature querystring key/value prefixed with ?,
        file: {},// File object using the HTML5 File API,
        progress: function cb(file, data) {

        },// progress callback function,
        complete: function cb(file, data) {
            console.log("upload file success");
        },// complete callback function,
        error: function cb(file, data) {
            console.log("upload file error");
        },// error callback function,
        blockSize: 1024 * 1024  // Use this to override the DefaultBlockSize
    };

    currentUser;
    Elder_ObjectId;
    Elder_PhoneId;
    Elder_Name;
    Elder_Photo;
    imageFile;
    recordFile;
    userACLString;
    adminACLString;
    readonlyAdminACLString;
    // for url routing
    successType: number;

    /*
      constructor
    */
    constructor(
        private activatedRoute: ActivatedRoute, router: Router, private _globalservice: GlobalService, private sanitizer: DomSanitizer, private comminnicatonParameterDefine: ComminnicatonParameterDefine
    ) {
        super(router);
        console.log("in constructor");
        this.focusParseUserData = _globalservice.focusParseUserObject;
        this._comminnicatonParameterDefine = comminnicatonParameterDefine;
        this.CareRecordInfoObject = _globalservice.CareRecordInfoObject;
        this.adminACLString = _globalservice.adminACLString;
        this.readonlyAdminACLString = _globalservice.readonlyAdminACLString;
        this.userACLString = _globalservice.userACLString;
        console.dir(_globalservice);
        /*
        window.onbeforeunload = function () {
            return 'window close';
        };*/



    }


    /**init */
    ngOnInit(): void {
        console.log("in AddMessage ngOnInit");
        // check the data

        $('.dropdown').dropdown({
            label: {
                duration: 0,
            },
            debug: false,
            performance: true,
        });



        if (this.CareRecordInfoObject == null || this.focusParseUserData == null) {
            console.error("in constructor not enough data so go back to selectoe");
            this.router.navigateByUrl('/selectElder');
        }

        // get url status
        this.activatedRoute.params.subscribe(params => {
            this.successType = +params['successType']; // (+) converts string 'id' to a number

            // In a real app: dispatch action to load the details here.
        });
        console.log("successType= " + this.successType)
        console.dir(this._comminnicatonParameterDefine)
        this.addMessageDefinea = this._comminnicatonParameterDefine.addMessageDefine[this.successType];
        console.dir(this.addMessageDefinea)

        // instance basic info
        this.currentUser = Parse.User.current();
        this.Elder_ObjectId = this.focusParseUserData.fullUserObject.id;
        this.Elder_PhoneId = this.focusParseUserData.mabowid;
        this.Elder_Name = this.focusParseUserData.nickname;
        this.Elder_Photo = this.focusParseUserData.imageUrl;




        this.elderUsermabowId = this.Elder_PhoneId;
        this.elderUserName = this.Elder_Name;
        this.elderUserPhoto = this.Elder_Photo;

        $('#example1').progress({
            percent: this.upload_percent
        });

        //this.registerSelectImage();
        this.retreivePostExampleArray();
        //this.registerSelectFile();
        //this.registerSubmitAll();
        this.retreiveCallTime();
        this.queryLastCareRecordInfo();
        this.startTiming();
        $('.ui.fixed.sticky')
            .sticky({
                context: '#container'
            })
            ;
    }

    queryLastCareRecordInfo() {
        console.log("in QueryCareRecordInfo ")
        var CareRecordInfo = Parse.Object.extend("CareRecordInfo");
        var carerecordinfo = new CareRecordInfo();
        var query = new Parse.Query(CareRecordInfo);
        query.include('relateMessageBoard');
        query.include('careRecordInfoV2');
        query.equalTo("elder", {
            __type: "Pointer",
            className: "_User",
            objectId: this.focusParseUserData.user_object_id
        });
        query.descending("createdAt");
        console.log("QueryCareRecordInfo mabow_id: " + this.focusParseUserData.mabowid);
        console.log("QueryCareRecordInfo objectId: " + this.focusParseUserData.user_object_id);
        query.first({
            success: (results) => {
                console.log("Successfully retrieved LastCareRecordInfo message." + results.id);
                var object = results;
                this.lastMessage = "本次溝通沒有填寫 i";
                if (object.get('relateMessageBoard')) {
                    var messageboard = object.get('relateMessageBoard');
                    this.lastMessage = messageboard.get('MESSAGE');
                }
            },
            error: function (error) {
                alert("Error: " + error.code + " " + error.message);
            }
        });
    }

    startTiming() {
        var timer = setInterval(() => {
            this.postItime++;
        }, 1000);
    }

    /***
   * conponent leave callback
   */
    canDeactivate() {
        console.log('i am navigating away');
        if (this.isPostSuccess == true) {
            return true
        }
        /*if (this.submitCheckisOk() == true ) {
            return true
        } else {*/
        return window.confirm('放棄編輯嗎?');
        //}
    }
    /**
     * @method onSelectSample
     * @param data :String
     */
    onSelectSample(data: string): void {
        console.log("onSelectSampleA=" + data);
        this.newMessage.messageContentAll = this.newMessage.messageContentAll.concat(data);

    }


    /**
    * @method onEditMessageContentAll
    * @param data :String
    */
    onEditMessageContentAll(data) {
        console.log("onEditMessageContentAll=" + data);
        this.newMessage.messageContentAll = this.newMessage.messageContentAll.concat(data);

    }

    /**
     * @method onMultiple
     * @param data :Array
     */
    onMultiple(data: Array<string>): void {
        this.choosePostTag = data;
    }

    onMultipleSelect(data: string): void {
        console.log("onMultipleSelect:" + data);
        /* $('#members_dropdown > option:selected').toArray().map(
             (obj) => {obj.value

                 console.log("ddata=" + obj.value);
             }
           );*/

        /*console.log("ddata=" + $('select#members_dropdown').val());*/
        this.choosePostTag = $('select#members_dropdown').val()
        /*for (var i = 0; i < this.choosePostTag.length; i++) {
            if (data == this.choosePostTag[i]) {
                return;
            }
        }
        this.choosePostTag.push(data);*/

    }


    /**
    * @method submitCheckisOk
    * @return boolean
    */
    submitCheckisOk(): boolean {
        console.log("in submitCheckisOk");
        // Limit the file type of image
        if (typeof this.imageFile === "undefined") {
            console.log("imageFile = undefined");
            var r = confirm("未上傳照片是否繼續");
            if (r == false) {
                return false;;
            }
        } else {
            console.log("imageFile has file, so check file type");
            var ext = $('#fileselect').val().split('.').pop().toLowerCase();
            if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
                this.submitCheckisOkErrorAlertShow('檔案格式不符!請重新選擇');
                return false;
            }
        }


        //  check input

        var talkingTime = this.newMessage.talkSecond;
        var content = this.newMessage.messageContentAll;
        var moodNum = this.newMessage.mood;
        var fail_reason = this.newMessage.fail_reason;
        var video_quality = this.newMessage.video_quality;
        var detailRecordContent = this.newMessage.detailRecord;
        var showmissitemarray = [];
        if (talkingTime == "" && this.addMessageDefinea.careInfo) {
            showmissitemarray.push("關懷時間沒有填");
        }
        if (content == "" && this.addMessageDefinea.i) {
            showmissitemarray.push("關懷短紀錄沒有填");
        }
        if (content.length > 50 && this.addMessageDefinea.i) {
            showmissitemarray.push("關懷短紀錄太長");
        }
        if (moodNum == "" && this.addMessageDefinea.careInfo) {
            showmissitemarray.push("心情沒有填");
        }
        if (fail_reason == "" && !this.addMessageDefinea.isDone) {
            showmissitemarray.push("不成功原因沒有填");
        }
        if (video_quality == "" && this.addMessageDefinea.isDone && this.addMessageDefinea.i) {
            showmissitemarray.push("視訊品質沒有填");
        }
        if (detailRecordContent.length == 0 && this.addMessageDefinea.detailI) {
            showmissitemarray.push("關懷『長』紀錄沒有填");
        }

        if (this.isLoading) {
            showmissitemarray.push("還在處理中，請等下再試試");
        }

        if (showmissitemarray.length > 0) {
            this.submitCheckisOkErrorAlertShow('有漏東西或是規格不合 :' + showmissitemarray);
            return false;
        }


        return true;
    }

    submitCheckisOkErrorAlertShow(content: string) {
        alert(content);
    }


    /**
    * @method submitPost
    * @return event
    */
    submitPost(event: Event) {
        console.log("submitPost ........................");

        if (this.submitCheckisOk() == false) {
            console.error("submit pist/ check require fail so return");
            return;
        }
        // confirm again
        var r = confirm("確定要上傳嗎？\n• 送出i 之後，開啟 app 檢查是否 i 訊息正確無誤\n• 下次時間登記至 google 日曆\n• 登出 通訊/雲端 帳號");
        if (r != true) {
            console.log("/submit/ user deny uploading");
            return;
        }
        alert("本次的發 i 時間為 " + this.postItime + " 秒")
        this.isLoading = true;
        // uploading......
        // disable all input
        this.disableInput();
        let talkingTime = this.newMessage.talkSecond;
        let content = this.newMessage.messageContentAll;
        let requirementContent = this.newMessage.wish;
        let moodNum = this.newMessage.mood;
        let detailRecordContent = this.newMessage.detailRecord;
        let postSubTag = this.choosePostTag;
        // var recordInSecond = this.recordTime;

        this.userACLString = this.Elder_PhoneId.concat("_eng");

        console.log("ready to save message object");
        var Task = Parse.Object.extend("MessageBoard");
        var Pointer = Parse.Object.extend("_User");
        var pointer = new Pointer();
        pointer.id = this.currentUser.id;
        var messageboardObjeciId;


        // if (typeof this.imageFile === "undefined") {
        //do nothing
        console.log("no image to uplad, so run the original process");
        Parse.Promise.when([this.imageToUpload(), this.thumbnailImageToUpload()])
            .then((results) => {
                return this.uploadDataTomessageBoardClass(results[0], results[1], pointer, this.Elder_PhoneId, Number(talkingTime), content, Number(moodNum), requirementContent, postSubTag, detailRecordContent)
            }).then((task) => {
                if (task) {
                    messageboardObjeciId = task.id;
                    console.log('New object created with objectId: ' + messageboardObjeciId);
                }
                console.log("has requirementContent, so start to upload it");
                return this.uploadRequirement(messageboardObjeciId, this.Elder_ObjectId, requirementContent);

            }).then(() => {
                return this.saveRecordInfoObject(messageboardObjeciId);

            }).then(() => {
                this.finalcallback("Success", true, false);

            }).catch((e) => {
                console.error('Failed to create new object, with error code: ' + e);
                console.dir(e)
                this.finalcallback("Fail", false, false);

            });


    }

    /**
    * @method imagefileChangeEvent
    * @param fileInput
    */
    imagefileChangeEvent(fileInput: any) {
        var files = fileInput.target.files || fileInput.dataTransfer.files;
        // Our file var now holds the selected file
        this.imageFile = files[0];
        console.dir(this.imageFile);

        var reader = new FileReader();
        reader.readAsArrayBuffer(this.imageFile);

        reader.onload = (event: any) => {
            // blob stuff
            console.log("in reader.onload");
            var blob = new Blob([event.target.result]); // create blob...
            console.dir(blob);
            //var window: any;
            //window.URL = window.URL || window.webkitURL;
            var blobURL = (window.URL || window.webkitURL).createObjectURL(blob); // and get it's URL
            this.trustedDashboardUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl
                    (blobURL);

            //(<HTMLInputElement>document.getElementById("uploadPhotoPreview")).src = blobURL;
            this.uploadPhotoPreview = this.trustedDashboardUrl;
            //(window.URL || window.webkitURL).revokeObjectURL(blobURL);

        };

    }

    /**
     * @method videofileChangeEvent
     * @param fileInput
     */
    videofileChangeEvent(fileInput: any) {
        var files = fileInput.target.files || fileInput.dataTransfer.files;
        // Our file var now holds the selected file
        this.recordFile = files[0];
        console.dir(this.recordFile);

        //this.video_upload_init(this.upload_azure_config, this.recordFile);
        this.video_upload_aws(this.recordFile);
    }


    /**
     * @method retreivePostExample
     */
    retreivePostExample() {
        console.log("in retreivePostExample()");

        //var PostTagInfo = Parse.Object.extend("MessageBoardPostTag");
        var PostTagContent = Parse.Object.extend("MessageBoardPostExample");
        var PostTagInfo = Parse.Object.extend("MessageBoardPostTag");
        var query_tag = new Parse.Query(PostTagInfo);
        query_tag.find({
            success: (results_tag) => {
                for (var i = 0; i < results_tag.length; i++) {
                    var object_tag = results_tag[i];

                    //console.log(object_tag.id + ' - ' + object_tag.get('PostTag'));

                    switch (object_tag.get('PostTag')) {
                        case "A":
                            this.postA_id = object_tag.id;
                            break;
                        case "B":
                            this.postB_id = object_tag.id;
                            break;
                        case "C":
                            this.postC_id = object_tag.id;
                            break;
                        case "D":
                            this.postD_id = object_tag.id;
                            break;
                        case "subTag":
                            this.post_subTag.push(object_tag.get('PostContentType'));
                            break;
                        default:

                    }
                }

                var query = new Parse.Query(PostTagContent);
                //query.matchesQuery("Tag", innerQuery);
                //query.equalTo("PostTag", "Dan Stemkoski");
                query.find({
                    success: (results) => {
                        console.log("results=" + results.length);
                        for (var j = 0; j < results.length; j++) {
                            var object = results[j];

                            //    console.log(object.id + ' - ' + object.get('ContentExample'));
                            var tag_id = object.get('Tag').id;
                            //   console.log("tag_id = " + tag_id);
                            //   console.log("postA_id = " + this.postA_id);
                            //   console.log("postB_id = " + this.postB_id);
                            //   console.log("postC_id = " + this.postC_id);
                            //   console.log("postD_id = " + this.postD_id);

                            switch (tag_id) {
                                case this.postA_id:
                                    this.postA_example.push(object.get('ContentExample'));
                                    var index: number = this.postA_example_title.length;
                                    this.postA_example_title.push((index + 1).toString());
                                    break;
                                case this.postB_id:
                                    this.postB_example.push(object.get('ContentExample'));
                                    var index: number = this.postB_example_title.length;
                                    this.postB_example_title.push((index + 1).toString());
                                    break;
                                case this.postC_id:
                                    this.postC_example.push(object.get('ContentExample'));
                                    var index: number = this.postC_example_title.length;
                                    this.postC_example_title.push((index + 1).toString()); break;
                                case this.postD_id:
                                    this.postD_example.push(object.get('ContentExample'));
                                    var index: number = this.postD_example_title.length;
                                    this.postD_example_title.push((index + 1).toString()); break;
                                default:
                                    break;
                            }

                        }
                        this.isLoading = false;
                    },
                    error: function (error) {
                        alert("Error: " + error.code + " " + error.message);
                        this.isLoading = false;
                    }
                });





            },
            error: function (error) {
                alert("Error: " + error.code + " " + error.message);
                this.isLoading = false;

            }
        });


    }

    retreivePostExampleArray() {
        console.log("in retreivePostExampleArray()");


        var PostTagContent = Parse.Object.extend("MessageBoardPostExample");
        var PostTagInfo = Parse.Object.extend("MessageBoardPostTag");
        var query_tag = new Parse.Query(PostTagInfo);
        query_tag.find({
            success: (results_tag) => {
                for (var i = 0; i < results_tag.length; i++) {
                    var object_tag = results_tag[i];

                    //console.log(object_tag.id + ' - ' + object_tag.get('PostTag'));

                    if (object_tag.get('PostTag') == "subTag") {

                        this.post_subTag.push(object_tag.get('PostContentType'));

                    } else {
                        // console.log("add new object " + object_tag.get('PostTag') + " - " + object_tag.id);
                        var PostObject = new POST();
                        PostObject.PostTagObjectId = object_tag.id;
                        PostObject.PostTag = object_tag.get('PostTag');  // A,B...
                        PostObject.PostType = object_tag.get('PostContentType');
                        this.PostTagExampleArray.push(PostObject);
                    }


                    /*switch (object_tag.get('PostTag')) {
                        case "A":
                            this.postA_id = object_tag.id;
                            break;
                        case "B":
                            this.postB_id = object_tag.id;
                            break;
                        case "C":
                            this.postC_id = object_tag.id;
                            break;
                        case "D":
                            this.postD_id = object_tag.id;
                            break;
                        case "subTag":
                            this.post_subTag.push(object_tag.get('PostContentType'));
                            break;
                        default:

                    }*/
                }

                var query = new Parse.Query(PostTagContent);
                query.find({
                    success: (results) => {
                        console.log("total " + results.length + " post examples");
                        for (var j = 0; j < results.length; j++) {
                            var object = results[j];


                            var tag_id = object.get('Tag').id;
                            //console.log(tag_id + ' - ' + object.get('ContentExample'));

                            for (var k = 0; k < this.PostTagExampleArray.length; k++) {
                                //console.log(k + ' - ' + this.PostTagExampleArray[k].PostTagObjectId);
                                if (tag_id == this.PostTagExampleArray[k].PostTagObjectId) {
                                    //console.log(tag_id + ' - ' + k + ' - ' + this.PostTagExampleArray[k].PostTagObjectId);
                                    //console.log(this.PostTagExampleArray[k].PostTag + " => " + object.get('ContentExample'));
                                    this.PostTagExampleArray[k].PostExample.push(object.get('ContentExample'));
                                    //console.log("tag " + this.PostTagExampleArray[k].PostTag + " now has " + this.PostTagExampleArray[k].PostExample.length + " examples");
                                }


                            }




                            /*
                                                        switch (tag_id) {
                                                            case this.postA_id:
                                                                this.postA_example.push(object.get('ContentExample'));
                                                                var index: number = this.postA_example_title.length;
                                                                this.postA_example_title.push((index + 1).toString());
                                                                break;
                                                            case this.postB_id:
                                                                this.postB_example.push(object.get('ContentExample'));
                                                                var index: number = this.postB_example_title.length;
                                                                this.postB_example_title.push((index + 1).toString());
                                                                break;
                                                            case this.postC_id:
                                                                this.postC_example.push(object.get('ContentExample'));
                                                                var index: number = this.postC_example_title.length;
                                                                this.postC_example_title.push((index + 1).toString()); break;
                                                            case this.postD_id:
                                                                this.postD_example.push(object.get('ContentExample'));
                                                                var index: number = this.postD_example_title.length;
                                                                this.postD_example_title.push((index + 1).toString()); break;
                                                            default:
                                                                break;
                                                        }*/

                        }
                        this.isLoading = false;
                    },
                    error: function (error) {
                        alert("Error: " + error.code + " " + error.message);
                        this.isLoading = false;
                    }
                });





            },
            error: function (error) {
                alert("Error: " + error.code + " " + error.message);
                this.isLoading = false;

            }
        });


    }

    /**
     * @method retreiveCallTime
     */
    retreiveCallTime() {

        if (this.successType == 2) { // call failed, so set the total call time to 0
            this.totalcalltime = 0;
            console.log("totalcalltime = " + this.totalcalltime);
            this.newMessage.talkSecond = this.totalcalltime.toString();
        } else {
            this.isLoading = true;
            var check_date = new Date();
            var check_d = check_date.getDate() - 1;
            check_date.setDate(check_d);
            console.log("check_date = " + check_date);
            this.RecordTimeArray = [];

            var callstartdate = new Date(this.CareRecordInfoObject.get("starttime"));
            var thiscalltotaltime = 0; //unit : second
            console.log("this call starttime=" + this.CareRecordInfoObject.get("starttime") + " calldonetime=" + this.calldonedate);
            thiscalltotaltime = (this.calldonedate.getTime() - callstartdate.getTime()) / 1000;
            console.log("this calltime=" + thiscalltotaltime);
            this.RecordTime = {
                time_info: "",
                total_time: ""
            }
            var callstartDate_Y = callstartdate.getFullYear();
            var callstartDate_M = callstartdate.getMonth() + 1;
            var callstartDate_D = callstartdate.getDate();
            var callstartDate_h = callstartdate.getHours();
            var callstartDate_m = callstartdate.getMinutes();
            var callstartDate_s = callstartdate.getSeconds();
            var callstartDate_time = callstartDate_Y + "/" + callstartDate_M + "/" + callstartDate_D + " " + callstartDate_h + ":" + callstartDate_m + ":" + callstartDate_s;

            var calldoneDate_Y = this.calldonedate.getFullYear();
            var calldoneDate_M = this.calldonedate.getMonth() + 1;
            var calldoneDate_D = this.calldonedate.getDate();
            var calldoneDate_h = this.calldonedate.getHours();
            var calldoneDate_m = this.calldonedate.getMinutes();
            var calldoneDate_s = this.calldonedate.getSeconds();
            var calldoneDate_time = calldoneDate_Y + "/" + calldoneDate_M + "/" + calldoneDate_D + " " + calldoneDate_h + ":" + calldoneDate_m + ":" + calldoneDate_s;

            this.RecordTime.time_info = "通話開始時間 : " + callstartDate_time + " -- 結束時間 : " + calldoneDate_time;
            this.RecordTime.total_time = "此次通話長度 : " + Math.round(thiscalltotaltime) + " 秒";
            this.RecordTimeArray.push(this.RecordTime);

            var CareRecordInfoObject = Parse.Object.extend("CareRecordInfo");
            var query = new Parse.Query(CareRecordInfoObject);
            query.equalTo("elder", this.focusParseUserData.fullUserObject);
            query.equalTo("careGiver", this.currentUser);
            query.equalTo("isDone", true);
            query.greaterThanOrEqualTo("starttime", check_date);
            query.descending("createdAt");

            query.find({
                success: (results) => {
                    console.log("results=" + results.length);
                    var isRecorded = false;

                    for (var j = 0; j < results.length; j++) {
                        var object = results[j];

                        //var tag_id = object.get('Tag').id;
                        if (object.get('relateMessageBoard') != null) {
                            console.log('already done record id = ' + object.get('relateMessageBoard').id);
                            isRecorded = true
                        }
                        if (!isRecorded) {
                            console.log(object.id + ' - callstart=' + object.get('starttime') + ' calldone=' + object.get('calldonetime'));

                            var calldoneTime;
                            if (object.get('calldonetime') == null) {
                                calldoneTime = new Date(object.get('starttime'));
                            } else {
                                calldoneTime = new Date(object.get('calldonetime'));
                            }

                            if (object.get('starttime') != null && calldoneTime != null) {

                                var callstartTime = new Date(object.get('starttime'));
                                this.totalcalltime = this.totalcalltime + ((calldoneTime.getTime() - callstartTime.getTime()) / 1000);
                                console.log("totalcalltime = " + this.totalcalltime);

                                this.RecordTime = {
                                    time_info: "",
                                    total_time: ""
                                }
                                var callstartTime_Y = callstartTime.getFullYear();
                                var callstartTime_M = callstartTime.getMonth() + 1;
                                var callstartTime_D = callstartTime.getDate();
                                var callstartTime_h = callstartTime.getHours();
                                var callstartTime_m = callstartTime.getMinutes();
                                var callstartTime_s = callstartTime.getSeconds();
                                var callstartTime_time = callstartTime_Y + "/" + callstartTime_M + "/" + callstartTime_D + " " + callstartTime_h + ":" + callstartTime_m + ":" + callstartTime_s;

                                var calldoneTime_Y = calldoneTime.getFullYear();
                                var calldoneTime_M = calldoneTime.getMonth() + 1;
                                var calldoneTime_D = calldoneTime.getDate();
                                var calldoneTime_h = calldoneTime.getHours();
                                var calldoneTime_m = calldoneTime.getMinutes();
                                var calldoneTime_s = calldoneTime.getSeconds();
                                var calldoneTime_time = calldoneTime_Y + "/" + calldoneTime_M + "/" + calldoneTime_D + " " + calldoneTime_h + ":" + calldoneTime_m + ":" + calldoneTime_s;

                                this.RecordTime.total_time = "";
                                this.RecordTime.time_info = "通話開始時間 : " + callstartTime_time + " -- 結束時間 : " + calldoneTime_time;
                                this.RecordTime.total_time = "此次通話長度 : " + Math.round((calldoneTime.getTime() - callstartTime.getTime()) / 1000) + " 秒";

                                this.RecordTimeArray.push(this.RecordTime);

                            }

                        }
                    }

                    console.dir(this.RecordTimeArray);


                    this.totalcalltime = this.totalcalltime + thiscalltotaltime;
                    this.totalcalltime = Math.round(this.totalcalltime);

                    console.log("totalcalltime = " + this.totalcalltime);
                    this.newMessage.talkSecond = this.totalcalltime.toString();



                    this.isLoading = false;
                },
                error: function (error) {
                    alert("Error: " + error.code + " " + error.message);
                    this.isLoading = false;
                }
            });
        }


    }



    /**
     * @method uploadRequirement
     * @param taskObjId
     * @param elder_objectId
     * @param requirementContent
     */
    uploadRequirement(taskObjId, elder_objectId, requirementContent) {
        console.log("in uploadRequirement");
        if (requirementContent.length <= 0) {
            return Promise.resolve("No req data")
        }
        //creatorPointer
        var creatorPointer = Parse.Object.extend("_User");
        var creator_pointer = new creatorPointer();
        creator_pointer.id = this.currentUser.id;

        console.log("in uploadRequirement creator_pointer.id=" + creator_pointer.id);


        //elderPointer
        var elderPointer = Parse.Object.extend("_User");
        var elder_pointer = new elderPointer();
        elder_pointer.id = elder_objectId;

        console.log("in uploadRequirement elder_pointer.id=" + elder_pointer.id);

        //parentPointer
        var parentPointer = Parse.Object.extend("MessageBoard");
        var parent_pointer = new parentPointer();
        parent_pointer.id = taskObjId;

        console.log("in uploadRequirement parent_pointer.id =" + parent_pointer.id);

        var Task = Parse.Object.extend("RequirementList");
        var taskk = new Task();
        taskk.set("parent", parent_pointer);
        taskk.set("user", elder_pointer);
        taskk.set("creator", creator_pointer);
        taskk.set("content", requirementContent);
        taskk.set("status", Number(0));

        return taskk.save(null/*, {
                success: (task) => {
                    console.log("save requirement success");
                    this.errorlog("Success", true, taskObjId);

                },
                error: function (error) {
                    console.log("save requirement fail");


                }
            }*/);
    }


    /**
     * @method uploadDataTomessageBoardClass

     */
    uploadDataTomessageBoardClass(parseFile, thumbParseFile, pointer, elder_mabowid, talkSecond, content, moodNum, requirementContent, postSubTag, detailRecordContent) {
        console.log("in uploadDataTomessageBoardClass()");
        //return new Promise((resolve, reject) => {
        if (content.length <= 0) {
            return Promise.resolve(" no message data");
        }
        console.log("uploadDataTomessageBoardClass...")
        var Task = Parse.Object.extend("MessageBoard");
        //create a new ACL
        var roleACL = new Parse.ACL();
        roleACL.setRoleWriteAccess(this.adminACLString, true);
        roleACL.setRoleReadAccess(this.adminACLString, true);
        roleACL.setRoleWriteAccess(this.userACLString, true);
        roleACL.setRoleReadAccess(this.userACLString, true);
        roleACL.setRoleReadAccess(this.readonlyAdminACLString, true);
        roleACL.setRoleWriteAccess(this.readonlyAdminACLString, false);


        var task = new Task();
        task.set("UserKey", pointer);
        task.set("MABOW_ID", elder_mabowid);
        task.set("talkInSecond", talkSecond);
        task.set("MESSAGE", content);
        task.set("MOOD_PIC", moodNum);
        task.set("USER_TYPE", Number(1));
        if (parseFile != null && typeof parseFile !== "string")
            task.set("ImageUpload", parseFile);
        if (thumbParseFile != null && typeof thumbParseFile !== "string")
            task.set("ImageUploadSmall", thumbParseFile);
        task.set("USER_NAME", this.currentUser.attributes.userNickname);
        task.set("USER_ID", this.currentUser.attributes.username);
        task.set("DetailRecord", detailRecordContent);
        task.set("MessageSubTag", postSubTag);

        console.log("userACLStringuserACLString = " + this.userACLString);
        task.setACL(roleACL);
        if (requirementContent.length > 0) {
            task.set("Requirement_Content", requirementContent);
        }
        console.log("start to save message object");

        return task.save(null);

        //   });

    }

    /**
    * @method imageToUpload
    * @return parsefile
    */
    imageToUpload() {
        console.log("in imageToUpload");
        if (this.imageFile == null) {
            console.log("in imageToUpload null this.imageFile = " + this.imageFile);
            return Parse.Promise.resolve("");
        }
        var file_name = "smallimage.jpg";
        var parseFile = new Parse.File(file_name, this.imageFile);
        return parseFile.save();
    }

    /**
    * @method thumbnailImageToUpload
    * @return parsefile
    */
    thumbnailImageToUpload() {
        console.log("in thumbnailImageToUpload");
        if (this.imageFile == null) {
            console.log("in thumbnailImageToUpload null");
            return Parse.Promise.resolve("");
        }
        return this.processfile(this.imageFile).then((_base64: any) => {
            console.log("in thumbnailImageToUpload/2");
            //console.log(_base64)
            var thumbParseFile = new Parse.File("messageImageSmall.jpg", {
                base64: _base64
            });
            return thumbParseFile.save();
        }).catch((e) => {
            return Parse.Promise.error(e)
        })
    }
    /**
     * @method processfile
     * @return base64
     */
    processfile(file) {
        return new Promise((resolve, reject) => {
            // read the files
            console.log("in processfile");
            console.dir(file);
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (event: any) => {
                // blob stuff
                console.log("in reader.onload");
                var blob = new Blob([event.target.result]); // create blob...

                var blobURL = (window.URL || window.webkitURL).createObjectURL(blob); // and get it's URL
                console.log("blobURL=" + blobURL);

                // helper Image object
                var image = new Image();
                image.src = blobURL;
                //preview.appendChild(image); // preview commented out, I am using the canvas instead
                image.onload = () => {
                    // have to wait till it's loaded
                    var resized = this.resizeMe(image); // send it to canvas
                    //console.log("resized = " + resized);

                    var base64 = resized.split('base64,')[1];

                    resolve(base64);

                    //if (callback) {
                    //  callback(thumbParseFile);
                    //}

                }
            };
        });
    }

    resizeMe(img) {
        console.log("in resizeMe");
        var max_width = 640;
        var max_height = 480;
        var canvas = document.createElement('canvas');
        var width = img.width;
        var height = img.height;
        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                //height *= max_width / width;
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                //width *= max_height / height;
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg", 0.5); // get the data from canvas as 70% JPG (can be also PNG, etc.)

    }

    enableInput() {
        console.log("enableInput Input");
        $("input").prop('disabled', false);
        $("button").prop('disabled', false);

    }

    disableInput() {
        console.log("disable Input");
        $("input").prop('disabled', true);
        $("button").prop('disabled', true);


    }


    finalcallback(code, isSuccess, backtoprepare) {
        console.log("in finalcallback code=" + code + ", issuccess=" + isSuccess);
        //submit messageboard success and save recordinfo
        alert(code);
        // save record info and redirect to success page
        if (isSuccess) {
            if (code == "Success") {
                this.isPostSuccess = true;
            }

            if (backtoprepare)
                this.router.navigateByUrl('/prepareCare');
            else
                this.router.navigateByUrl('/eldermanagementdetail');
            return;
        }
        this.enableInput();
        this.isLoading = false;
    }

    /***
     * saveRecordInfoObject
     * @param messageboardObjeciId
     */
    saveRecordInfoObject(messageboardObjeciId?) {
        console.log("in saveRecordInfoObject messageboardObjeciId= " + messageboardObjeciId);
        var roleACL = new Parse.ACL();
        roleACL.setRoleWriteAccess(this.adminACLString, true);
        roleACL.setRoleReadAccess(this.adminACLString, true);
        roleACL.setRoleWriteAccess(this.userACLString, true);
        roleACL.setRoleReadAccess(this.userACLString, true);
        roleACL.setRoleReadAccess(this.readonlyAdminACLString, true);
        roleACL.setRoleWriteAccess(this.readonlyAdminACLString, false);
        if (messageboardObjeciId != null) {
            var parentPointer = Parse.Object.extend("MessageBoard");
            var parent_pointer = new parentPointer();
            parent_pointer.id = messageboardObjeciId;
        }
        var recordHelpDetail = this.newRecordInfo.helpDetail;
        var recordNote = this.newRecordInfo.note;
        this.CareRecordInfoObject.set("relateMessageBoard", parent_pointer);
        this.CareRecordInfoObject.set("isThisClassFillComplete", true);
        this.CareRecordInfoObject.set("calldonetime", this.calldonedate);
        this.CareRecordInfoObject.set("endtime", new Date());
        this.CareRecordInfoObject.set("note", recordNote);
        this.CareRecordInfoObject.set("helpDetail", recordHelpDetail);
        this.CareRecordInfoObject.set("careRecordMediaFile", this.CareRecordMediaFile);
        this.CareRecordInfoObject.set("isDone", this.addMessageDefinea.isDone);
        this.CareRecordInfoObject.set("fail_reason", Number(this.newMessage.fail_reason));
        this.CareRecordInfoObject.set("video_quality", Number(this.newMessage.video_quality));
        this.CareRecordInfoObject.set("postISecond", Number(this.postItime));
        this.CareRecordInfoObject.setACL(roleACL);
        console.log("in saveRecordInfoObject/ start to save message object");
        return this.CareRecordInfoObject.save(null);
    }

    retrySaveRecordInfoObject(messageboardObjeciId) {
        console.log("in messageboardObjeciId messageboardObjeciId= " + messageboardObjeciId);
        this.saveRecordInfoObject(messageboardObjeciId);
    }
    video_upload_aws(file) {
        console.log("in video_upload_aws()");

        $('#example1').progress({
            percent: 0
        });
        this.upload_percent = 0;
        var LargeFileName = this.prepareUplaodLargeFile();
        var FullName = 'LargeFile/' + LargeFileName;  //folder + file name;
        var AWS = require('aws-sdk');
        AWS.config.update({
            accessKeyId: 'AKIA2BXVYOOAYUWKRVNB',   //awsConfig.key
            secretAccessKey: '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'    //awsConfig.secret
        });
        var s3 = new AWS.S3({
            params: {
                Bucket: 'elasticbeanstalk-ap-northeast-1-690931397505',   //awsConfig.s3.bucket,
                Key: FullName, //檔案名稱
                ACL: 'public-read' //檔案權限
            }
        });
        s3.upload({
            Body: file
        }).on('httpUploadProgress', (evt) => {
            console.log(evt);
            var p = Math.round(evt.loaded / evt.total * 100);
            console.log(p + '% done');
            this.upload_percent = p;
            $('#example1').progress({
                percent: p
            });
        }).send((err, data) => {
            console.log("upload s3 err=" + err);
            console.dir(data);
            console.log("upload s3 finish.........");
            //上傳完畢或是碰到錯誤

            if (err == null) {
                console.log("url=" + data.Location);
                var RecordVideo = Parse.Object.extend("LargeFile");
                var parseRecordVideo = new RecordVideo();
                parseRecordVideo.set("type", 1);
                parseRecordVideo.set("url", data.Location);
                var postACL = new Parse.ACL();
                postACL.setPublicReadAccess(true);
                parseRecordVideo.setACL(postACL);
                parseRecordVideo.save(null, {
                    success: (LargeFile) => {
                        // Execute any logic that should take place after the object is saved.
                        console.log('New object created with objectId: ' + LargeFile.id);
                        this.CareRecordInfoObject.set("careRecordInfoV2", LargeFile);
                    },
                    error: function (data, error) {
                        // Execute any logic that should take place if the save fails.
                        // error is a Parse.Error with an error code and message.
                        alert('上傳失敗 錯誤：' + error.message);
                    }
                });
            } else {
                this.upload_percent = 0;
                $('#example1').progress({
                    percent: 0
                });
            }
        });
    }

    prepareUplaodLargeFile = () => {
        console.log('in prepareUplaod')
        let date = new Date();
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        console.log('blob date = ' + date + " Y=" + Y + " M=" + M + " D=" + D + " h=" + h + " m=" + m + " s=" + s);
        let MM = ('0' + M).substr(-2);
        let DD = ('0' + D).substr(-2);
        let hh = ('0' + h).substr(-2);
        let mm = ('0' + m).substr(-2);
        let ss = ('0' + s).substr(-2);
        //let filename = Y.toString() + M.toString() + D.toString() + h.toString() + m.toString() + s.toString() + ".webm";
        let filename = Y + MM + DD + "_" + hh + mm + ss + ".webm";
        console.log('largefile filename = ' + filename);
        return filename;
    }

    /**
     * @param config
     * @param file
     */
    video_upload_init(config, file) {

        console.log("in video_upload_init()");
        this.isLoading = true;
        config.file = file;
        var state = this.initializeState(config);
        var sasUrl = ""
        var parseFileName = ""
        this.getSASurl(state, config).then((responseData) => {
            console.dir(responseData);
            console.log("in getSASurl -> success");
            parseFileName = responseData.fileName;
            sasUrl = responseData.sasUrl;

            var file = [123];
            var name = parseFileName;
            console.log(name)
            var parseFile = new Parse.File(name, file);

            return parseFile.save();


        }).then((parseFileObj) => {
            if (parseFileObj) {
                this.CareRecordInfoObject.set("careRecordFile", parseFileObj);
            }
            return;

        }).then(() => {
            this.videoUpload(state, sasUrl, config);
            this.upload_percent = 0;
            $('#example1').progress({
                percent: 0
            });
        }).catch((e) => {
            console.error("in getSASurl exception " + e);
            console.dir(e)
            this.finalcallback("出現問題，請再試一次", false, false);
        });

    }

    /***
     *
     */
    initializeState(config) {
        console.log("in initializeState");
        var blockSize = this.DefaultBlockSize;
        console.dir(config);


        if (config.blockSize) blockSize = config.blockSize;

        var maxBlockSize = blockSize; // Default Block Size
        var numberOfBlocks = 1;

        var file = config.file;

        var fileSize = file.size;
        if (fileSize < blockSize) {
            maxBlockSize = fileSize;

            console.log("max block size = " + maxBlockSize);
        }

        if (fileSize % maxBlockSize == 0) {
            numberOfBlocks = fileSize / maxBlockSize;
        } else {
            // numberOfBlocks = parseInt(fileSize / maxBlockSize, 10) + 1;
            numberOfBlocks = (fileSize / maxBlockSize) + 1;
        }

        //$log.log("total blocks = " + numberOfBlocks);
        console.log("total blocks = " + numberOfBlocks);
        return {
            maxBlockSize: maxBlockSize, //Each file will be split in 256 KB.
            numberOfBlocks: numberOfBlocks,
            totalBytesRemaining: fileSize,
            currentFilePointer: 0,
            blockIds: new Array(),
            blockIdPrefix: 'block-',
            bytesUploaded: 0,
            submitUri: null,
            file: file,
            baseUrl: config.baseUrl,
            sasToken: config.sasToken,
            //fileUrl: config.baseUrl + config.sasToken,
            //fileUrl: "https://alexprivatestorage.blob.core.windows.net/test/" + file.name + "?sr=c&sp=w&sig=w7GzSHvCZ3U0kr8HjRHI/06NtxTMdVkuUQ%2B7gMclb5k%3D&sv=2015-07-08&se=2016-12-10T08%3A29%3A08Z",
            progress: config.progress,
            complete: config.complete,
            error: config.error,
            cancelled: false
        };
    }

    /***
     *
     */
    getSASurl(state, config) {
        console.log("in getSASurl");
        var fileName = this.guid() + ".mp4";
        var input = { objID: this.CareRecordInfoObject.id, blobName: fileName, container: "test", className: "CareRecordInfo", account: "alexprivatestorage" }
        return Parse.Cloud.run('getWriteShareAccessKey', input)
    }
    /**
     *
     */
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + s4() + s4() +
            s4() + s4() + s4() + s4();
    }

    /**
     * @param state
     * @param FullUploadUrl
     * @param config
     */
    videoUpload(state, FullUploadUrl, config) {
        console.log("in videoUpload ()");
        this.CareRecordMediaFile = this.upload_azure_config.baseUrl + state.file.name;
        console.log("file url = " + this.CareRecordMediaFile);
        console.log("Full file url = " + FullUploadUrl);
        var reader = new FileReader();
        //reader.readAsArrayBuffer(config.file);
        reader.onloadend = (evt: any) => {
            console.log("in onloadend");
            if (evt.target.readyState == 2 && !state.cancelled) { // DONE == 2
                var requestData = new Uint8Array(evt.target.result);
                //var uri = state.fileUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1];
                var uri = FullUploadUrl + '&comp=block&blockid=' + state.blockIds[state.blockIds.length - 1];
                console.log("uri=" + uri);
                var xmlHttp = new XMLHttpRequest();
                xmlHttp.open("PUT", uri, false); // false for synchronous request
                xmlHttp.setRequestHeader('Content-Type', 'video/mp4');
                xmlHttp.setRequestHeader('x-ms-blob-type', 'BlockBlob');
                xmlHttp.onreadystatechange = () => {
                    console.log("xmlHttp.readyState=" + xmlHttp.readyState);
                    console.log("XMLHttpRequest=" + xmlHttp.readyState);
                    if (xmlHttp.readyState == XMLHttpRequest.DONE) {
                        console.log("XMLHttpRequest DONE equestData.length = " + requestData.length);
                        state.bytesUploaded += requestData.length;
                        //var percentComplete = ((parseFloat(state.bytesUploaded) / parseFloat(state.file.size)) * 100).toFixed(2);
                        var percentComplete = (((state.bytesUploaded) / parseFloat(state.file.size)) * 100).toFixed(2);
                        console.log("XMLHttpRequest DONE = " + percentComplete + " %");
                        this.uploadFileInBlocks(reader, state, FullUploadUrl);
                        var p = Math.floor(Number(percentComplete));
                        this.upload_percent = p;
                        $('#example1').progress({
                            percent: p
                        });
                    }
                }
                xmlHttp.send(requestData);
                console.log("http put");

            }
        };
        this.uploadFileInBlocks(reader, state, FullUploadUrl);
        console.log("in upload test");
        return {
            cancel: function () {
                state.cancelled = true;
            }
        };
    }
    /***
     *
     */
    uploadFileInBlocks(reader, state, FullUploadUrl) {
        console.log("in uploadFileInBlocks");
        if (!state.cancelled) {
            if (state.totalBytesRemaining > 0) {
                console.log("current file pointer = " + state.currentFilePointer + " bytes read = " + state.maxBlockSize);

                var fileContent = state.file.slice(state.currentFilePointer, state.currentFilePointer + state.maxBlockSize);
                var blockId = state.blockIdPrefix + this.pad(state.blockIds.length, 6);
                console.log("block id = " + blockId);
                state.blockIds.push(btoa(blockId));
                reader.readAsArrayBuffer(fileContent);
                state.currentFilePointer += state.maxBlockSize;
                state.totalBytesRemaining -= state.maxBlockSize;
                if (state.totalBytesRemaining < state.maxBlockSize) {
                    state.maxBlockSize = state.totalBytesRemaining;
                }
            } else {
                this.commitBlockList(state, FullUploadUrl);
            }
        }
    }
    /***
     *
     */
    commitBlockList(state, FullUploadUrl) {
        console.log("in commitBlockList");
        var uri = state.fileUrl + '&comp=blocklist';
        console.log(uri);
        var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for (var i = 0; i < state.blockIds.length; i++) {
            requestBody += '<Latest>' + state.blockIds[i] + '</Latest>';
        }
        requestBody += '</BlockList>';
        console.log(requestBody);
        //var uri = state.fileUrl + '&comp=blocklist';
        var uri = FullUploadUrl + '&comp=blocklist';
        console.log("commilt list uri=" + uri);
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open("PUT", uri, true); // false for synchronous request
        xmlHttp.setRequestHeader('Content-Type', 'video/mp4');
        xmlHttp.onreadystatechange = () => {
            console.log("xmlHttp.readyState=" + xmlHttp.readyState);
            console.log("XMLHttpRequest=" + xmlHttp.readyState);
            if (xmlHttp.readyState == XMLHttpRequest.DONE) {
                //alert(xmlHttp.getAllResponseHeaders());
                alert("記錄影片 上傳完成！");
                console.log("XMLHttpRequest commit list DONE");
                this.isLoading = false;
            }
        }
        xmlHttp.send(requestBody);
    }

    pad(number, length) {
        console.log("in pad");
        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }

    /**
    * @method backtoprepare
    */
    backtoprepare() {
        console.log("submitPost and back to prepare ..........");
        if (this.submitCheckisOk() == false) {
            console.error("submit pist/ check require fail so return");
            return;
        }
        // confirm again d
        var r = confirm("確定要上傳嗎？\n• 送出i 之後，開啟 app 檢查是否 i 訊息正確無誤\n• 下次時間登記至 google 日曆\n• 登出 通訊/雲端 帳號");
        if (r != true) {
            console.log("/submit/ user deny uploading");
            return;
        }
        this.isLoading = true;
        // disable all input
        this.disableInput();
        var talkingTime = this.newMessage.talkSecond;
        var content = this.newMessage.messageContentAll;
        var requirementContent = this.newMessage.wish;
        var moodNum = this.newMessage.mood;
        var detailRecordContent = this.newMessage.detailRecord;
        var postSubTag = this.choosePostTag;

        this.userACLString = this.Elder_PhoneId.concat("_eng");
        console.log("ready to save message object");
        var Task = Parse.Object.extend("MessageBoard");
        var Pointer = Parse.Object.extend("_User");
        var pointer = new Pointer();
        pointer.id = this.currentUser.id;
        var messageboardObjeciId;
        this.saveRecordInfoObject(null).then(() => {
            this.finalcallback("Success", true, true)
        }).catch((e) => {
            console.error('Failed to create new object, with error code: ' + e);
            console.dir(e)
            this.finalcallback("錯誤，通常是沒有訂閱", false, true);

        });
    }

}
