import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { KTVSongInfo } from './addKTVSongs';
import { LiveKtvsingerPipe2Pipe } from './live-ktvsinger-pipe2.pipe';

declare var $: any;
declare var Parse: any;


@Component({
  selector: 'addKTVSongs',
  templateUrl: 'addKTVSongs.component.html',
  styleUrls: ['addKTVSongs.component.css']
})

export class AddKTVSongsComponent implements OnInit {

  ktvSongs: KTVSongInfo;
  ktvSongsCheck: KTVSongInfo;
  songFile;
  lyricFile;
  upload_percent = 0;

  ktvSongsInfoArray = [];
  ktvSingerArray = [];
  ktvSongList = [];
  doSubmitKTVSong = false;
  doSubmitKTVSongLyrics = false;
  doCheckKTVSong = false;
  isBothFuncActivated = false;
  isSongFileStartUpload = false;
  isSongFileUploaded = false;
  KTVSongs_amount = 0;
  isSongIndexChecking = true;
  isSongUploadingToParse = false;
  SelectSinger;
  SelectSong;
  song_url;
  song_objectId;
  lyric_url;
  singer;
  isLrcNeedStart = false;
  KTVSongs_object;
  KTVSongs_objectId;
  parseFile;
  isLyricFileUploaded = false;
  isLyricUploadingToParse = false;
  showLyricGuide = false;
  isLoading = false;
  lrc_timer;
  time_line = [];
  lrc_line = [];
  scoll_index = 0;
  showFullsonglist = false;
  isonSongInfoSelectPlayMV = false;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private _location: Location
  ) {
    this.ktvSongs = new KTVSongInfo();
    this.ktvSongsCheck = new KTVSongInfo();
    /*
    window.onbeforeunload = function () {
      return 'window close';
    };*/
  }
  ngOnInit(): void {
    $('#progress1').progress({
      percent: this.upload_percent
    });
    $('.dropdown').dropdown({
      label: {
        duration: 0,
      },
      debug: false,
      performance: true,
    });
    this.queryKTVSongInfo();

  }

  songfileChangeEvent(fileInput: any) {

    var files = fileInput.target.files || fileInput.dataTransfer.files;
    // Our file var now holds the selected file
    this.songFile = files[0];
    console.dir(this.songFile);

    var ext = $('#songfileselect').val().split('.').pop().toLowerCase();
    /*if ($.inArray(ext, ['mp4']) == -1 || $.inArray(ext, ['mpg']) == -1) {
      alert('檔案格式不符!請重新選擇');
      return false;
    }*/
  }

  uploadSongToS3() {

    if (this.songFile != null) {
      this.isSongFileUploaded = false;
      this.isSongFileStartUpload = true;
      $('#progress1').progress({
        percent: 0
      });
      this.upload_percent = 0;

      // TODO: parse the input of singer and song -> $singer-$song.mp4
      var SongFileName = $('#songfileselect').val().split('\\').pop();
      var SongFileType = SongFileName.split(".").pop();
      console.log("SongFileName=" + SongFileName + " type=" + SongFileType);
      const dateTime = Date.now();
      console.log("timestamp=" + dateTime);

      //var S3Bucket = 'elasticbeanstalk-ap-northeast-1-690931397505' //awsConfig.s3.bucket(old without CDN)
      //var SongFilePath = 'KTV_Songs/' + dateTime + '.mp4'; //old path
      var S3Bucket = 'ktv-storage-2' //awsConfig.s3.bucket(new with CDN)
      var SongFilePath = 'un_transcoded/' + dateTime + '.' + SongFileType; //new path

      var AWS = require('aws-sdk');
      AWS.config.update({
        accessKeyId: 'AKIA2BXVYOOAYUWKRVNB',   //awsConfig.key
        secretAccessKey: '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'    //awsConfig.secret
      });

      var s3 = new AWS.S3({
        params: {
          Bucket: S3Bucket,   //awsConfig.s3.bucket
          Key: SongFilePath, //檔案路徑＋名稱
          ACL: 'public-read' //檔案權限
        }
      });

      s3.upload({
        Body: this.songFile
      }).on('httpUploadProgress', (evt) => {

        //上傳進度

        //console.log(evt);
        var p = Math.round(evt.loaded / evt.total * 100);
        console.log(p + '% done');
        this.upload_percent = p;
        $('#progress1').progress({
          percent: p
        });

      }).send((err, data) => {
        if (err) {
          console.log("upload s3 err=" + err);
          alert("上傳歌曲發生錯誤")
        }
        console.dir(data);
        console.log("upload s3 finish.........");
        //上傳完畢或是碰到錯誤

        if (err == null) {
          console.log("url=" + data.Location);
          console.log("get name = " + data.Location.split('.')[0]);
          /*var nameArray = data.Location.split('.');
          var transcode_url = "";
          for (var i = 0; i < nameArray.length - 1; i++) {
            if (i == nameArray.length - 2) {
              transcode_url = transcode_url + nameArray[i] + "_transcode.mp4";
            } else {
              transcode_url = transcode_url + nameArray[i] + ".";
            }
          }*/
          var CDN_URL = "http://d2r82lvrcdj8m9.cloudfront.net/"
          var transcode_url = CDN_URL + (data.Location.split('/').pop()).split('.')[0] + "_transcode.mp4"
          console.log("get transcode name = " + transcode_url);

          var SongVideo = Parse.Object.extend("LargeFile");
          var parseSongVideo = new SongVideo();
          parseSongVideo.set("type", 1);
          parseSongVideo.set("url", transcode_url);
          var postACL = new Parse.ACL();
          postACL.setPublicReadAccess(true);
          parseSongVideo.setACL(postACL);
          parseSongVideo.save(null, {
            success: (LargeFile) => {
              // Execute any logic that should take place after the object is saved.
              console.log('New object created with objectId: ' + LargeFile.id);
              //this.CareRecordInfoObject.set("careRecordInfoV2", LargeFile);
              this.ktvSongs.objectId = LargeFile.id;

              //this.ktvSongs.url = data.Location;
              this.ktvSongs.url = transcode_url;
              this.isSongFileUploaded = true;
              this.queryKTVSongInfo();
            },
            error: (data, error) => {
              this.isSongFileUploaded = false;
              alert('上傳失敗 錯誤：' + error.message);
            }
          });
        } else {
          this.upload_percent = 0;
          $('#progress').progress({
            percent: 0
          });
        }
      });


    } else {
      alert('請選擇要上傳的歌曲');
    }

  }

  submit_KTVSongs() {
    console.log("in submit_KTVSongs");
    var alert_str = "以下資訊不可為空白:";
    var show_alert = false;

    if (this.ktvSongs.index == "" || this.ktvSongs.index == null) {
      alert_str = alert_str + "\n歌曲編號";
      show_alert = true;
    }
    if (this.ktvSongs.language == "" || this.ktvSongs.language == null) {
      alert_str = alert_str + "\n語言";
      show_alert = true;
    }
    if (this.ktvSongs.name == "" || this.ktvSongs.name == null) {
      alert_str = alert_str + "\n歌名";
      show_alert = true;
    }
    if (this.ktvSongs.singer == "" || this.ktvSongs.singer == null) {
      alert_str = alert_str + "\n歌手";
      show_alert = true;
    }
    if (this.ktvSongs.singer == null) {
      alert_str = alert_str + "\n導唱";
      show_alert = true;
    }
    if (this.ktvSongs.url == "" || this.ktvSongs.url == null) {
      alert_str = alert_str + "\n歌曲網址";
      show_alert = true;
    }
    if (show_alert) { alert(alert_str); return; }

    if (!confirm("歌名:" + this.ktvSongs.name + "\n"
      + "歌手:" + this.ktvSongs.singer + "\n"
      + "語言:" + this.ktvSongs.language + "\n"
      //+ "導唱:" + this.ktvSongs.singersing + "\n"
      + "編號:" + this.ktvSongs.index + "\n"
      + "請確認歌單資訊是否正確並上傳?")) {
      return;
    }

    console.log("prepare to Parse KTVSongs");
    this.isSongUploadingToParse = true;

    var pKTVSongs = Parse.Object.extend("KTVSongs");
    var parseKTVSongs = new pKTVSongs();

    parseKTVSongs.set("SongIndex", this.ktvSongs.index);
    parseKTVSongs.set("SongLanguage", this.ktvSongs.language);
    parseKTVSongs.set("SongName", this.ktvSongs.name);
    parseKTVSongs.set("Singer", this.ktvSongs.singer);
    //parseKTVSongs.set("SingerSing", this.ktvSongs.singersing == "有" ? true : false);
    var LargeFilePointer = Parse.Object.extend("LargeFile");
    var ktvSong_pointer = new LargeFilePointer();
    ktvSong_pointer.id = this.ktvSongs.objectId;
    parseKTVSongs.set("KTVSongFile", ktvSong_pointer);

    var postACL = new Parse.ACL();
    postACL.setPublicReadAccess(true);
    postACL.setPublicWriteAccess(true);
    parseKTVSongs.setACL(postACL);

    parseKTVSongs.save(null, {
      success: (object) => {

        console.log('New parseKTVSongs created with objectId: ' + object.id);
        this.KTVSongs_objectId = object.id
        //this.CareRecordInfoObject.set("careRecordInfoV2", LargeFile);
        this.isSongUploadingToParse = false;
        alert("已成功更新歌單");
        this.refresh_data();
      },
      error: (data, error) => {
        this.isSongUploadingToParse = false;
        alert('上傳失敗 錯誤：' + error.message);
      }
    });
  }

  show_hide_lyric_guide() {
    if (this.showLyricGuide == true) {
      this.showLyricGuide = false;
    } else {
      this.showLyricGuide = true;
    }
  }

  findKTVSongfromParse() {
    console.log('in findKTVSongfromParse()');

    var pKTVSongs = Parse.Object.extend("KTVSongs");
    var parseKTVSongs = new Parse.Query(pKTVSongs);
    parseKTVSongs.equalTo("SongLanguage", this.ktvSongs.language);
    parseKTVSongs.equalTo("SongName", this.ktvSongs.name);
    parseKTVSongs.equalTo("Singer", this.ktvSongs.singer);
    //parseKTVSongs.equalTo("SingerSing", this.ktvSongs.singersing == "有" ? true : false);
    parseKTVSongs.find({
      success: (KTVSongList) => {
        if (KTVSongList.length == 0) {
          alert("查無符合這些條件的歌曲");
        } else if (KTVSongList.length > 1) {
          alert("找到符合此條件的歌曲超過一首 請恰工程師檢查");
        } else {
          alert('找到一首歌曲');
          this.KTVSongs_objectId = KTVSongList[0].id;  // objectId of KTVSongs
          this.KTVSongs_object = KTVSongList[0];
          console.log('KTVSongs_object SongName=' + this.KTVSongs_object.get("SongName"));
        }
      },
      error: (error) => {
        alert("無法找到歌曲 錯誤 : " + error.code + " " + error.message);
      }
    });

  }

  findKTVSongfromObjectId() {
    console.log('in findKTVSongfromObjectId()');
    var key = prompt("請輸入工程密碼", "");
    if (key == "0000") {
      var pKTVSongs = Parse.Object.extend("KTVSongs");
      var parseKTVSongs = new Parse.Query(pKTVSongs);
      parseKTVSongs.get(this.KTVSongs_objectId, {
        success: (KTVSong) => {
          alert('找到一首歌曲');
          this.KTVSongs_object = KTVSong;
          console.log('KTVSongs_object SongName=' + this.KTVSongs_object.get("SongName"));
        },
        error: (object, error) => {
          alert("無法找到歌曲 錯誤 : " + error.code + " " + error.message);
        }
      });
    } else if (key != null) {
      alert('密碼錯誤');
    }
  }

  lyricfileChangeEvent(fileInput: any) {

    var files = fileInput.target.files || fileInput.dataTransfer.files;
    // Our file var now holds the selected file
    this.lyricFile = files[0];
    console.dir(this.lyricFile);

    var ext = $('#lyricfileselect').val().split('.').pop().toLowerCase();
    if ($.inArray(ext, ['txt']) == -1) {
      alert('檔案格式不符!請重新選擇');
      return false;
    }
  }

  uploadLyricToParse() {
    console.log('in uploadLyricToParse()');

    if (this.KTVSongs_object) {
      this.isLyricUploadingToParse = true;
      var FileNmae = this.KTVSongs_objectId + '.txt';
      var parseFileUpload = new Parse.File(FileNmae, this.lyricFile);
      parseFileUpload.save().then((parseFileObj) => {
        console.log('parseFile save success');
        this.isLyricFileUploaded = true;
        this.parseFile = parseFileObj;
        this.isLyricUploadingToParse = false;

      }, (error) => {
        this.isLyricUploadingToParse = false;
        alert("上傳歌詞失敗 : " + error.message);
      });
    } else {
      alert("請先確認要上傳歌詞的歌曲")
    }
  }

  updateLyricToKTVSongs() {
    console.log('in updateLyricToKTVSongs() id=' + this.KTVSongs_objectId);

    if (this.KTVSongs_object) {
      this.isLyricUploadingToParse = true;
      var pKTVSongs = Parse.Object.extend("KTVSongs");
      var parseKTVSongs = new Parse.Query(pKTVSongs);
      parseKTVSongs.get(this.KTVSongs_objectId, {
        success: (KTVSong) => {
          KTVSong.set("lyricFile", this.parseFile);
          KTVSong.save(null, {
            success: (object) => {
              this.isLyricUploadingToParse = false;
              console.log('lyricFile update success : ');
              alert("已成功更新歌詞");
              this.refresh_data();
            },
            error: (data, error) => {
              this.isLyricUploadingToParse = false;
              alert('更新歌詞錯誤：' + error.message);
            }
          });
        },
        error: (object, error) => {
          this.isLyricUploadingToParse = false;
          alert("無法找到歌曲 錯誤 : " + error.code + " " + error.message);
        }
      });

    } else {
      alert("請先確認要上傳歌詞的歌曲")
    }
  }

  edit_KTVSongs() {
    if (this.doSubmitKTVSong == false) {
      this.doSubmitKTVSong = true;
    } else {
      this.doSubmitKTVSong = false;
    }
    this.check_display_split_line();
  }

  edit_KTVSongs_lyrics() {

    if (this.doSubmitKTVSongLyrics == false) {
      this.doSubmitKTVSongLyrics = true;
    } else {
      this.doSubmitKTVSongLyrics = false;
    }
    this.check_display_split_line();
  }

  check_KTVSongs() {
    if (this.doCheckKTVSong == false) {
      this.doCheckKTVSong = true;
    } else {
      this.doCheckKTVSong = false;
    }
    this.check_display_split_line();
  }

  check_display_split_line() {
    if ((this.doSubmitKTVSong && this.doSubmitKTVSongLyrics)
      || (this.doSubmitKTVSong && this.doCheckKTVSong)
      || (this.doSubmitKTVSongLyrics && this.doCheckKTVSong)) {
      this.isBothFuncActivated = true;
    } else {
      this.isBothFuncActivated = false;
    }


  }

  refresh_data() {
    window.location.reload();
  }

  queryKTVSongInfo() {
    console.log("in queryKTVSongInfo");
    this.KTVSongs_amount = 0;
    this.isSongIndexChecking = true;
    this.queryKTVSongInfoData(0);
    this.isLoading = true;
  }

  queryKTVSongInfoData(index) {
    console.log("in queryKTVSongInfoData " + index);
    let KTVParse = Parse.Object.extend("KTVSongs");
    let query = new Parse.Query(KTVParse);
    query.include('KTVSongFile');
    query.include('lyricFile');
    query.limit(100);
    query.skip(index * 100);
    query.find({
      success: (results) => {
        console.log("queryKTVSongSinger/ Successfully retrieved " + results.length + " songs");
        if (results.length <= 0) {
          this.ktvSongs.index = this.paddingSongIndex((this.KTVSongs_amount + 1) + '', 6);
          this.isSongIndexChecking = false;
          this.isLoading = false
          return
        }
        this.KTVSongs_amount = this.KTVSongs_amount + results.length;
        for (var i = 0; i < results.length; i++) {
          let songObject = results[i];
          let ktvSongInfo = new KTVSongInfo();

          ktvSongInfo.objectId = songObject.id;
          ktvSongInfo.index = songObject.get("SongIndex");
          ktvSongInfo.language = songObject.get("SongLanguage");
          //ktvSongInfo.singersing = songObject.get("SingerSing") ? "有" : "無";
          ktvSongInfo.name = songObject.get("SongName");
          ktvSongInfo.singer = songObject.get("Singer");
          if (songObject.get("lyricFile") != null) {
            //console.log("found lyric of this song");
            ktvSongInfo.lyric_url = songObject.get("lyricFile").url();
          } else {
            //console.log("not found lyric of this song");
            ktvSongInfo.lyric_url = "no_lyric";
          }

          if (songObject.get("KTVSongFile"))
            ktvSongInfo.url = songObject.get("KTVSongFile").get("url");
          else
            ktvSongInfo.url = "NOT_UPLOADED";
          this.ktvSongsInfoArray.push(ktvSongInfo);

          var SingerListExist = false;
          for (var j = 0; j < this.ktvSingerArray.length; j++) {
            if (ktvSongInfo.singer == this.ktvSingerArray[j])
              SingerListExist = true;
          }
          if (!SingerListExist)
            this.ktvSingerArray.push(ktvSongInfo.singer);
        }
        console.dir(this.ktvSingerArray);
        if (results.length == 100) {

          console.log("parse again, skip " + (index + 1) * 100 + " data");
          this.queryKTVSongInfoData(index + 1);
        } else {

          this.ktvSongs.index = this.paddingSongIndex((this.KTVSongs_amount + 1) + '', 6);
          this.isSongIndexChecking = false;
          this.isLoading = false;
          console.log("parse_get_songs done, total " + this.ktvSingerArray.length);
        }
      },
      error: (error) => {
        alert("無法找到歌單 錯誤 : " + error.code + " " + error.message);
      }
    });

  }

  /**
     * findKTVSong
     */
  findKTVSong() {
    console.log("singer=" + this.SelectSinger);
    if (this.SelectSinger == "") {
      alert("請選擇一位歌手");
      return;
    }
    //this.openKTVSongList = 1;
    this.ktvSongList = [];
    for (var i = 0; i < this.ktvSongsInfoArray.length; i++) {

      if (this.ktvSongsInfoArray[i].singer == this.SelectSinger) {
        console.log(this.ktvSongsInfoArray[i].singer + " - " + this.ktvSongsInfoArray[i].name);
        var ktvSongInfo = new KTVSongInfo();
        ktvSongInfo.index = this.ktvSongsInfoArray[i].index;
        ktvSongInfo.language = this.ktvSongsInfoArray[i].language;
        ktvSongInfo.name = this.ktvSongsInfoArray[i].name;
        ktvSongInfo.singer = this.ktvSongsInfoArray[i].singer;
        ktvSongInfo.url = this.ktvSongsInfoArray[i].url;
        ktvSongInfo.objectId = this.ktvSongsInfoArray[i].objectId;
        ktvSongInfo.lyric_url = this.ktvSongsInfoArray[i].lyric_url;
        this.ktvSongList.push(ktvSongInfo);
      }
    }
  }

  selectKTVSongList(song) {
    console.log("selectKTVSongList /song=" + song.name);

    this.song_url = song.url;
    this.SelectSong = song.name;
    this.song_objectId = song.objectId;
    this.lyric_url = song.lyric_url;
    this.singer = song.singer;
    this.showFullsonglist = false;
    this.playMV();
  }

  @ViewChild('videoPlayer', { static: false }) videoplayer: any;
  /**
     * selectKTVSong
     * play and check
     */
  selectKTVSong() {
    console.log("song=" + this.SelectSong);
    this.song_url = "";
    this.song_objectId = "";
    if (this.SelectSong == "") {
      alert("請選擇一首歌曲");
      return;
    }
    for (var i = 0; i < this.ktvSongList.length; i++) {
      if (this.SelectSong == this.ktvSongList[i].name) {
        this.song_url = this.ktvSongList[i].url;
        this.song_objectId = this.ktvSongList[i].objectId;
        this.lyric_url = this.ktvSongList[i].lyric_url;
        this.singer = this.ktvSongList[i].singer
      }
    }
    this.playMV();
  }

  playMV() {

    if (this.song_url == "NOT_UPLOADED") {
      alert("伺服器找不到此首歌曲")
      return;
    } else {
      console.log("song url=" + this.song_url);
      console.log("song objectId=" + this.song_objectId);
      console.log("song lyric_url=" + this.lyric_url);

      if (this.lyric_url == "no_lyric") {
        this.lrc_line = [];
        this.lrc_line.push("☆ ☆ ☆ 瑪帛歡樂鉅獻 ☆ ☆ ☆");
        this.lrc_line.push(this.SelectSong);
        this.lrc_line.push(this.singer);
        alert("目前還沒有上傳歌詞")

      }

      this.videoplayer.nativeElement.load();
      this.videoplayer.nativeElement.play();
      // this.testDownloadPlay();  // full download before play
      this.playLRC();
    }


  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.song_url);
  }

  testDownloadPlay() {
    console.log("in testDownloadPlay " + this.song_url);

    var req = new XMLHttpRequest();
    req.open('GET', this.song_url, true);
    req.responseType = 'blob';

    req.onload = () => {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (req.status === 200) {
        var videoBlob = req.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        // Video is now downloaded
        // and we can set it as source on the video element
        //video.src = vid;
        this.song_url = this.sanitize(vid);
        this.videoplayer.nativeElement.load();
        this.videoplayer.nativeElement.play();
      }
    }
    req.onprogress = (event) => {
      console.log('LOADING ', event.loaded + "/" + event.total);
    };
    req.onerror = function () {
      // Error
    }

    req.send();

  }

  paddingSongIndex(str, length) {

    //0~9 -> 00~09
    if (str.length < length) {
      return this.paddingSongIndex("0" + str, length);
    } else {
      return str;
    }
  }

  paddingLeft(str, length) {

    //0~9 -> 00~09
    if (str < 10 && str >= 0) {
      return ("0" + str);
    } else {
      return str;
    }
  }

  // TODO: parse multiple time in single lrc line
  parse(txt) {
    this.time_line = [];
    this.lrc_line = [];
    var lrcs = txt.split('\n');
    //console.dir(lrcs);

    for (var i = 0; i < lrcs.length; i++) {
      if (/\d/.test(lrcs[i])) {  // check if has number(time)
        if (lrcs[i].split(']')[1] != "") {
          this.lrc_line.push(lrcs[i].split(']')[1]);
        } else {
          this.lrc_line.push(" ");
        }
        this.time_line.push((lrcs[i].split('[')[1]).split(']')[0]);
      }
    }
  }

  start_lrc_timer() {
    console.log("in start_lrc_timer()");
    clearInterval(this.lrc_timer);
    var lrc_index = 0;
    var check_time = 0;
    this.scoll_index = 0;
    $('#lrc_scroll').scrollTop(0);
    this.lrc_timer = setInterval(() => {

      var currentTime = check_time;
      var video_current_min = this.paddingLeft(Math.floor(currentTime / 60), 2);
      var video_current_sec = this.paddingLeft(currentTime - video_current_min * 60, 2);
      var video_current_time = video_current_min + ":" + video_current_sec;

      if (lrc_index > 1) {
        if (this.scoll_index < (lrc_index - 2) * 30) {
          this.scoll_index = this.scoll_index + 10;
          $('#lrc_scroll').scrollTop(this.scoll_index);
        }
      }

      if (video_current_time >= this.time_line[lrc_index] && video_current_time < this.time_line[lrc_index + 1]) {
        lrc_index = lrc_index + 1;
      }
      check_time = check_time + 1;
    }, 1000);
  }

  loadLyric() {
    console.log("in loadLyric url=" + this.lyric_url);
    if (this.lyric_url == "no_file") {
      console.log("no Lyric, return");
      return;
    }
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", this.lyric_url, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status == 0) {
          var allText = rawFile.responseText;
          //this.isLrcLoaded = true;
          this.parse(allText)
          this.start_lrc_timer();

        }
      }
    }
    rawFile.send(null);
  }

  playLRC() {
    console.log("in playLRC");
    this.loadLyric();
  }

  onLanguageSelect(data: string): void {
    console.log("onLanguageSelect / " + data);
    this.ktvSongs.language = data;
  }
  onSingerSingSelect(data: string): void {
    console.log("onSingerSingSelect / " + data);
    //this.ktvSongs.singersing = data;
  }

  list_KTVSongs() {
    console.log("in list_KTVSongs");
    if (this.showFullsonglist == false) {
      this.showFullsonglist = true;
      this.doCheckKTVSong = true;
    } else {
      this.showFullsonglist = false;
      this.doCheckKTVSong = false;
    }
  }

  onSongInfoSelect(index: string): void {
    console.log("onSongInfoSelect / " + index);
    console.dir(this.ktvSongsInfoArray[index])
    //this.ktvSongsCheck.singersing = this.ktvSongsInfoArray[index].singersing;
    this.ktvSongsCheck.language = this.ktvSongsInfoArray[index].language;
    this.ktvSongsCheck.name = this.ktvSongsInfoArray[index].name;
    this.ktvSongsCheck.singer = this.ktvSongsInfoArray[index].singer;
    this.ktvSongsCheck.index = this.ktvSongsInfoArray[index].index;
    this.ktvSongsCheck.url = this.ktvSongsInfoArray[index].url;
    this.ktvSongsCheck.lyric_url = this.ktvSongsInfoArray[index].lyric_url;
    this.ktvSongsCheck.objectId = this.ktvSongsInfoArray[index].objectId;

    this.doCheckKTVSong = true;
    this.isonSongInfoSelectPlayMV = true;


  }

  onSongInfoSelectPlayMV() {
    this.song_url = this.ktvSongsCheck.url;
    this.SelectSong = this.ktvSongsCheck.name;
    this.song_objectId = this.ktvSongsCheck.objectId;
    this.lyric_url = this.ktvSongsCheck.lyric_url;
    this.singer = this.ktvSongsCheck.singer;
    this.playMV();
  }


}

