import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
declare var Parse: any;

@Component({
  selector: 'app-check-user-action-record',
  templateUrl: './check-user-action-record.component.html',
  styleUrls: ['./check-user-action-record.component.css']
})
export class CheckUserActionRecordComponent implements OnInit {

  DataArray = [1];
  MSO_TOP_count = 0;
  MSO_KBRO_count = 0
  MSO_TBC_count = 0
  MSO_CNS_count = 0
  STB_16_count = 0;
  STB_390_count = 0
  total_APP_device_count = 0;

  diff_STB = 0;
  diff_actUser = 0;
  diff_Mfriend = 0;
  diff_Mpartner = 0;
  diff_Mpaid = 0;

  icon_classSTB = "yellow minus icon"
  icon_class0 = "yellow minus icon"
  icon_class1 = "yellow minus icon"
  icon_class2 = "yellow minus icon"
  icon_class3 = "yellow minus icon"

  statisticsMabowSTBYesterday = 0;
  statisticsMabowSTBToday = 0;
  statisticsActiveUserYesterday = 0;
  statisticsActiveUserToday = 0;
  statisticsMabowFriendYesterday = 0;
  statisticsMabowFriendToday = 0;
  statisticsMabowPartnerYesterday = 0;
  statisticsMabowPartnerToday = 0;
  statisticsPaidUserYesterday = 0;
  statisticsPaidUserToday = 0;
  rangDateStr = "";
  todayStr = ""

  constructor() { }

  ngOnInit() {

    //this.getParseConfig();
    //this.checkAPPdevices();
    this.getCheckUseDateRange();
    this.getUserIntegrationData();
  }

  getCheckUseDateRange() {
    let TODAY = new Date();
    let yesterday = new Date(TODAY.getTime() - 86400000);

    let _Y = yesterday.getFullYear();
    let _M = yesterday.getMonth();

    let nextY = _Y;
    let nextM = _M + 1;
    if (_M == 11) {
      nextY = _Y + 1;
      nextM = 0;
    }
    console.log("_Y=" + _Y + " _M=" + _M)

    let firstday = new Date(_Y, _M, 1);
    let lastday = new Date((new Date(nextY, nextM, 1)).getTime() - 86400000);

    console.log("yesterday=" + yesterday);
    console.log("firstday=" + firstday);
    console.log("lastday=" + lastday);

    this.rangDateStr = (firstday.getMonth() + 1) + "/1 - " + (firstday.getMonth() + 1) + "/" + lastday.getDate();
    console.log("rangDateStr=" + this.rangDateStr)


    this.todayStr = TODAY.getFullYear() + "年" + (TODAY.getMonth() + 1) + "月" + TODAY.getDate() + "日";

  }

  getUserIntegrationData() {
    console.log("getUserIntegrationData")

    let today = new Date();
    let checkDate = new Date(today.getTime() - 2 * 86400000);

    let _UserIntegrationData = Parse.Object.extend("UserIntegrationData")
    let query = new Parse.Query(_UserIntegrationData);
    query.greaterThan("recordDate", checkDate);
    query.find().then((results) => {
      console.log("results=" + results.length);

      results.forEach((record) => {
        let _recordDate = record.get("recordDate");
        if (record.get("recordType") == "stb") {
          if (_recordDate.getTime() < today.getTime() - 86400000) {
            this.statisticsMabowSTBYesterday = record.get("recordCount");
          } else {
            this.statisticsMabowSTBToday = record.get("recordCount");
          }
        } else if (record.get("recordType") == "use") {
          if (_recordDate.getTime() < today.getTime() - 86400000) {
            this.statisticsActiveUserYesterday = record.get("recordCount");
          } else {
            this.statisticsActiveUserToday = record.get("recordCount");
          }
        } else if (record.get("recordType") == "plan_friend") {
          if (_recordDate.getTime() < today.getTime() - 86400000) {
            this.statisticsMabowFriendYesterday = record.get("recordCount");
          } else {
            this.statisticsMabowFriendToday = record.get("recordCount");
          }
        } else if (record.get("recordType") == "plan_partner") {
          if (_recordDate.getTime() < today.getTime() - 86400000) {
            this.statisticsMabowPartnerYesterday = record.get("recordCount");
          } else {
            this.statisticsMabowPartnerToday = record.get("recordCount");
          }
        } else if (record.get("recordType") == "plan_paid") {
          if (_recordDate.getTime() < today.getTime() - 86400000) {
            this.statisticsPaidUserYesterday = record.get("recordCount");
          } else {
            this.statisticsPaidUserToday = record.get("recordCount");
          }

        }
      })

      this.diff_STB = Math.abs(this.statisticsMabowSTBToday - this.statisticsMabowSTBYesterday);
      this.diff_actUser = Math.abs(this.statisticsActiveUserToday - this.statisticsActiveUserYesterday);
      this.diff_Mfriend = Math.abs(this.statisticsMabowFriendToday - this.statisticsMabowFriendYesterday);
      this.diff_Mpartner = Math.abs(this.statisticsMabowPartnerToday - this.statisticsMabowPartnerYesterday);
      this.diff_Mpaid = Math.abs(this.statisticsPaidUserToday - this.statisticsPaidUserYesterday);


      if (this.statisticsMabowSTBToday > this.statisticsMabowSTBYesterday) {
        this.icon_classSTB = "red caret up icon";
      } else if (this.statisticsMabowSTBToday < this.statisticsMabowSTBYesterday) {
        this.icon_classSTB = "green caret down icon";
      } else {
        this.icon_classSTB = "yellow minus icon";
      }

      if (this.statisticsActiveUserToday > this.statisticsActiveUserYesterday) {
        this.icon_class0 = "red caret up icon";
      } else if (this.statisticsActiveUserToday < this.statisticsActiveUserYesterday) {
        this.icon_class0 = "green caret down icon";
      } else {
        this.icon_class0 = "yellow minus icon";
      }

      if (this.statisticsMabowFriendToday > this.statisticsMabowFriendYesterday) {
        this.icon_class1 = "red caret up icon";
      } else if (this.statisticsMabowFriendToday < this.statisticsMabowFriendYesterday) {
        this.icon_class1 = "green caret down icon";
      } else {
        this.icon_class1 = "yellow minus icon";
      }

      if (this.statisticsMabowPartnerToday > this.statisticsMabowPartnerYesterday) {
        this.icon_class2 = "red caret up icon";
      } else if (this.statisticsMabowPartnerToday < this.statisticsMabowPartnerYesterday) {
        this.icon_class2 = "green caret down icon";
      } else {
        this.icon_class2 = "yellow minus icon";
      }

      if (this.statisticsPaidUserToday > this.statisticsPaidUserYesterday) {
        this.icon_class3 = "red caret up icon";
      } else if (this.statisticsPaidUserToday < this.statisticsPaidUserYesterday) {
        this.icon_class3 = "green caret down icon";
      } else {
        this.icon_class3 = "yellow minus icon";
      }

      console.log("statisticsMabowSTBYesterday = " + this.statisticsMabowSTBYesterday);
      console.log("statisticsMabowSTBToday = " + this.statisticsMabowSTBToday);
      console.log("statisticsActiveUserYesterday = " + this.statisticsActiveUserYesterday);
      console.log("statisticsActiveUserToday = " + this.statisticsActiveUserToday);
      console.log("statisticsMabowFriendYesterday = " + this.statisticsMabowFriendYesterday);
      console.log("statisticsMabowFriendToday = " + this.statisticsMabowFriendToday);
      console.log("statisticsMabowPartnerYesterday = " + this.statisticsMabowPartnerYesterday);
      console.log("statisticsMabowPartnerToday = " + this.statisticsMabowPartnerToday);
      console.log("statisticsPaidUserYesterday = " + this.statisticsPaidUserYesterday);
      console.log("statisticsPaidUserToday = " + this.statisticsPaidUserToday);




    })






  }

  getParseConfig() {

    Parse.Config.get().then((config) => {
      console.log("Yay! Config was fetched from the server.");

      this.statisticsMabowSTBYesterday = config.get("statisticsMabowSTBYesterday");
      this.statisticsMabowSTBToday = config.get("statisticsMabowSTBToday");
      this.statisticsActiveUserYesterday = config.get("statisticsActiveUserYesterday");
      this.statisticsActiveUserToday = config.get("statisticsActiveUserToday");
      this.statisticsMabowFriendYesterday = config.get("statisticsMabowFriendYesterday");
      this.statisticsMabowFriendToday = config.get("statisticsMabowFriendToday");
      this.statisticsMabowPartnerYesterday = config.get("statisticsMabowPartnerYesterday");
      this.statisticsMabowPartnerToday = config.get("statisticsMabowPartnerToday");

      this.statisticsPaidUserYesterday = this.statisticsMabowFriendYesterday + this.statisticsMabowPartnerYesterday;
      this.statisticsPaidUserToday = this.statisticsMabowFriendToday + this.statisticsMabowPartnerToday;

      this.diff_STB = Math.abs(this.statisticsMabowSTBToday - this.statisticsMabowSTBYesterday);
      this.diff_actUser = Math.abs(this.statisticsActiveUserToday - this.statisticsActiveUserYesterday);
      this.diff_Mfriend = Math.abs(this.statisticsMabowFriendToday - this.statisticsMabowFriendYesterday);
      this.diff_Mpartner = Math.abs(this.statisticsMabowPartnerToday - this.statisticsMabowPartnerYesterday);
      this.diff_Mpaid = Math.abs(this.statisticsPaidUserToday - this.statisticsPaidUserYesterday);


      console.log("statisticsMabowSTBYesterday = " + this.statisticsMabowSTBYesterday);
      console.log("statisticsMabowSTBToday = " + this.statisticsMabowSTBToday);
      console.log("statisticsActiveUserYesterday = " + this.statisticsActiveUserYesterday);
      console.log("statisticsActiveUserToday = " + this.statisticsActiveUserToday);
      console.log("statisticsMabowFriendYesterday = " + this.statisticsMabowFriendYesterday);
      console.log("statisticsMabowFriendToday = " + this.statisticsMabowFriendToday);
      console.log("statisticsMabowPartnerYesterday = " + this.statisticsMabowPartnerYesterday);
      console.log("statisticsMabowPartnerToday = " + this.statisticsMabowPartnerToday);
      console.log("statisticsPaidUserYesterday = " + this.statisticsPaidUserYesterday);
      console.log("statisticsPaidUserToday = " + this.statisticsPaidUserToday);


      if (this.statisticsMabowSTBToday > this.statisticsMabowSTBYesterday) {
        this.icon_classSTB = "red caret up icon";
      } else if (this.statisticsMabowSTBToday < this.statisticsMabowSTBYesterday) {
        this.icon_classSTB = "green caret down icon";
      } else {
        this.icon_classSTB = "yellow minus icon";
      }

      if (this.statisticsActiveUserToday > this.statisticsActiveUserYesterday) {
        this.icon_class0 = "red caret up icon";
      } else if (this.statisticsActiveUserToday < this.statisticsActiveUserYesterday) {
        this.icon_class0 = "green caret down icon";
      } else {
        this.icon_class0 = "yellow minus icon";
      }

      if (this.statisticsMabowFriendToday > this.statisticsMabowFriendYesterday) {
        this.icon_class1 = "red caret up icon";
      } else if (this.statisticsMabowFriendToday < this.statisticsMabowFriendYesterday) {
        this.icon_class1 = "green caret down icon";
      } else {
        this.icon_class1 = "yellow minus icon";
      }

      if (this.statisticsMabowPartnerToday > this.statisticsMabowPartnerYesterday) {
        this.icon_class2 = "red caret up icon";
      } else if (this.statisticsMabowPartnerToday < this.statisticsMabowPartnerYesterday) {
        this.icon_class2 = "green caret down icon";
      } else {
        this.icon_class2 = "yellow minus icon";
      }

      if (this.statisticsPaidUserToday > this.statisticsPaidUserYesterday) {
        this.icon_class3 = "red caret up icon";
      } else if (this.statisticsPaidUserToday < this.statisticsPaidUserYesterday) {
        this.icon_class3 = "green caret down icon";
      } else {
        this.icon_class3 = "yellow minus icon";
      }



    }).catch((e) => {
      console.log(e)
      alert("讀取Parse Config發生錯誤, 請稍後再試")
    })

  }


  checkAPPdevices() {
    return this.getMSOUserDeviceActiveRecord(0, []).then(() => {

      console.log("TOP:" + this.MSO_TOP_count);
      console.log("KBRO:" + this.MSO_KBRO_count);
      console.log("TBC:" + this.MSO_TBC_count);
      console.log("CNS:" + this.MSO_CNS_count);

      return this.get16users();

    }).then((_STB_16_count) => {
      console.log("STB_16_count=" + _STB_16_count);
      this.STB_16_count = _STB_16_count;
      return this.get390users();

    }).then((_STB_390_count) => {
      console.log("STB_16_count=" + _STB_390_count);
      this.STB_390_count = _STB_390_count;

      this.total_APP_device_count = this.total_APP_device_count + this.MSO_TOP_count + this.MSO_KBRO_count + this.MSO_TBC_count + this.MSO_CNS_count
        + this.STB_16_count + this.STB_390_count;

      console.log(this.total_APP_device_count)


    }).catch((e) => {
      console.log(e)
    })
  }


  getMSOUserDeviceActiveRecord(index, _userDeviceArray) {
    console.log("UserDeviceActiveRecord / " + index)

    let _UserDeviceActiveRecord = Parse.Object.extend("UserDeviceActiveRecord");
    let query = new Parse.Query(_UserDeviceActiveRecord);

    query.ascending("createdAt")
    query.equalTo("userIsActive", true);
    query.include("partner");
    query.limit(100);
    query.skip(index * 100)

    return query.find().then((results) => {
      console.log("results:" + results.length);
      if (results.length == 0)
        return Promise.resolve(_userDeviceArray);

      for (var i = 0; i < results.length; i++) {
        if (results[i].get("partner")) {
          if (results[i].get("partner").get("name").indexOf("台數科") >= 0)
            this.MSO_TOP_count = this.MSO_TOP_count + 1;
          if (results[i].get("partner").get("name").indexOf("凱擘") >= 0)
            this.MSO_KBRO_count = this.MSO_KBRO_count + 1;
          if (results[i].get("partner").get("name").indexOf("TBC") >= 0)
            this.MSO_TBC_count = this.MSO_TBC_count + 1;
          if (results[i].get("partner").get("name").indexOf("中嘉") >= 0)
            this.MSO_CNS_count = this.MSO_CNS_count + 1;
        }


      }

      if (results.length == 100)
        return this.getMSOUserDeviceActiveRecord(index + 1, _userDeviceArray);
      else
        return Promise.resolve(_userDeviceArray);
    })
  }

  get16users() {

    let _User = Parse.Object.extend("_User");
    let query = new Parse.Query(_User);

    query.equalTo("contained16", true);

    return query.count()
  }

  get390users() {

    let _User = Parse.Object.extend("_User");
    let query = new Parse.Query(_User);
    query.equalTo("isMabowGateUser", true);
    query.notEqualTo("contained16", true);
    return query.count()
  }





}
