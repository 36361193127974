import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ParentClass } from '../ParentClass';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';

declare var window: any;
declare var Parse: any;


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})


export class ProfileComponent extends ParentClass implements OnInit {
  //globalService: GlobalService;
  gs: GlobalService;

  isLoading: boolean = false;
  uploadPhotoPreview: SafeUrl;
  trustedDashboardUrl: SafeUrl;
  new_nickname = null;
  profile_image: SafeUrl;
  imageFile;

  constructor(router: Router, _globalservice: GlobalService, private sanitizer: DomSanitizer) {
    super(router);
    //this.globalService = _globalservice;
    this.gs = _globalservice;
    /*window.onbeforeunload = function () {
      return 'window close';
    };*/
    //this.profile_image = _globalservice.profile_image;
  }



  ngOnInit() {
  }



  /**
  * @method imagefileChangeEvent
  * @param fileInput
  */
  imagefileChangeEvent(fileInput: any) {
    var files = fileInput.target.files || fileInput.dataTransfer.files;
    // Our file var now holds the selected file
    this.imageFile = files[0];
    console.dir(this.imageFile);

    var reader = new FileReader();
    reader.readAsArrayBuffer(this.imageFile);

    reader.onload = (event: any) => {
      console.log("in reader.onload");
      var blob = new Blob([event.target.result]); // create blob...
      console.dir(blob);
      var blobURL = (window.URL || window.webkitURL).createObjectURL(blob); // and get it's URL
      this.trustedDashboardUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl
          (blobURL);
      this.uploadPhotoPreview = this.trustedDashboardUrl;

    };
  }
  update_nickname() {
    if (this.new_nickname) {
      console.log("update_nickname go");
      Parse.User.current().set("userNickname", this.new_nickname);
      Parse.User.current().save();
      this.gs.username.next(this.new_nickname);
    }
  }

  update_profile_image() {
    if (this.uploadPhotoPreview) {
      this.isLoading = true;
      Parse.Promise.when([this.imageToUpload(), this.thumbnailImageToUpload()])
        .then((results) => {
          Parse.User.current().set("userProfileImage", results[0]);
          Parse.User.current().set("userProfileImageSmall", results[1]);
          Parse.User.current().save();
        }).then(() => {
          var profilePhoto = Parse.User.current().get("userProfileImageSmall");
          this.gs.profile_image = profilePhoto.url();
          this.isLoading = false;
        });
    }
  }

  imageToUpload() {
    console.log("in imageToUpload");

    if (this.imageFile == null) {
      console.log("in imageToUpload null this.imageFile = " + this.imageFile);

      return Parse.Promise.resolve("");
    }
    var file_name = "profileimage.jpg";
    var parseFile = new Parse.File(file_name, this.imageFile);
    return parseFile.save();

  }

  /**
  * @method thumbnailImageToUpload
  * @return parsefile
  */
  thumbnailImageToUpload() {

    console.log("in thumbnailImageToUpload");

    if (this.imageFile == null) {
      console.log("in thumbnailImageToUpload null");

      return Parse.Promise.resolve("");
    }

    return this.processfile(this.imageFile).then((_base64: any) => {
      console.log("in thumbnailImageToUpload/2");
      //console.log(_base64)
      var thumbParseFile = new Parse.File("profileimagesmall.jpg", {
        base64: _base64
      });

      return thumbParseFile.save();

    }).catch((e) => {
      return Parse.Promise.error(e)

    })

  }

  /**
   * @method processfile
   * @return base64
   */
  processfile(file) {
    return new Promise((resolve, reject) => {
      // read the files
      console.log("in processfile");
      console.dir(file);
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (event: any) => {
        // blob stuff
        console.log("in reader.onload");
        var blob = new Blob([event.target.result]); // create blob...

        var blobURL = (window.URL || window.webkitURL).createObjectURL(blob); // and get it's URL
        console.log("blobURL=" + blobURL);

        // helper Image object
        var image = new Image();
        image.src = blobURL;
        //preview.appendChild(image); // preview commented out, I am using the canvas instead
        image.onload = () => {
          // have to wait till it's loaded
          var resized = this.resizeMe(image); // send it to canvas
          //console.log("resized = " + resized);

          var base64 = resized.split('base64,')[1];

          resolve(base64);

          //if (callback) {
          //  callback(thumbParseFile);
          //}

        }
      };
    });
  }

  resizeMe(img) {

    console.log("in resizeMe");
    var max_width = 640;
    var max_height = 480;

    var canvas = document.createElement('canvas');

    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL("image/jpeg", 0.5); // get the data from canvas as 70% JPG (can be also PNG, etc.)

  }

}