import { Component, OnInit } from '@angular/core';
import { questions } from './questions';
import * as XLSX from 'xlsx';
declare var Parse: any;
type AOA = any[][];

@Component({
  selector: 'app-addmabowquestion',
  templateUrl: './addmabowquestion.component.html',
  styleUrls: ['./addmabowquestion.component.css']
})
export class AddmabowquestionComponent implements OnInit {
  questionslist: questions[] = [];
  question: questions = {
    no: 0,
    question: "",
    answer: "",
    explain: "",
    theme: "",
    options: {},
    last_use_date: "2011/1/1"
  };

  data: AOA = [[], []];
  questionUploadAmount;
  isloading = false;
  canShowNext100 = false;
  canShowPrevious100 = false;
  isQuestionDisplay = false;
  skip_index = 0;

  constructor() { }

  ngOnInit() {
    //this.query_questions();
  }

  refresh_data() {
    window.location.reload();
  }

  query_questions(skip_step) {
    if (this.skip_index + skip_step >= 0) {
      this.skip_index = this.skip_index + skip_step;
    } else {
      this.skip_index = 0;
    }
    this.isQuestionDisplay = true;
    this.isloading = true;
    this.questionslist = [];
    var MabowQuestion = Parse.Object.extend("MabowQ_Question");
    var MabowQuestion = new MabowQuestion();
    var query = new Parse.Query(MabowQuestion);
    query.descending("createdAt")
    query.skip(this.skip_index * 100);
    query.find({
      success: (results) => {
        console.log("query_questions " + results.length)

        if (results.length == 100 && this.skip_index == 0) {
          this.canShowNext100 = true;
          this.canShowPrevious100 = false;
        } else if (results.length == 100 && this.skip_index > 0) {
          this.canShowNext100 = true;
          this.canShowPrevious100 = true;
        } else if (results.length < 100 && this.skip_index > 0) {
          this.canShowNext100 = false;
          this.canShowPrevious100 = true;
        } else if (results.length < 100 && this.skip_index == 0) {
          this.canShowNext100 = false;
          this.canShowPrevious100 = false;
        }


        for (var i = 0; i < results.length; i++) {

          var qs: questions = {
            no: 0,
            question: "default",
            answer: "default",
            explain: "default",
            theme: "default",
            options: {},
            last_use_date: "2011/1/1"
          };
          qs.no = i + 1;
          qs.question = results[i].get("question");
          qs.answer = results[i].get("correct_answer");
          qs.explain = results[i].get("explanation");
          qs.theme = results[i].get("theme");
          qs.options = results[i].get("answer_options");
          var parse_last_use_date = results[i].get("last_use_date");
          qs.last_use_date = parse_last_use_date.getFullYear() + "/" + (parse_last_use_date.getMonth() + 1) + "/" + parse_last_use_date.getDate();
          this.questionslist.push(qs);

        }
        this.isloading = false;

      },
      error: (error) => {
        alert("Error: " + error.code + " " + error.message);
        this.isloading = false;
      }
    });
  }

  newQuestion(question: questions) {
    var MabowQuestion = Parse.Object.extend("MabowQ_Question");
    var MabowQuestion = new MabowQuestion();
    MabowQuestion.set("question", question.question);
    MabowQuestion.set("correct_answer", question.answer);
    MabowQuestion.set("theme", question.theme);
    MabowQuestion.set("explanation", question.explain);

    MabowQuestion.save(null, {
      success: (MabowQuestion) => {
        alert('新增題庫ID: ' + MabowQuestion.id);
        //this.query_questions();
      },
      error: (MabowQuestion, error) => {
        alert('新增失敗 error code: ' + error.message);
      }
    });
  }

  onFileChange(evt: any) {
    this.isQuestionDisplay = false;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      var sheetindex = -1;
      for (var i = 0; i < wb.SheetNames.length; i++) {
        if (wb.SheetNames[i] == "wait_upload") {
          sheetindex = i;
        }
      }
      if (sheetindex == -1) {
        alert("錯誤:excel 找不到 wait_upload 標簽 !")
        return;
      } else {
        /* grab first sheet */
        const wsname: string = wb.SheetNames[sheetindex];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        //console.dir(ws)

        /* save data */
        this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

        console.log("total:" + this.data.length);
        //this.handleData();
      }

    };
    reader.readAsBinaryString(target.files[0]);
  }

  private checkData() {
    for (var i = 4; i < (4 + this.questionUploadAmount); i++) {
      var canUpload = true;
      for (var j = 0; j < 16; j++) {
        if (this.data[i][j] == null || this.data[i][j].length == 0) {
          console.log("第" + (i + 1) + "行第" + (j + 1) + "欄有空位沒填,無法上傳")
          alert("第" + (i + 1) + "行第" + (j + 1) + "欄有空位沒填")
          canUpload = false;
          return false;
        }
      }

    }
    if (canUpload) {
      console.log("所有欄位都有資料,可以上傳")
      return true;
    } else {
      console.log("有欄位未填,無法上傳")
      return false;
    }

  }

  private async handleData() {

    for (var i = 4; i < this.data.length; i++) {
      //console.dir(this.data[i]);
      var canUpload = true;
      var retry = false;
      var last_index = this.data[i].length - 1;
      if (this.data[i][last_index] == "ok") {
        canUpload = false;
        console.log("第 " + (i - 3) + " 題已經上傳過")
      }
      if (this.data[i][last_index] == "fail") {
        retry = true;
      }
      /*for (var j = 0; j < 16; j++) {
        if (this.data[i][j] == null || this.data[i][j].length == 0) {
          alert("第" + i + "行第" + j + "欄有空位沒填")
          canUpload = false;
        }
      }*/

      //canUpload = false;
      if (canUpload) {
        var question_content = this.data[i][1];
        var answer_options = {};
        answer_options["1"] = this.data[i][3];
        answer_options["2"] = this.data[i][4];
        var correct_answer = this.data[i][5];
        var explanation = this.data[i][6];
        var theme = this.data[i][8];
        var subtheme = this.data[i][9];
        var answer_correct_rate = parseFloat(this.data[i][11]);
        var answer_correct_people_count = Math.floor(50 * answer_correct_rate);
        var answer_wrong_people_count = 50 - answer_correct_people_count;
        var provider = this.data[i][12];
        var viewer1 = this.data[i][13];
        var viewer2 = this.data[i][14];
        const TypeClass = Parse.Object.extend("Mabow_Qestion_Type");
        var type = TypeClass.createWithoutData(this.data[i][15]);
        var last_use_date = new Date();
        last_use_date.setFullYear(2011);

        //console.log("answer_options")
        //console.dir(answer_options)

        console.log(i + " line start upload...")
        var MabowQuestion = Parse.Object.extend("MabowQ_Question");
        var MabowQuestion = new MabowQuestion();
        MabowQuestion.set("question", question_content);
        MabowQuestion.set("answer_options", answer_options);
        MabowQuestion.set("correct_answer", correct_answer);
        MabowQuestion.set("theme", theme);
        MabowQuestion.set("subtheme", subtheme);
        MabowQuestion.set("answer_type", "choose");
        MabowQuestion.set("explanation", explanation);
        MabowQuestion.set("type", type);
        MabowQuestion.set("answer_correct_rate", answer_correct_rate);
        MabowQuestion.set("answer_correct_people_count", answer_correct_people_count);
        MabowQuestion.set("answer_wrong_people_count", answer_wrong_people_count);
        MabowQuestion.set("provider", provider);
        MabowQuestion.set("viewer1", viewer1);
        MabowQuestion.set("viewer2", viewer2);
        MabowQuestion.set("last_use_date", last_use_date);
        await MabowQuestion.save(null, {
          success: (MabowQuestion) => {
            //alert('新增題庫ID: ' + MabowQuestion.id);
            console.log("第 " + i + " 行 " + ' 新增題庫ok ID: ' + MabowQuestion.id)
            if (!retry) {
              this.data[i].push("ok")
            } else {
              this.data[i][this.data[i].length - 1] = "ok";
            }

            //this.query_questions();
          },
          error: (MabowQuestion, error) => {
            //alert('新增失敗 error code: ' + error.message);
            console.log("第 " + i + " 行 " + '新增失敗 error code: ' + error.message)
            if (!retry) {
              this.data[i].push("fail")
            } else {
              this.data[i][this.data[i].length - 1] = "fail"
            }
          }
        });
      }

    }
    this.isloading = false;
    alert("上傳結束")

  }

  async uploadQuestionToParse() {

    this.questionUploadAmount = 0;
    var key = prompt("請輸入上傳題目數量", "");
    this.questionUploadAmount = Number(key);
    console.log("上傳" + this.questionUploadAmount + "題...")

    if (this.checkData()) {
      this.isloading = true;
      this.handleData();
    }
  }

}
