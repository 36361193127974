import { Component, OnInit } from '@angular/core';
import { liveerror } from './liveerror';
import { LiveErrorMonitorPipe } from './live-error-monitor.pipe';

declare var Parse: any;

@Component({
  selector: 'app-live-error-monitor',
  templateUrl: './live-error-monitor.component.html',
  styleUrls: ['./live-error-monitor.component.css']
})
export class LiveErrorMonitorComponent implements OnInit {
  liveerrorlist: liveerror[] = [];
  sortBy: string = "";
  sortBy_tmp: string = "";
  select_list: string[];

  constructor() {
    this.select_list = [];
    this.select_list.push("全部");
    this.select_list.push("未澄清");
    this.sortBy = "";

    this.query_live_error_monitor();
  }

  ngOnInit() {
  }



  query_live_error_monitor() {
    console.log("query_live_error_monitor ");
    this.sortBy = "";
    this.liveerrorlist = [];

    var liveerrormonitor = Parse.Object.extend("LiveError");
    var liveerrormonitor = new liveerrormonitor();
    var query = new Parse.Query(liveerrormonitor);
    query.descending("createdAt");
    query.include('User');
    query.include('LiveInteraction');

    query.find({
      success: (results) => {
        for (var i = 0; i < results.length; i++) {
          console.log("results.length " + results.length);
          var live_error: liveerror = {
            name: "",
            account: "",
            ImageURL: "http://semantic-ui.com/images/avatar2/large/kristy.png",
            userobject_id: "",
            room_id: "",
            time: "",
            clarified: false,
            id: "",
          };
          live_error.name = results[i].get("User").get("username");
          live_error.account = results[i].get("User").get("username");
          if (results[i].get("User").get('userProfileImageSmall'))
            live_error.ImageURL = results[i].get("User").get('userProfileImageSmall').url();
          live_error.userobject_id = results[i].get("User").id;
          live_error.room_id = results[i].get("Channel").id;
          live_error.time = results[i].get("createdAt");
          if (results[i].get("clarified"))
            live_error.clarified = results[i].get("clarified");
          live_error.id = results[i].id;
          this.liveerrorlist.push(live_error);
        }
        console.log("finish");

      },
      error: (error) => {
        alert("Error: " + error.code + " " + error.message);
      }
    });
  }


  clarfy(liveerror_item: liveerror) {

    const LiveError = Parse.Object.extend("LiveError");
    var liveerror = new LiveError();

    liveerror.id = liveerror_item.id;
    liveerror.set("clarified", true);

    liveerror.save(null, {
      success: function (liveerror) {
        console.log('New object created with objectId: ' + liveerror.id);
      },
      error: function (liveerror, error) {
        alert('Failed to create new object, with error code: ' + error.message);
      }
    }).then(() => {
      this.query_live_error_monitor();
    });
  }
}
